var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_c('h2',[_vm._v(_vm._s(_vm.itemSelecionado ? 'EDITAR ITEM' : 'CADASTRAR ITEM'))])]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.alerta),expression:"alerta"}],staticClass:"pa-2",staticStyle:{"width":"100%"},attrs:{"type":_vm.tipo,"value":true}},[_c('h4',[_vm._v(_vm._s(_vm.alerta))])]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.alerta),expression:"alerta"}]}),_c('v-card-text',[_c('v-stepper',{staticClass:"pa-0 ma-0",staticStyle:{"box-shadow":"none"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-layout',{attrs:{"wrap":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-autocomplete',{attrs:{"label":"Categoria","outline":"","items":_vm.categorias,"item-text":"nome","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":props.item.cor}},[_vm._v(_vm._s(props.item.icone))]),_c('span',[_vm._v(_vm._s(props.item.nome))])]}}]),model:{value:(_vm.item.categoria),callback:function ($$v) {_vm.$set(_vm.item, "categoria", $$v)},expression:"item.categoria"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{attrs:{"label":"Tipos de Item","outline":"","items":_vm.servicos ?
                            [
                              { text: 'Serviço', value: 'S' } ]
                            :
                            [
                              { text: 'Produto Físico', value: 'P' },
                              { text: 'Produto Digital', value: 'D' } ]},model:{value:(_vm.item.tipo),callback:function ($$v) {_vm.$set(_vm.item, "tipo", $$v)},expression:"item.tipo"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"label":"Preço","outline":"","prefix":"R$"},model:{value:(_vm.item.preco),callback:function ($$v) {_vm.$set(_vm.item, "preco", $$v)},expression:"item.preco"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Nome","outline":"","maxlength":"100","counter":"100"},model:{value:(_vm.item.nome),callback:function ($$v) {_vm.$set(_vm.item, "nome", $$v)},expression:"item.nome"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"label":"Descrição","outline":"","maxlength":"255","counter":"255"},model:{value:(_vm.item.descricao),callback:function ($$v) {_vm.$set(_vm.item, "descricao", $$v)},expression:"item.descricao"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-switch',{attrs:{"slot":"activator","color":"primary","disabled":!_vm.item.categoria},slot:"activator",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tem controle de quantidade ")]},proxy:true}]),model:{value:(_vm.tem_limite_qtd),callback:function ($$v) {_vm.tem_limite_qtd=$$v},expression:"tem_limite_qtd"}}),_c('v-slide-x-transition',[(_vm.tem_limite_qtd)?_c('v-text-field',{attrs:{"label":"Quantidade Disponível","outline":"","type":"number"},model:{value:(_vm.item.quantidade_disponivel),callback:function ($$v) {_vm.$set(_vm.item, "quantidade_disponivel", $$v)},expression:"item.quantidade_disponivel"}}):_vm._e()],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-toolbar',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","icon":""},on:{"click":function($event){return _vm.imagens.push(undefined)}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('h4',{staticClass:"info--text"},[_vm._v("Adicionar Nova Imagem")])])],1),_c('input',{ref:"imagemInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"input":_vm.carregarImagem}}),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.cropping),expression:"cropping"}],attrs:{"xs12":""}},[_c('vue-croppie',{ref:"croppie",attrs:{"enableExif":true,"enforceBoundary":true,"enableResize":false,"enableOrientation":false,"mouseWheelZoom":true,"showZoomer":false,"boundary":{ width: _vm.croppie_width + 100, height: _vm.croppie_height + 100 },"viewport":{ width: _vm.croppie_width, height: _vm.croppie_height }}}),_c('v-btn',{staticClass:"mt-3",attrs:{"text":"","block":""},on:{"click":function($event){return _vm.recortarImagem()}}},[_vm._v(" Recortar imagem ")])],1),(!_vm.cropping)?_c('v-stepper',{staticStyle:{"box-shadow":"none"},model:{value:(_vm.step_image),callback:function ($$v) {_vm.step_image=$$v},expression:"step_image"}},[_c('v-stepper-items',{staticStyle:{"box-shadow":"none"}},_vm._l((_vm.imagens),function(item,index){return _c('v-stepper-content',{key:index,staticClass:"ma-0 pa-0",staticStyle:{"box-shadow":"none"},attrs:{"step":Number(index) + 1}},[_c('v-layout',{staticStyle:{"justify-content":"center","align-items":"center"},attrs:{"justify-center":"","column":""}},[(item)?_c('img',{staticClass:"ml-3 mr-3 mt-2",attrs:{"slot":"activator","src":item,"width":"600"},on:{"click":function($event){return _vm.removerImagem(item, index)}},slot:"activator"}):(_vm.loading && !item)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"color":"info"}}):(!_vm.cropping)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-card',_vm._g({staticClass:"ml-3 mr-3 mt-2 mb-2",attrs:{"hover":"","width":"600","loading":_vm.loading},on:{"click":function($event){_vm.$refs.imagemInput.click(), _vm.indexSelecionado = index}}},on),[_c('v-layout',{attrs:{"justify-center":"","column":""}},[_c('v-icon',{attrs:{"size":"15em"}},[_vm._v("image")])],1)],1)]}}],null,true)},[_c('h4',[_vm._v("Clique para inserir uma imagem")])]):_vm._e()],1),_c('v-flex',{staticStyle:{"justify-content":"center","align-items":"center","display":"flex"},attrs:{"xs12":""}},[_c('v-btn',{staticClass:"mt-3 mb-3",attrs:{"color":"primary","large":""},on:{"click":function($event){_vm.$refs.imagemInput.click(), _vm.indexSelecionado = index}}},[_vm._v(" Inserir imagem ")])],1)],1)}),1)],1):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-space-between":""}},[_c('v-btn',{staticClass:"ma-3",attrs:{"icon":"","color":"info","disabled":_vm.step_image === 1},on:{"click":function($event){_vm.step_image--}}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-btn',{staticClass:"ma-3",attrs:{"icon":"","color":"info","disabled":_vm.imagens.length === _vm.step_image},on:{"click":function($event){_vm.step_image++}}},[_c('v-icon',[_vm._v("arrow_forward")])],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-flex',[(_vm.step == 1)?_c('v-btn',{staticClass:"mb-1",attrs:{"block":"","color":"success"},on:{"click":_vm.cadastrarItem}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.itemSelecionado ? 'Editar' : 'Cadastrar')+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mt-1",attrs:{"block":""},on:{"click":function($event){return _vm.$emit('fechar')}}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }