<template>
  <v-card>
    <v-card-title class="justify-center">
        <h2>{{ tituloCard }}</h2>
    </v-card-title>
    <v-divider></v-divider>
    <v-alert
      v-show="alerta"
      :type="tipo"
      class="mt-4 mb-1 pa-2"
      :value="true"
    >
      <h4 justify-center>{{alerta}}</h4>
    </v-alert>
    <v-card-text>
      <v-container flex grid-list-md>
      <v-flex xs12 v-if="isVenda && step === 1">
        <v-stepper v-model="step_image" style="box-shadow: none;">
          <v-stepper-items style="box-shadow: none;">
            <v-stepper-content step="1" class="ma-0 pa-0" style="box-shadow: none;">
              <v-layout justify-center column>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                      <img
                        v-show="imagem_frontal && mostrar"
                        v-on="on"
                        @click="$refs.imagemFrontalInput.click()"
                        :src="imagem_frontal"
                        alt="Imagem Frontal"
                        class="ml-3 mr-3 mt-2"
                        style="cursor: pointer;"
                      />
                  </template>
                  <h4>Clique para inserir uma outra imagem</h4>
                </v-tooltip>
                <v-tooltip bottom>
                  <template  v-slot:activator="{ on }">
                    <v-card
                      v-show="!imagem_frontal && !cropping1"
                      hover
                      class="ml-3 mr-3 mt-2"
                      v-on="on"
                      @click="$refs.imagemFrontalInput.click()"
                    >
                      <v-layout justify-center column>
                        <v-icon size="15em">image</v-icon>
                      </v-layout>
                    </v-card>
                  </template>
                  <h4>Clique para inserir uma imagem</h4>
                </v-tooltip>
                <v-flex xs12 v-show="cropping1">
                  <vue-croppie
                    ref="croppieimagemFrontalRef"
                    :enableExif="true"
                    :enforceBoundary="true"
                    :enableResize="false"
                    :enableOrientation="false"
                    :mouseWheelZoom="true"
                    :showZoomer="false"
                    :boundary="{ width: 700, height: 500 }"
                    :viewport="{ width: 600, height: 450}"
                  ></vue-croppie>
                  <v-btn
                    text
                    @click="recortarImagem"
                    color="grey darken-2"
                    block
                    class="mt-3"
                  >
                    Recortar imagem
                    </v-btn>
                </v-flex>
                <input
                  type="file"
                  style="display:none"
                  accept="image/*"
                  ref="imagemFrontalInput"
                  @input="carregarImagem"
                />
                <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem Frontal</h3>
                <v-layout wrap justify-space-between>
                  <v-btn icon color="info" class="ma-3" disabled>
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                  <v-btn icon color="info" class="ma-3" @click="step_image++">
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2" class="ma-0 pa-0">
              <v-layout justify-center column>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <img
                        v-show="imagem_traseira && mostrar2"
                        v-on="on"
                        @click="$refs.imagemTraseiraInput.click()"
                        :src="imagem_traseira"
                        alt="Imagem Frontal"
                        class="ml-3 mr-3 mt-2"
                        style="cursor: pointer;"
                      />
                  </template>
                  <h4>Clique para inserir uma outra imagem</h4>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-card
                      v-show="!imagem_traseira && !cropping2"
                      hover
                      class="ml-3 mr-3 mt-2"
                      v-on="on"
                      @click="$refs.imagemTraseiraInput.click()"
                    >
                      <v-layout justify-center column>
                        <v-icon size="15em">image</v-icon>
                      </v-layout>
                    </v-card>
                  </template>
                  <h4>Clique para inserir uma imagem</h4>
                </v-tooltip>
                <v-flex xs12 v-show="cropping2">
                  <vue-croppie
                    ref="croppieimagemTraseiraRef"
                    :enableExif="true"
                    :enforceBoundary="true"
                    :enableResize="false"
                    :enableOrientation="false"
                    :mouseWheelZoom="true"
                    :showZoomer="false"
                    :boundary="{ width: 700, height: 500 }"
                    :viewport="{ width: 600, height: 450}"
                  ></vue-croppie>
                  <v-btn
                    text
                    @click="recortarImagem"
                    color="grey darken-2"
                    block
                    class="mt-3"
                  >
                    Recortar imagem
                    </v-btn>
                </v-flex>
                <input
                  type="file"
                  style="display:none"
                  accept="image/*"
                  ref="imagemTraseiraInput"
                  @input="carregarImagem"
                />
                <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem Traseira</h3>
                <v-layout wrap justify-space-between>
                  <v-btn icon color="info" class="ma-3" @click="step_image--">
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                  <v-btn icon color="info" class="ma-3" @click="step_image++">
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="ma-0 pa-0">
              <v-layout justify-center column>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <img
                        v-show="imagem_interior && mostrar3"
                        v-on="on"
                        @click="$refs.imagemInteriorInput.click()"
                        :src="imagem_interior"
                        alt="Imagem Frontal"
                        class="ml-3 mr-3 mt-2"
                        style="cursor: pointer;"
                      />
                  </template>
                  <h4>Clique para inserir uma outra imagem</h4>
                </v-tooltip>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <v-card
                        v-show="!imagem_interior && !cropping3"
                        hover
                        class="ml-3 mr-3 mt-2"
                        v-on="on"
                        @click="$refs.imagemInteriorInput.click()"
                      >
                        <v-layout justify-center column>
                          <v-icon size="15em">image</v-icon>
                        </v-layout>
                      </v-card>
                  </template>
                <h4>Clique para inserir uma imagem</h4>
              </v-tooltip>
              <v-flex xs12 v-show="cropping3">
                  <vue-croppie
                    ref="croppieimagemInteriorRef"
                    :enableExif="true"
                    :enforceBoundary="true"
                    :enableResize="false"
                    :enableOrientation="false"
                    :mouseWheelZoom="true"
                    :showZoomer="false"
                    :boundary="{ width: 700, height: 500 }"
                    :viewport="{ width: 600, height: 450}"
                  ></vue-croppie>
                  <v-btn
                    text
                    @click="recortarImagem"
                    color="grey darken-2"
                    block
                    class="mt-3"
                  >
                    Recortar imagem
                    </v-btn>
                </v-flex>
                <input
                  type="file"
                  style="display:none"
                  accept="image/*"
                  ref="imagemInteriorInput"
                  @input="carregarImagem"
                />
                <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem Interna</h3>
                <v-layout wrap justify-space-between>
                  <v-btn icon color="info" class="ma-3" @click="step_image--">
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                  <v-btn icon color="info" class="ma-3" @click="step_image++">
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4" class="ma-0 pa-0">
              <v-layout justify-center column>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <img
                        v-show="imagem_lateral_e && mostrar4"
                        v-on="on"
                        @click="$refs.imagemLateralEInput.click()"
                        :src="imagem_lateral_e"
                        alt="Imagem Frontal"
                        class="ml-3 mr-3 mt-2"
                        style="cursor: pointer;"
                      />
                  </template>
                  <h4>Clique para inserir uma outra imagem</h4>
                </v-tooltip>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <v-card
                        v-show="!imagem_lateral_e && !cropping4"
                        hover
                        class="ml-3 mr-3 mt-2"
                        v-on="on"
                        @click="$refs.imagemLateralEInput.click()"
                      >
                        <v-layout justify-center column>
                          <v-icon size="15em">image</v-icon>
                        </v-layout>
                      </v-card>
                  </template>
                <h4>Clique para inserir uma imagem</h4>
              </v-tooltip>
              <v-flex xs12 v-show="cropping4">
                  <vue-croppie
                    ref="croppieimagemLateralERef"
                    :enableExif="true"
                    :enforceBoundary="true"
                    :enableResize="false"
                    :enableOrientation="false"
                    :mouseWheelZoom="true"
                    :showZoomer="false"
                    :boundary="{ width: 700, height: 500 }"
                    :viewport="{ width: 600, height: 450}"
                  ></vue-croppie>
                  <v-btn
                    text
                    @click="recortarImagem"
                    color="grey darken-2"
                    block
                    class="mt-3"
                  >
                    Recortar imagem
                    </v-btn>
                </v-flex>
                <input
                  type="file"
                  style="display:none"
                  accept="image/*"
                  ref="imagemLateralEInput"
                  @input="carregarImagem"
                />
                <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem da Lateral Esquerda</h3>
                <v-layout wrap justify-space-between>
                  <v-btn icon small color="info" class="ma-2" @click="step_image--">
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                  <v-btn icon small color="info" class="ma-2" @click="step_image++">
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="5" class="ma-0 pa-0">
              <v-layout justify-center column>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <img
                        v-show="imagem_lateral_d && mostrar5"
                        v-on="on"
                        @click="$refs.imagemLateralDInput.click()"
                        :src="imagem_lateral_d"
                        alt="Imagem Frontal"
                        class="ml-3 mr-3 mt-2"
                        style="cursor: pointer;"
                      />
                  </template>
                  <h4>Clique para inserir uma outra imagem</h4>
                </v-tooltip>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                      <v-card
                        v-show="!imagem_lateral_d && !cropping5"
                        hover
                        class="ml-3 mr-3 mt-2"
                        v-on="on"
                        @click="$refs.imagemLateralDInput.click()"
                      >
                        <v-layout justify-center column>
                          <v-icon size="15em">image</v-icon>
                        </v-layout>
                      </v-card>
                  </template>
                <h4>Clique para inserir uma imagem</h4>
              </v-tooltip>
              <v-flex xs12 v-show="cropping5">
                  <vue-croppie
                    ref="croppieimagemLateralDRef"
                    :enableExif="true"
                    :enforceBoundary="true"
                    :enableResize="false"
                    :enableOrientation="false"
                    :mouseWheelZoom="true"
                    :showZoomer="false"
                    :boundary="{ width: 700, height: 500 }"
                    :viewport="{ width: 600, height: 450}"
                  ></vue-croppie>
                  <v-btn
                    text
                    @click="recortarImagem"
                    color="grey darken-2"
                    block
                    class="mt-3"
                  >
                    Recortar imagem
                    </v-btn>
                </v-flex>
                <input
                  type="file"
                  style="display:none"
                  accept="image/*"
                  ref="imagemLateralDInput"
                  @input="carregarImagem"
                />
                <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem da Lateral Direita</h3>
                <v-layout wrap justify-space-between>
                  <v-btn icon color="info" class="ma-3" @click="step_image--">
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                  <v-btn icon color="info" class="ma-3" disabled>
                    <v-icon>arrow_forward</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
      <v-stepper v-model="step" style="box-shadow: none;">
        <v-stepper-items>
          <v-stepper-content step="1"  class="pa-0" style="box-shadow: none;">
            <v-container flex grid-list-md>
              <v-form lazy-validation v-model="valid" ref="formVeiculo">
                <v-layout row wrap fill-height justify-space-between>
                  <v-flex xs12 style="justify-content: end; display: flex;">
                    <v-switch
                        slot="activator"
                        class="ml-4"
                        v-model="dadosVeiculo.usado"
                        label="Usado"
                        color="info"
                        inset
                      />
                  </v-flex>
                  <v-flex xs6 v-if="!isVenda || dadosVeiculo.usado">
                    <v-text-field
                      outlined
                      hint="Digite no formato ABC-1234 ou ABC-1D23"
                      label="Placa"
                      v-mask="['AAA-#X##']"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório',
                        (v) => !!v && v.length == 8 || 'Placa Inválida'
                      ]"
                      v-model="dadosVeiculo.identificacao"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      outlined
                      v-model="dadosVeiculo.modelo"
                      label="Modelo"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                      ]"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isVenda">
                    <v-autocomplete
                      outlined
                      v-model="dadosVeiculo.marca"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                      ]"
                      label="Marca"
                      :items="[
                        'Agrale',
                        'Aston Martin',
                        'Audi',
                        'Avelloz',
                        'Bentley',
                        'BMW',
                        'BMW Motorrad',
                        'BYD',
                        'Chery',
                        'Chevrolet',
                        'Chrysler',
                        'Citroen',
                        'Dafra',
                        'Dodge',
                        'Ducati',
                        'Effa',
                        'Exeed',
                        'Ferrari',
                        'Fiat',
                        'Ford',
                        'Foton',
                        'GM',
                        'Haojue',
                        'Harley Davidson',
                        'Honda',
                        'Husqvarna',
                        'Hyundai',
                        'Iveco',
                        'JAC',
                        'Jaguar',
                        'Jeep',
                        'Kasinski',
                        'KTM',
                        'Kawasaki',
                        'Kia',
                        'Kymco',
                        'Lamborghini',
                        'Land Rover',
                        'Lexus',
                        'Lifan',
                        'Maserati',
                        'McLaren',
                        'Mercedes-AMG',
                        'Mercedes-Benz',
                        'Mini',
                        'Mitsubishi',
                        'MXF',
                        'Nissan',
                        'Outra',
                        'Peugeot',
                        'Piaggio',
                        'Porsche',
                        'Pro Tork',
                        'RAM',
                        'Renault',
                        'Rolls-Royce',
                        'Royal Enfield',
                        'Shineray',
                        'Smart',
                        'Sousa Motos',
                        'Subaru',
                        'Sundown',
                        'Suzuki',
                        'Toyota',
                        'Triumph',
                        'Troller',
                        'Volkswagen',
                        'Volvo',
                        'Voltz Motors',
                        'Yamaha'
                      ]"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs6 v-else>
                    <v-text-field
                      outlined
                      v-model="dadosVeiculo.marca"
                      label="Marca"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                      ]"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      outlined
                      v-model="dadosVeiculo.tipo_veiculo_object"
                      :items="tipos_veiculo"
                      label="Tipo do veículo"
                      item-text="denominacao"
                      item-value="id_tipo_veiculo"
                      no-data-text="Desculpe, não foi possível carregar os tipos de veiculo"
                      return-object
                    ></v-select>
                  </v-flex>
                  <v-slide-x-transition>
                    <v-flex xs12 v-if="isVenda && dadosVeiculo.usado">
                      <v-text-field
                        label="Quilometragem"
                        outlined
                        v-model="dadosVeiculo.quilometragem"
                        type="number"
                        :rules="[
                          (v) => !!v || 'Esse campo é obrigatório',
                          (v) => v >= 0 || 'O valor não pode ser menor que zero'
                        ]"
                      />
                    </v-flex>
                  </v-slide-x-transition>
                  <v-flex xs12 v-if="isVenda && !dadosVeiculo.usado">
                    <v-text-field
                      label="Quantidade em estoque"
                      outlined
                      v-model="dadosVeiculo.qtd_estoque"
                      type="number"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório',
                        (v) => v >= 0 || 'O valor não pode ser menor que zero'
                      ]"
                    />
                  </v-flex>
                </v-layout>
                <v-layout wrap fill-height justify-space-between>
                  <v-flex xs12>
                    <v-select
                      outlined
                      v-model="dadosVeiculo.cor_texto"
                      label="Cor"
                      :items="[
                        'Branca',
                        'Preta',
                        'Prata',
                        'Cinza',
                        'Azul',
                        'Vermelha',
                        'Verde',
                        'Amarela',
                        'Marrom',
                        'Bege',
                        'Rosa'
                      ]"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    v-if="isVenda"
                  >
                    <v-autocomplete
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                      outlined
                      v-model="dadosVeiculo.motor"
                      label="Potência do motor"
                      :items="[
                        '1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9',
                        '2.0', '2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7', '2.8', '2.9',
                        '3.0', '3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9',
                        '4.0', '4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7', '4.8', '4.9',
                        '5.0', '5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7', '5.8', '5.9',
                        '6.0', '6.1', '6.2', '6.3', '6.4', '6.5', '6.6', '6.7', '6.8', '6.9',
                        '7.0', '7.1', '7.2', '7.3', '7.4', '7.5', '7.6', '7.7', '7.8', '7.9',
                        '8.0', '8.1', '8.2', '8.3', '8.4', '8.5', '8.6', '8.7', '8.8', '8.9',
                        '9.0', '9.1', '9.2', '9.3', '9.4', '9.5', '9.6', '9.7', '9.8', '9.9',
                        '10.0', '10.1', '10.2', '10.3', '10.4', '10.5', '10.6', '10.7', '10.8', '10.9',
                        '11.0', '11.1', '11.2', '11.3', '11.4', '11.5', '11.6', '11.7', '11.8', '11.9',
                        '12.0', '12.1', '12.2', '12.3', '12.4', '12.5', '12.6', '12.7', '12.8', '12.9',
                        '13.0', '13.1', '13.2', '13.3', '13.4', '13.5', '13.6', '13.7', '13.8', '13.9',
                        '14.0', '14.1', '14.2', '14.3', '14.4', '14.5', '14.6', '14.7', '14.8', '14.9',
                        '15.0', '15.1', '15.2', '15.3', '15.4', '15.5', '15.6', '15.7', '15.8', '15.9'
                      ]"
                    />
                    <v-autocomplete
                      v-else
                      outlined
                      v-model="dadosVeiculo.cilindradas"
                      label="Cilindradas"
                      :items="[
                        '50', '100', '110', '125', '150', '160', '190', '200', '250', '300', '350', '400',
                        '420', '450', '500', '550', '600', '650', '700', '750', '800', '850', '900', '1000'
                      ]"
                    />
                  </v-flex>
                  <v-flex xs12
                    v-if="
                        isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                    "
                  >
                    <v-autocomplete
                      outlined
                      v-model="dadosVeiculo.qtd_portas"
                      label="Quantidade de Portas"
                      :items="[
                        1, 2, 3, 4, 5, 6
                      ]"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex :xs="tipo_veiculo !== 'motocicleta' ? 9 : 12">
                    <v-combobox
                      outlined
                      label="Combustivel"
                      :items="combustiveis"
                      item-text="nome"
                      :rules="[(v) => !!v.length || 'Esse campo é obrigatório']"
                      required
                      item-value="id_combustivel"
                      hide-no-data
                      :menu-props="{maxHeight: 120}"
                      hide-details
                      multiple
                      class="pb-1"
                      return-object
                      v-model="dadosVeiculo.combustiveis"
                    >
                      <template v-slot:selection="data">
                        <svg-filler :path="require(`@/assets/combs/${data.item.icone}.svg`)" width="35px" height="35px" :fill="data.item.color"/>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs3
                      v-if="
                        isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                      ">
                      <v-switch
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.kit_gnv"
                        label="Kit GNV"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      class="mt-4"
                      xs6
                      v-if="
                        isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                      "
                    >
                      <v-select
                        label="Tipo de Câmbio"
                        outlined
                        v-model="dadosVeiculo.cambio"
                        :items="[
                          { text: 'Manual', value: 1 },
                          { text: 'Automático', value: 2 },
                          { text: 'Automatizado', value: 3 },
                          { text: 'CVT', value: 4 }
                        ]"
                      ></v-select>
                    </v-flex>
                    <v-flex
                      xs6
                      class="mt-4"
                      v-if="isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                      "
                    >
                      <v-select
                        label="Tipo de Direção"
                        v-model="dadosVeiculo.direcao"
                        outlined
                        :items="[
                          { text: 'Mecânica', value: 1 },
                          { text: 'Hidráulica', value: 2 },
                          { text: 'Elétrica', value: 3 },
                          { text: 'Eletro-hidráulica', value: 4 }
                        ]"
                      ></v-select>
                    </v-flex>
                    <v-flex xs6 v-if="isVenda">
                      <v-text-field
                        outlined
                        v-model="dadosVeiculo.ano_fabricacao"
                        label="Ano de Fabricação / modelo"
                        placeholder="0000/0000"
                        v-mask="['####/####']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 v-if="isVenda">
                      <v-text-field
                        v-money="money"
                        outlined
                        v-model.lazy="dadosVeiculo.valor"
                        label="Valor do Veículo"
                        prefix="R$"
                        v-if="!salvando"
                      />
                      <v-text-field
                        v-model="dadosVeiculo.valor"
                        label="Valor do Veículo"
                        prefix="R$"
                        outlined
                        v-else
                      />
                    </v-flex>
                    <v-flex xs12 v-if="dadosVeiculo.valor_estimado && isVenda" class="mb-4 mt-0">
                      <h3>Preço estimado do veículo: <b style="color:green">{{dadosVeiculo.valor_estimado | currency({ fractionCount: 2 })}}</b></h3>
                    </v-flex>
                  <v-layout
                    wrap
                    justify-space-between
                    v-if="isVenda"
                  >
                    <v-flex xs12>
                      <h2>Opcionais</h2>
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.vidro_eletrico"
                        label="Vidro elétrico"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.trava_eletrica"
                        label="Trava elétrica"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                    xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.ar"
                        label="Ar condicionado"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.alarme"
                        label="Alarme"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.som"
                        label="Som"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.volante_regulagem_altura"
                        label="Volante com regulagem de altura"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.bluetooth"
                        label="Bluetooth"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.vidro_eletrico_traseiro"
                        label="Vidro elétrico traseiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.vidro_eletrico_dianteiro"
                        label="Vidro elétrico dianteiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.rodas_liga_leve"
                        label="Rodas de liga leve"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.kit_multimidia"
                        label="Kit multimídia"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.computador_bordo"
                        label="Computador de bordo"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.bancos_couro"
                        label="Bancos de couro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.retrovisores_eletricos"
                        label="Retrovisor elétrico"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag"
                        :label="
                          tipo_veiculo !== 'motocicleta' ?
                          'Air bag do motorista' :
                          'Air bag'
                        "
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag_passageiro"
                        label="Air bag passageiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag_lateral"
                        label="Air bag lateral"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag_duplo"
                        label="Air bag duplo"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.freios_abs"
                        label="Freios ABS"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.radio"
                        label="Rádio"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.entrada_usb"
                        label="Entrada USB"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.porta_copos"
                        label="Porta copos"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.cd_player"
                        label="CD Player"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.distribuicao_eletronica_frenagem"
                        label="Distribuição eletrônica de frenagem"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.desembaçador_traseiro"
                        label="Desembaçador traseiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.sensor_re"
                        label="Sensor de ré"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.camera_re"
                        label="Câmera de ré"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.blindado"
                        label="Blindado"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.gps"
                        label="GPS"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.escapamento_esportivo"
                        label="Escapamento Esportivo"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.contra_peso_guidon"
                        label="Contra Peso Guidon" color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.bau"
                        label="Baú"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.farois_neblina"
                        label="Faróis de Neblina"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      v-if="dadosVeiculo.usado"
                    >
                      <h2>Estado financeiro</h2>
                    </v-flex>
                    <v-radio-group
                      v-model="dadosVeiculo.quitando"
                      v-if="dadosVeiculo.usado"
                    >
                      <v-radio
                        label="Quitado"
                        color="info"
                        :value="false"
                      ></v-radio>
                      <v-radio
                        class="mt-4"
                        label="Financiado"
                        color="info"
                        :value="true"
                      ></v-radio>
                    </v-radio-group>
                    <v-flex
                      xs12
                      class="mt-4"
                      v-if="dadosVeiculo.usado"
                    >
                      <h2>Documentação e regularização</h2>
                    </v-flex>
                    <v-flex
                      xs12
                      style="flex-direction: column;
                      display: flex;"
                      v-if="dadosVeiculo.usado"
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.ipva_pago"
                        label="IPVA Pago"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.com_multa"
                        label="Com Multa"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.de_leilao"
                        label="De Leilão"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      class="mb-4"
                    >
                      <h2>Conservação e garantia</h2>
                    </v-flex>
                    <v-flex
                      xs12
                      style="flex-direction: column; display: flex;"
                    >
                      <v-checkbox
                        v-if="dadosVeiculo.usado"
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.unico_dono"
                        label="Único Dono"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.com_manual"
                        label="Com Manual"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.com_garantia"
                        label="Com Garantia"
                        color="info"
                      />
                      <v-checkbox
                        v-if="dadosVeiculo.usado"
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.revisoes_feitas"
                        label="Revisões feitas em concessionária"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.chave_reserva"
                        label="Chave Reserva"
                        color="info"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout
                    column
                    v-if="!isVenda"
                    class="ma-0 pa-0"
                  >
                    <v-flex xs3>
                      <v-select
                        v-model="select"
                        :items="options_cliente"
                        outlined
                        label="A quem pertence esse veículo?"
                        :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                        required
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs6 class="text-xs-center subheading gray--text font-weight-regular ma-0 pa-0" v-if="select == 'Sim'">
                      Selecione o funcinário dono do veículo
                    </v-flex>
                    <v-autocomplete  v-if="select == 'Do funcionário'"
                      outlined
                      :items="funcionarios"
                      v-model="funcionarioSelected"
                      color="primary"
                      item-text="nome_email"
                      label="Colaboradores"
                      return-object
                    ></v-autocomplete>
                  </v-layout>
                </v-layout>
              </v-form>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <v-container flex grid-list-md>
              <v-layout justify-center wrap>
                <v-bottom-navigation
                  active
                  v-model="stepOfertar"
                  color="info"
                  grow
                  class="ma-1 mb-4"
                  style="box-shadow: none;"
                >
                  <v-btn style="font-size: 1em;" class="mr-4" x-large>
                    <v-icon size="30">person</v-icon>
                    Usuários do Aplicativo
                  </v-btn>
                  <v-btn style="font-size: 1em;" class="ml-4" x-large>
                    <v-icon size="30">business</v-icon>
                    Revendedoras de Veículo
                  </v-btn>
                </v-bottom-navigation>
                <v-flex xs12>
                  <v-stepper v-model="stepOfertar">
                    <v-stepper-items>
                      <v-stepper-content step="0">
                        <h3 class="text-center">OFERTAR PARA PESSOAS FÍSICAS</h3>
                      </v-stepper-content>
                      <v-stepper-content step="1">
                        <h2 class="text-center" style="margin-bottom: 2em;">SELECIONE UMA OPÇÃO</h2>
                        <v-layout class="mb-4 mt-4" justify-space-around>
                          <v-flex xs5>
                            <v-btn
                              outlined
                              block
                              :text="btnAtivo !== 1"
                              color="info"
                              @click="btnAtivo = 1"
                              :class=" btnAtivo == 1 ?'white--text' : ''"
                            >
                              Todas as empresa
                            </v-btn>
                          </v-flex>
                          <v-flex xs5>
                            <v-btn
                              outlined
                              block
                              :text="btnAtivo !== 2"
                              color="info"
                              @click="btnAtivo = 2"
                              :class=" btnAtivo == 2 ?'white--text' : ''"
                            >
                              Empresas selecionadas
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-slide-x-transition>
                          <v-layout column v-if="btnAtivo == 2">
                            <v-layout justify-end class="mr-2 mb-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    fab
                                    small
                                    color="info"
                                    class="white--text"
                                    @click="filtroAtivo = !filtroAtivo"
                                  >
                                    <v-icon>{{ filtroAtivo ? 'filter_list_off' : 'filter_list' }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{ filtroAtivo ? 'Esconder filtro' : 'Mostrar filtro' }}
                                </span>
                              </v-tooltip>
                            </v-layout>
                            <v-slide-x-transition>
                              <v-layout justify-center v-show="filtroAtivo">
                                <v-flex xs4>
                                  <v-text-field
                                    label="Nome"
                                    color="info"
                                    v-model="nome_fantasia"
                                  />
                                </v-flex>
                                <v-flex xs3>
                                  <v-text-field
                                    label="Cidade"
                                    color="info"
                                    v-model="cidade"
                                  />
                                </v-flex>
                                <v-flex xs3>
                                  <v-select
                                    label="Estado"
                                    color="info"
                                    :items="estados"
                                    v-model="estado"
                                    clearable
                                  />
                                </v-flex>
                                <v-flex xs1>
                                  <v-btn
                                    fab
                                    color="info"
                                    small
                                    class="white--text"
                                    @click="pegarRevendedoras(true)"
                                  >
                                    <v-icon>search</v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-slide-x-transition>
                            <v-list>
                              <v-list-item
                                v-for="(empresa, index) in empresas"
                                :key="index"
                                @click="function () {
                                  const temp = isSelected(empresa)
                                  if (temp) removerEmpresa(empresa)
                                  else adicionarEmpresa(empresa)
                                }"
                                class="ma-1"
                                :style="isSelected(empresa) ? {'border': '1px solid #2196f3'} : {}"
                              >
                                <v-list-item-avatar>
                                  <v-icon color="info">business</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ empresa.nome_fantasia }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ empresa.cnpj }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    <v-icon color="info">map</v-icon>
                                    <span v-if="empresa.endereco" class="ml-1">{{ empresa.endereco.cidade }}/{{ empresa.endereco.estado }}</span>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <div class="text-xs-center">
                              <v-pagination
                                v-model="pagination_empresas.page"
                                :length="pages"
                                circle
                                color="info"
                              ></v-pagination>
                            </div>
                          </v-layout>
                        </v-slide-x-transition>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-flex>
                <v-flex xs12 class="mt-4">
                  <v-textarea
                    outlined
                    label="Descrição"
                    color="info"
                    hint="Informe aqui o estado do veículo e outras informações relevantes"
                    placeholder="Informe aqui o estado do veículo e outras informações relevantes"
                    v-model="dadosVeiculo.descricao"
                    counter
                    maxlength="300"
                  >
                  </v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-stepper-content>
          <v-stepper-content step="3" class="pa-0">
            <div>
              <div class="headline pt-2">Dados do veículo:</div>
              <v-flex xs12 class="text-xs-left pt-2" v-if="veiculo_existente.usuario"> Proprietário: {{veiculo_existente.usuario.pessoa.nome}}  |  CPF: {{veiculo_existente.usuario.pessoa.cpf}}</v-flex>
              <v-flex xs12 class="text-xs-left pt-2" v-if="veiculo_existente.empresa"> Vinculado a empresa: {{veiculo_existente.empresa.razao_social}}</v-flex>
              <v-flex xs12 class="text-xs-left pt-2">Placa: {{veiculo_existente.identificacao}}</v-flex>
              <v-flex xs12 class="text-xs-left pt-2">Modelo: {{veiculo_existente.modelo_marca}}</v-flex>
              <v-flex xs12 class="text-xs-left pt-2">Cor: <v-btn :color="formatarCor(veiculo_existente.cor)"></v-btn></v-flex>
              <h3 v-if="!veiculo_existente.empresa" class="text-xs-center pt-4">Veículo pertence a um terceiro, Confirme para adicionar a sua frota!</h3>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
    </v-card-text>
    <v-card-actions>
      <template v-if="step === 1 || step === 2">
        <v-btn
          text
          color="info"
          @click="function () {
            if (step === 1) fechar()
            else step--
          }"
          :disabled="salvando"
          x-large
          class="ml-3"
        >
          {{ step === 1 ? 'Fechar' : 'Voltar' }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="info"
          @click="function () {
            if (step === 1 && isVenda && $refs.formVeiculo.validate()) step++
            else if ($refs.formVeiculo.validate()) inserirVeiculo()
            this.activeAlert('Verifique se todos os campos foram preenchidos corretamente.', 'error')
          }"
          :loading="salvando"
          x-large
          class="mr-3"
          :disabled="step === 1 && veiculoJaAvenda && dadosVeiculo.usado"
        >
          Confirmar
        </v-btn>
      </template>
      <template v-if="step === 3">
        <v-btn
          text
          color="info"
          @click="fechar()"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!veiculo_existente.empresa"
          text
          color="green"
          @click="editarVeiculo()"
          x-large
        >
          Confirmar
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
  </template>

<script>
import { veiculoDao, funcionariosDao } from '@/api'
import { VMoney } from 'v-money'
// import Swatches from 'vue-swatches'

export default {
  // components: { Swatches },
  props: ['isVenda'],
  directives: { money: VMoney },
  data () {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      color: null,
      valid: false,
      value: 0,
      stepOfertar: 0,
      validFinanciamento: false,
      step_image: 1,
      step: 1,
      btnAtivo: 1,
      modeloVeiculo: undefined,
      alerta: undefined,
      tipo: 'error',
      veiculo_existente: {
        empresa: null,
        usuario: null,
        identificacao: null,
        modelo_marca: null,
        cor: null,
        id_veiculo: null
      },
      dadosVeiculo: {
        empresa: null,
        usado: true,
        quilometragem: 0,
        valor: '0,00',
        valor_estimado: null,
        vidro_eletrico: false,
        alarme: false,
        air_bag: false,
        som: false,
        trava_eletrica: false,
        sensor_re: false,
        ar: false,
        camera_re: false,
        air_bag_passageiro: false,
        volante_regulagem_altura: false,
        bluetooth: false,
        vidro_eletrico_traseiro: false,
        vidro_eletrico_dianteiro: false,
        rodas_liga_leve: false,
        kit_multimidia: false,
        computador_bordo: false,
        bancos_couro: false,
        air_bag_lateral: false,
        retrovisores_eletricos: false,
        freios_abs: false,
        radio: false,
        entrada_usb: false,
        air_bag_duplo: false,
        porta_copos: false,
        cd_player: false,
        distribuicao_eletronica_frenagem: false,
        desembaçador_traseiro: false,
        cambio: 1,
        direcao: 1,
        usuario: null,
        combustiveis: [],
        id_tipo_veiculo: null,
        tipo_veiculo_object: null,
        identificacao: null,
        modelo_marca: null,
        cor: null,
        chassi: null,
        marca: null,
        modelo: null,
        versao: null,
        versao_motor: null,
        motor: null,
        ano_modelo: null,
        ano_fabricacao: null,
        data_ultimo_licenciamento: null,
        qtd_vavulas: null,
        qtd_cilindros: null,
        qtd_assentos: null,
        tipo_tracao: null,
        tipo_transmissao: null,
        hp: null,
        cilindradas: null,
        tipo_carroceria: null,
        capacidade: null,
        qtd_proprietarios: null,
        qtd_fontes: null,
        qtd_alertas: null,
        data_primeiro_alerta: null,
        data_ultimo_alerta: null,
        tem_restricoes: false,
        teve_restricoes: false,
        primeira_data_expiracao_placa: null,
        ultima_data_expiracao_placa: null,
        local_registro: null,
        estado_registro: null,
        cor_texto: null,
        destinatario_oferta: null,
        descricao: null,
        kit_gnv: false,
        blindado: false,
        gps: false,
        escapamento_esportivo: false,
        contra_peso_guidon: false,
        bau: false,
        farois_neblina: false,
        quitando: false,
        ipva_pago: false,
        com_multa: false,
        de_leilao: false,
        unico_dono: false,
        com_manual: false,
        com_garantia: false,
        revisoes_feitas: false,
        chave_reserva: false,
        qtd_estoque: null
      },
      veiculo: null,
      select: null,
      options_cliente: [
        'Do funcionário',
        'Da empresa'
      ],
      pagination: {
        page: 1,
        rowsPerPage: 1000
      },
      funcionarios: [],
      tipos_veiculo: [],
      veiculoJaAvenda: false,
      salvando: false,
      cropping1: false,
      cropping2: false,
      cropping3: false,
      cropping4: false,
      cropping5: false,
      mostrar: true,
      mostrar2: true,
      mostrar3: true,
      mostrar4: true,
      mostrar5: true,
      imagem_frontal: undefined,
      imagem_traseira: undefined,
      imagem_lateral_e: undefined,
      imagem_lateral_d: undefined,
      imagem_interior: undefined,
      imagem_adicionada: false,
      empresas: [],
      empresas_selecionadas: [],
      pagination_empresas: {
        page: 1,
        rowsPerPage: 5
      },
      totalItens: 0,
      filtroAtivo: false,
      nome_fantasia: undefined,
      estado: undefined,
      cidade: undefined
    }
  },
  watch: {
    headers (val) {
      if (val) {
        this.carregarTiposVeiculo()
      }
    },
    pagination_empresas: {
      handler (val, oldval) {
        if (this.empresa) this.pegarRevendedoras(false)
      },
      deep: true
    },
    stepOfertar (val) {
      if (val === 1) this.pegarRevendedoras(false)
      else {
        this.empresas_selecionadas = []
      }
    },
    btnAtivo (val) {
      if (val === 1) this.empresas_selecionadas = []
    },
    'dadosVeiculo.combustiveis' (val) {
      if (typeof val === 'object' && val && val.length && val.length > 2) {
        const combs = this.dadosVeiculo.combustiveis
        this.dadosVeiculo.combustiveis = [combs[1], combs[2]]
      }
    },
    'dadosVeiculo.identificacao' (val) {
      this.dadosVeiculo.identificacao = this.dadosVeiculo.identificacao.toUpperCase()

      if (val && val.length === 8) {
        this.carregarInformacoes()
      }
    },
    'dadosVeiculo.cor_texto' (val) {
      this.mudarCor()
    },
    'dadosVeiculo.kit_gnv' (val) {
      const GNV = this.combustiveis.filter((c) => c.ref === 'gnv')[0]
      const idGnv = GNV ? GNV.id_combustivel : undefined
      if (val && idGnv) {
        const existe = idGnv ? !!this.dadosVeiculo.combustiveis.filter((c) => c.id_combustivel === idGnv)[0] : false
        if (!existe) this.dadosVeiculo.combustiveis.push(GNV)
      } else if (idGnv) {
        this.dadosVeiculo.combustiveis = this.dadosVeiculo.combustiveis.filter((c) => c.id_combustivel !== idGnv)
      }
    },
    'dadosVeiculo.usado' (val) {
      if (val) this.activeAlert('Esse é um veículo usado, não esqueça de inserir a placa do veículo no campo acima.', 'info')
      else {
        this.activeAlert('Esse é um veículo novo, não é preciso preencher o campo "placa"', 'info')
        this.dadosVeiculo.identificacao = null
        this.dadosVeiculo.quilometragem = null
        this.dadosVeiculo.qtd_estoque = 1
        this.dadosVeiculo.quitando = false
        this.dadosVeiculo.ipva_pago = false
        this.dadosVeiculo.com_multa = false
        this.dadosVeiculo.de_leilao = false
        this.dadosVeiculo.unico_dono = false
        this.dadosVeiculo.com_manual = false
        this.dadosVeiculo.com_garantia = false
        this.dadosVeiculo.revisoes_feitas = false
        this.dadosVeiculo.chave_reserva = false
      }
    },
    'dadosVeiculo.tipo_veiculo_object' (val) {
      if (val) {
        this.dadosVeiculo.id_tipo_veiculo = val.id_tipo_veiculo
      }
    },
    tipo_veiculo (val) {
      if (val === 'motocicleta') {
        this.dadosVeiculo.air_bag_duplo = false
        this.dadosVeiculo.blindado = false
        this.dadosVeiculo.cd_player = false
        this.dadosVeiculo.air_bag_lateral = false
        this.dadosVeiculo.air_bag_passageiro = false
        this.dadosVeiculo.air_bag_duplo = false
        this.dadosVeiculo.desembaçador_traseiro = false
        this.dadosVeiculo.direcao = null
        this.dadosVeiculo.cambio = null
        this.dadosVeiculo.kit_gnv = false
        this.dadosVeiculo.kit_multimidia = false
        this.dadosVeiculo.motor = null
        this.dadosVeiculo.porta_copos = false
        this.dadosVeiculo.radio = false
        this.dadosVeiculo.retrovisores_eletricos = false
        this.dadosVeiculo.sensor_re = false
        this.dadosVeiculo.ar = false
        this.dadosVeiculo.som = false
        this.dadosVeiculo.bancos_couro = false
        this.dadosVeiculo.vidro_eletrico = false
        this.dadosVeiculo.vidro_eletrico_dianteiro = false
        this.dadosVeiculo.volante_regulagem_altura = false
      } else {
        this.dadosVeiculo.bau = false
        this.dadosVeiculo.escapamento_esportivo = false
        this.dadosVeiculo.contra_peso_guidon = false
        this.dadosVeiculo.farois_neblina = false
        this.dadosVeiculo.gps = false
        this.dadosVeiculo.cilindradas = null
      }
    },
    filtro (val) {
      this.pegarModeloVeiculo()
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    },
    empresa () {
      return this.$store.getters.empresa
    },
    estados () {
      return this.$store.getters.estados
    },
    filtro () {
      const data = {}
      if (this.dadosVeiculo.identificacao) data.identificacao__iexact = this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()
      return data
    },
    pages () {
      const count = this.totalItens
      if (this.pagination_empresas.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination_empresas.rowsPerPage)
    },
    tituloCard () {
      switch (this.step) {
        case 1:
          return 'ADICIONAR VEÍCULO'
        case 2:
          return 'OFERTAR VEÍCULO PARA'
        case 3:
          return 'VEÍCULO JÁ CADASTRADO'
        default:
          return ''
      }
    },
    tipo_veiculo () {
      if (this.dadosVeiculo && this.dadosVeiculo.tipo_veiculo_object) {
        return this.dadosVeiculo.tipo_veiculo_object.denominacao.toLowerCase()
      }
      return undefined
    }
  },
  mounted () {
    if (this.headers) {
      this.carregarTiposVeiculo()
      this.carregarFuncionarios()
      this.$store.dispatch('carregarCombustiveis')
    }
  },
  methods: {
    fechar () {
      this.$refs.formVeiculo.reset()
      this.step = 1
      this.$emit('carregar')
      this.$emit('fechar')
    },
    showError (msg) {
      this.alerta = msg
      this.tipo = 'error'
    },
    activeAlert (text, type) {
      this.alerta = text
      this.tipo = type
      const interval = setInterval(() => {
        this.alerta = undefined
        clearInterval(interval)
      }, 5000)
    },
    adicionarEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const existe = !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
      if (existe) {
        this.activeAlert('Essa empresa já foi inserida', 'error')
        return
      }
      this.empresas_selecionadas.push(empresa.cnpj)
    },
    removerEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const index = this.empresas_selecionadas.indexOf(empresa.cnpj)
      if (index === -1) {
        this.activeAlert('Essa empresa não foi inserida a lista.', 'error')
        return
      }
      this.empresas_selecionadas.splice(index, 1)
    },
    isSelected (empresa) {
      return !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
    },
    carregarTiposVeiculo () {
      veiculoDao.getTiposVeiculo(this.headers)
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.tipos_veiculo = json
          const result = Object.keys(json).find(key => {
            return json[key].denominacao === 'Carro'
          })
          this.dadosVeiculo.tipo_veiculo_object = json[result]
        })
    },
    carregarFuncionarios () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      funcionariosDao.getFuncionariosEmpresaConfirmados(data, this.headers)
        .then(res => res.json())
        .then(json => {
          this.funcionarios = json.results
        })
    },
    carregarInformacoes () {
      this.dadosVeiculo = {
        empresa: null,
        usuario: null,
        combustiveis: [],
        identificacao: this.dadosVeiculo.identificacao,
        id_tipo_veiculo: this.dadosVeiculo.id_tipo_veiculo,
        tipo_veiculo_object: this.dadosVeiculo.tipo_veiculo_object,
        modelo_marca: null,
        cor: null,
        chassi: null,
        marca: null,
        modelo: null,
        versao: null,
        versao_motor: null,
        motor: null,
        ano_modelo: null,
        ano_fabricacao: null,
        data_ultimo_licenciamento: null,
        qtd_vavulas: null,
        qtd_cilindros: null,
        qtd_assentos: null,
        tipo_tracao: null,
        tipo_transmissao: null,
        hp: null,
        cilindradas: null,
        tipo_carroceria: null,
        capacidade: null,
        qtd_proprietarios: null,
        qtd_fontes: null,
        qtd_alertas: null,
        data_primeiro_alerta: null,
        data_ultimo_alerta: null,
        tem_restricoes: false,
        teve_restricoes: false,
        primeira_data_expiracao_placa: null,
        ultima_data_expiracao_placa: null,
        local_registro: null,
        estado_registro: null,
        cor_texto: null,
        usado: true,
        quilometragem: 0,
        valor: '0,00',
        valor_estimado: null,
        vidro_eletrico: false,
        alarme: false,
        air_bag: false,
        som: false,
        trava_eletrica: false,
        sensor_re: false,
        ar: false,
        camera_re: false,
        air_bag_passageiro: false,
        volante_regulagem_altura: false,
        bluetooth: false,
        vidro_eletrico_traseiro: false,
        vidro_eletrico_dianteiro: false,
        rodas_liga_leve: false,
        kit_multimidia: false,
        computador_bordo: false,
        bancos_couro: false,
        air_bag_lateral: false,
        retrovisores_eletricos: false,
        freios_abs: false,
        radio: false,
        entrada_usb: false,
        air_bag_duplo: false,
        porta_copos: false,
        cd_player: false,
        distribuicao_eletronica_frenagem: false,
        desembaçador_traseiro: false,
        kit_gnv: false,
        blindado: false,
        gps: false,
        escapamento_esportivo: false,
        contra_peso_guidon: false,
        bau: false,
        farois_neblina: false,
        quitando: false,
        ipva_pago: false,
        com_multa: false,
        de_leilao: false,
        unico_dono: false,
        com_manual: false,
        com_garantia: false,
        revisoes_feitas: false,
        chave_reserva: false,
        qtd_estoque: null,
        cambio: 1,
        direcao: 1,
        destinatario_oferta: null,
        descricao: null
      }
      this.$store.dispatch('pegarInformacoesVeiculo', this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()).then(data => {
        if (data.informacoes.marca && data.informacoes.modelo) {
          this.dadosVeiculo.modelo_marca = `(${data.informacoes.marca}) ${data.informacoes.modelo.split(' ')[0]}`
        }
        // Verificar Combustível
        if (data.informacoes.tipo_combustivel && data.informacoes.tipo_combustivel !== '') {
          // Antiga API de informações veiculos
          /* if (data.informacoes.tipo_combustivel === 'FLEX') {
            this.combustiveis.map((c) => {
              if (c.nome.toUpperCase() === 'GASOLINA COMUM' || c.nome.toUpperCase() === 'ETANOL COMUM') {
                this.dadosVeiculo.combustiveis.push(c)
              }
            })
          } else {
            this.combustiveis.map((c) => {
              if (c.nome.toUpperCase().split(' ')[0] === data.informacoes.tipo_combustivel.toUpperCase()) {
                this.dadosVeiculo.combustiveis.push(c.id_combustivel)
              }
            })
          } */
          if (data.informacoes.tipo_combustivel.indexOf('/') === -1) {
            if (data.informacoes.tipo_combustivel.trim() === 'GASOLINA') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'GASOLINA COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
            if (data.informacoes.tipo_combustivel.trim() === 'ALCOOL') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'ETANOL COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
          } else {
            if (data.informacoes.tipo_combustivel.split('/')[0].trim() === 'GASOLINA' || data.informacoes.tipo_combustivel.split('/')[1].trim() === 'GASOLINA') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'GASOLINA COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
            if (data.informacoes.tipo_combustivel.split('/')[0].trim() === 'ALCOOL' || data.informacoes.tipo_combustivel.split('/')[1].trim() === 'ALCOOL') {
              const result = Object.keys(this.combustiveis).find(key => {
                return this.combustiveis[key].nome.toUpperCase() === 'ETANOL COMUM'
              })
              this.dadosVeiculo.combustiveis.push(this.combustiveis[result])
            }
          }
        }
        if (data.informacoes.tipo && data.informacoes.tipo !== '') {
          this.tipos_veiculo.map((tipo) => {
            if (tipo.denominacao.toUpperCase() === data.informacoes.tipo.toUpperCase()) {
              this.dadosVeiculo.id_tipo_veiculo = tipo.id_tipo_veiculo
              this.dadosVeiculo.tipo_veiculo_object = tipo
            }
          })
        }
        let corVeiculo
        if (data.historico !== undefined && data.historico !== null && data.historico.length > 0) {
          corVeiculo = data.historico.map((h) => {
            if (h.cor !== null) {
              return h.cor
            }
          })
          corVeiculo = corVeiculo[0].toUpperCase()
        } else {
          corVeiculo = data.informacoes.cor
          corVeiculo = corVeiculo.toUpperCase()
        }
        if (corVeiculo) {
          this.dadosVeiculo.cor_texto = corVeiculo[0] + corVeiculo.toLowerCase().substring(1, corVeiculo.length)
          switch (corVeiculo) {
            case 'BRANCA':
              this.color = {
                alpha: 1,
                hex: '#FFFFFF',
                hexa: '#FFFFFFFF',
                hsla: {},
                hsva: {},
                hue: 0,
                rgba: {
                  a: 1,
                  b: 255,
                  g: 255,
                  r: 255
                }
              }
              break
            case 'CINZA':
              this.color = {
                alpha: 1,
                hex: '#808080',
                hexa: '#808080FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0.51,
                  s: 0
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 1,
                  v: 0.51
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 128,
                  g: 128,
                  r: 128
                }
              }
              break
            case 'PRATA':
              this.color = {
                alpha: 1,
                hex: '#C0C0C0',
                hexa: '#C0C0C0FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0.51,
                  s: 0
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 1,
                  v: 0.51
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 192,
                  g: 192,
                  r: 192
                }
              }
              break
            case 'PRETA':
              this.color = {
                alpha: 1,
                hex: '#000000',
                hexa: '#000000FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0,
                  s: 0
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 0.9633333333333334,
                  v: 0
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 0,
                  r: 0
                }
              }
              break
            case 'VERMELHA':
              this.color = {
                alpha: 1,
                hex: '#FF0000',
                hexa: '#FF0000FF',
                hsla: {
                  a: 1,
                  h: 0,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 0,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 0,
                  r: 255
                }
              }
              break
            case 'AZUL':
              this.color = {
                alpha: 1,
                hex: '#0000FF',
                hexa: '#0000FFFF',
                hsla: {
                  a: 1,
                  h: 228.78504672897193,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 228.78504672897193,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 255,
                  g: 0,
                  r: 0
                }
              }
              break
            case 'AMARELA':
              this.color = {
                alpha: 1,
                hex: '#FFFF00',
                hexa: '#FFFF00FF',
                hsla: {
                  a: 1,
                  h: 60.560747663551396,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 60.560747663551396,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 255,
                  r: 255
                }
              }
              break
            case 'VERDE':
              this.color = {
                alpha: 1,
                hex: '#00FF00',
                hexa: '#00FF00FF',
                hsla: {
                  a: 1,
                  h: 124.48598130841121,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 124.48598130841121,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 0,
                  g: 255,
                  r: 0
                }
              }
              break
            case 'ROSA':
              this.color = {
                alpha: 1,
                hex: '#FF007F',
                hexa: '#FF007FFF',
                hsla: {
                  a: 1,
                  h: 302.803738317757,
                  l: 0.5,
                  s: 1
                },
                hsva: {
                  a: 1,
                  h: 302.803738317757,
                  s: 1,
                  v: 1
                },
                hue: 0,
                rgba: {
                  a: 1,
                  b: 127,
                  g: 0,
                  r: 255
                }
              }
          }
        }
        if (!this.isVenda) {
          this.dadosVeiculo.tem_restricoes = data.tem_restricoes
          this.dadosVeiculo.teve_restricoes = data.teve_restricoes
          this.dadosVeiculo.chassi = data.informacoes.chassi ? data.informacoes.chassi : null
          this.dadosVeiculo.versao = data.informacoes.versao ? data.informacoes.versao : null
          this.dadosVeiculo.versao_motor = data.informacoes.versao_motor ? data.informacoes.versao_motor : null
          this.dadosVeiculo.motor = data.informacoes.motor ? data.informacoes.motor : null
          this.dadosVeiculo.data_ultimo_licenciamento = data.informacoes.data_ultimo_licenciamento ? data.informacoes.data_ultimo_licenciamento : null
          this.dadosVeiculo.qtd_vavulas = data.informacoes.qtd_vavulas ? data.informacoes.qtd_vavulas : null
          this.dadosVeiculo.qtd_cilindros = data.informacoes.qtd_cilindros ? data.informacoes.qtd_cilindros : null
          this.dadosVeiculo.qtd_assentos = data.informacoes.qtd_assentos ? data.informacoes.qtd_assentos : null
          this.dadosVeiculo.qtd_proprietarios = data.qtd_proprietarios ? data.qtd_proprietarios : null
          this.dadosVeiculo.qtd_fontes = data.qtd_fontes ? data.qtd_fontes : null
          this.dadosVeiculo.qtd_alertas = data.qtd_alertas ? data.qtd_alertas : null
          this.dadosVeiculo.tipo_tracao = data.informacoes.tipo_tracao ? data.informacoes.tipo_tracao : null
          this.dadosVeiculo.tipo_transmissao = data.informacoes.tipo_transmissao ? data.informacoes.tipo_transmissao : null
          this.dadosVeiculo.hp = data.informacoes.hp ? data.informacoes.hp : null
          this.dadosVeiculo.cilindradas = data.informacoes.cilindradas ? data.informacoes.cilindradas : null
          this.dadosVeiculo.tipo_carroceria = data.informacoes.tipo_carroceria ? data.informacoes.tipo_carroceria : null
          this.dadosVeiculo.capacidade = data.informacoes.capacidade ? data.informacoes.capacidade : null
          this.dadosVeiculo.ultima_data_expiracao_placa = this.formatarData(data.ultima_data_expiracao_placa)
          this.dadosVeiculo.primeira_data_expiracao_placa = this.formatarData(data.primeira_data_expiracao_placa)
          this.dadosVeiculo.data_primeiro_alerta = this.formatarData(data.data_primeiro_alerta)
          this.dadosVeiculo.data_ultimo_alerta = this.formatarData(data.data_ultimo_alerta)
        }
        this.dadosVeiculo.valor_estimado = data.informacoes.valor_estimado
        if (this.dadosVeiculo.marca == null) this.dadosVeiculo.marca = data.informacoes.marca ? data.informacoes.marca[0].toUpperCase() + data.informacoes.marca.toLowerCase().substring(1) : null
        if (this.dadosVeiculo.modelo == null) this.dadosVeiculo.modelo = data.informacoes.modelo ? data.informacoes.modelo : null
        if (this.dadosVeiculo.modelo_marca == null) this.dadosVeiculo.modelo_marca = `${data.informacoes.marca} ${data.informacoes.modelo.split(' ')[0]}`
        if (this.dadosVeiculo.ano_modelo == null) this.dadosVeiculo.ano_modelo = data.informacoes.ano_modelo ? data.informacoes.ano_modelo : null
        if (this.dadosVeiculo.quilometragem == null) this.dadosVeiculo.quilometragem = data.informacoes.quilometragem ? data.informacoes.quilometragem : 0
        if (this.dadosVeiculo.ano_fabricacao == null) this.dadosVeiculo.ano_fabricacao = data.informacoes.ano_fabricacao + '/' + data.informacoes.ano_modelo
        if (data.historico !== undefined && data.historico !== null && data.historico.length > 0) {
          const registro = data.historico[data.historico.length - 1]
          this.dadosVeiculo.local_registro = registro.local_registro
          this.dadosVeiculo.estado_registro = registro.estado_registro
          this.dadosVeiculo.cor_texto = registro.cor
        } else {
          this.dadosVeiculo.local_registro = data.informacoes.municipio
          this.dadosVeiculo.estado_registro = data.informacoes.estado
          this.dadosVeiculo.cor_texto = data.informacoes.cor
        }
      })
    },
    formatarData (data) {
      if (data !== null && data !== undefined) {
        return data.split('T')[0]
      }
    },
    async inserirVeiculo () {
      this.salvando = true
      if (this.veiculoJaAvenda && this.dadosVeiculo.usado) {
        this.activeAlert('Essa placa já foi vinculada a um veículo', 'error')
        this.salvando = false
        return
      }
      if (this.isVenda && !this.imagem_adicionada) {
        this.activeAlert('É precisa adicionar ao menos uma imagem do veículo.', 'error')
        this.salvando = false
        return
      }
      if (this.$refs.formVeiculo.validate() && this.empresa && ((this.isVenda && this.imagem_adicionada) || !this.isVenda)) {
        this.dadosVeiculo.empresa = this.empresa.cnpj
        this.dadosVeiculo.modelo_marca = `(${this.dadosVeiculo.marca}) ${this.dadosVeiculo.modelo.split(' ')[0]}`
        this.dadosVeiculo.cor = this.color.hex
        if (this.funcionarioSelected) {
          this.dadosVeiculo.usuario = this.funcionarioSelected.pessoa.id_pessoa
        }
        if (!this.dadosVeiculo.usado) {
          this.dadosVeiculo.identificacao = ''
        }
        if (this.imagem_frontal && (this.imagem_frontal !== this.dadosVeiculo.imagem_frontal)) {
          await this.inserirImagemFirebase(this.imagem_frontal, 'imagens', 'frontal', 1)
        }
        if (this.imagem_traseira && (this.imagem_traseira !== this.dadosVeiculo.imagem_traseira)) {
          await this.inserirImagemFirebase(this.imagem_traseira, 'imagens', 'traseira', 2)
        }
        if (this.imagem_interior && (this.imagem_interior !== this.dadosVeiculo.imagem_interior)) {
          await this.inserirImagemFirebase(this.imagem_interior, 'imagens', 'interior', 3)
        }
        if (this.imagem_lateral_e && (this.imagem_lateral_e !== this.dadosVeiculo.imagem_lateral_e)) {
          await this.inserirImagemFirebase(this.imagem_lateral_e, 'imagens', 'lateral_esquerda', 4)
        }
        if (this.imagem_lateral_d && (this.imagem_lateral_d !== this.dadosVeiculo.imagem_lateral_d)) {
          await this.inserirImagemFirebase(this.imagem_lateral_d, 'imagens', 'lateral_direita', 5)
        }

        if (this.dadosVeiculo.valor) this.dadosVeiculo.valor = Number(this.dadosVeiculo.valor.replaceAll('.', '').replace(',', '.'))
        if (this.empresas_selecionadas.length > 0) this.dadosVeiculo.empresas_ofertadas = this.empresas_selecionadas

        this.dadosVeiculo.aVenda = this.isVenda
        this.dadosVeiculo.destinatario_oferta = this.isVenda ? this.stepOfertar + 1 : null
        this.dadosVeiculo.descricao = this.dadosVeiculo.descricao ? this.dadosVeiculo.descricao : null
        this.dadosVeiculo.identificacao = this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()
        await veiculoDao.insert(this.dadosVeiculo, this.headers)
          .then(response => {
            if (response.status === 200) {
              this.activeAlert('Veículo adicionado com sucesso', 'success')
              this.$emit('info', 'Veículo adicionado com sucesso')
              this.$emit('carregar')
              this.fechar()
            } else if (response.status === 202) {
              response.json().then(veiculo => {
                this.veiculo_existente.empresa = veiculo.empresa
                this.veiculo_existente.usuario = veiculo.usuario
                this.veiculo_existente.identificacao = veiculo.identificacao
                this.veiculo_existente.modelo_marca = veiculo.modelo_marca
                this.veiculo_existente.cor = veiculo.cor
                this.veiculo_existente.id_veiculo = veiculo.id_veiculo
              })
              this.step = 3
            } else {
              this.activeAlert('Desculpe, não foi possível adicionar o veículo no momento', 'error')
            }
          })
      } else {
        this.activeAlert('Não foi possível inserir veículo, verifique se todas as informações foram preenchidas.', 'error')
      }
      this.salvando = false
    },
    editarVeiculo () {
      if (!this.veiculo_existente.empresa) {
        this.veiculo_existente.empresa = this.empresa.cnpj
        veiculoDao.adicionarVeiculoEmpresa(this.veiculo_existente, this.veiculo_existente.id_veiculo, this.headers)
          .then(response => {
            if (response.ok) {
              this.activeAlert('As informações do veículo foram alteradas com sucesso.', 'success')
              this.$store.dispatch('setSuccess', 'As informações do veículo foram alteradas com sucesso')
              this.$emit('carregar')
              this.fechar()
            } else {
              this.activeAlert('Não foi possível editar as informações do veículo, verifique se todas as informações foram preenchidas.', 'error')
              this.$store.dispatch('setError', 'Desculpe, não foi possível modificar o veículo no momento')
              this.fechar()
            }
          })
      }
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    carregarImagem (event) {
      switch (this.step_image) {
        case 1:
          this.imagem_frontal = null
          this.mostrar = false
          break
        case 2:
          this.imagem_traseira = null
          this.mostrar2 = false
          break
        case 3:
          this.imagem_interior = null
          this.mostrar3 = false
          break
        case 4:
          this.imagem_lateral_e = null
          this.mostrar4 = false
          break
        case 5:
          this.imagem_lateral_d = null
          this.mostrar5 = false
          break
      }

      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          let ref = null
          switch (this.step_image) {
            case 1:
              this.cropping1 = true
              this.$refs.croppieimagemFrontalRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemFrontalRef
              break
            case 2:
              this.cropping2 = true
              this.$refs.croppieimagemTraseiraRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemTraseiraRef
              break
            case 3:
              this.cropping3 = true
              this.$refs.croppieimagemInteriorRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemInteriorRef
              break
            case 4:
              this.cropping4 = true
              this.$refs.croppieimagemLateralERef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemLateralERef
              break
            case 5:
              this.cropping5 = true
              this.$refs.croppieimagemLateralDRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemLateralDRef
              break
          }
          this.$nextTick(() => {
            ref.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    }, // carregarImagem
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      let refscroppie = null
      switch (this.step_image) {
        case 1:
          refscroppie = this.$refs.croppieimagemFrontalRef
          this.mostrar = true
          break
        case 2:
          refscroppie = this.$refs.croppieimagemTraseiraRef
          this.mostrar2 = true
          break
        case 3:
          refscroppie = this.$refs.croppieimagemInteriorRef
          this.mostrar3 = true
          break
        case 4:
          refscroppie = this.$refs.croppieimagemLateralERef
          this.mostrar4 = true
          break
        case 5:
          refscroppie = this.$refs.croppieimagemLateralDRef
          this.mostrar5 = true
          break
      }

      refscroppie.result(options, output => {
        switch (this.step_image) {
          case 1:
            this.imagem_frontal = output
            this.cropping1 = false
            this.imagem_adicionada = true
            break
          case 2:
            this.imagem_traseira = output
            this.cropping2 = false
            this.imagem_adicionada = true
            break
          case 3:
            this.imagem_interior = output
            this.cropping3 = false
            this.imagem_adicionada = true
            break
          case 4:
            this.imagem_lateral_e = output
            this.cropping4 = false
            this.imagem_adicionada = true
            break
          case 5:
            this.imagem_lateral_d = output
            this.cropping5 = false
            this.imagem_adicionada = true
            break
        }
      })
    }, // recortarImagem
    async inserirImagemFirebase (imagem, local, prefixo, step) {
      const data = {
        arquivo: imagem,
        id: this.empresa.cnpj,
        identificacao: this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim(),
        local: local,
        prefixo: prefixo,
        tipo: 'png',
        rota: 'veiculos'
      }
      const variavel = await this.$store.dispatch('inserirFirebase', data)
      switch (step) {
        case 1:
          this.dadosVeiculo.imagem_frontal = variavel
          this.cropping1 = false
          break
        case 2:
          this.dadosVeiculo.imagem_traseira = variavel
          this.cropping2 = false
          break
        case 3:
          this.dadosVeiculo.imagem_interior = variavel
          this.cropping3 = false
          break
        case 4:
          this.dadosVeiculo.imagem_lateral_e = variavel
          this.cropping4 = false
          break
        case 5:
          this.dadosVeiculo.imagem_lateral_d = variavel
          this.cropping5 = false
          break
      }
    }, // inserirImagemFirebase
    mudarCor () {
      let corVeiculo = this.dadosVeiculo.cor_texto
      if (!corVeiculo) return
      corVeiculo = corVeiculo.toUpperCase()
      switch (corVeiculo) {
        case 'BRANCA':
          this.color = {
            alpha: 1,
            hex: '#FFFFFF',
            hexa: '#FFFFFFFF',
            hsla: {
              a: 1,
              h: 0,
              l: 1,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 255,
              g: 255,
              r: 255
            }
          }
          break
        case 'CINZA':
          this.color = {
            alpha: 1,
            hex: '#636363',
            hexa: '#636363FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.39,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 0.39
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 99,
              g: 99,
              r: 99
            }
          }
          break
        case 'PRATA':
          this.color = {
            alpha: 1,
            hex: '#C6C6C6',
            hexa: '#C6C6C6FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.7763541666666667,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 0.7763541666666667
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 198,
              g: 198,
              r: 198
            }
          }
          break
        case 'PRETA':
          this.color = {
            alpha: 1,
            hex: '#000000',
            hexa: '#000000FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0.9633333333333334,
              v: 0
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 0,
              g: 0,
              r: 0
            }
          }
          break
        case 'VERMELHA':
          this.color = {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 0,
              s: 1,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 0,
              g: 0,
              r: 255
            }
          }
          break
        case 'AZUL':
          this.color = {
            alpha: 1,
            hex: '#0013FF',
            hexa: '#0013FFFF',
            hsla: {
              a: 1,
              h: 235.51401869158877,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 235.51401869158877,
              s: 1,
              v: 1
            },
            hue: 235.51401869158877,
            rgba: {
              a: 1,
              b: 255,
              g: 19,
              r: 0
            }
          }
          break
        case 'AMARELA':
          this.color = {
            alpha: 1,
            hex: '#F2F408',
            hexa: '#F2F408FF',
            hsla: {
              a: 1,
              h: 60.560747663551396,
              l: 0.4941163194444444,
              s: 0.935483870967742
            },
            hsva: {
              a: 1,
              h: 60.560747663551396,
              s: 0.4941163194444444,
              v: 0.935483870967742
            },
            hue: 60.560747663551396,
            rgba: {
              a: 1,
              b: 0,
              g: 255,
              r: 255
            }
          }
          break
        case 'VERDE':
          this.color = {
            alpha: 1,
            hex: '#15D013',
            hexa: '#15D013FF',
            hsla: {
              a: 1,
              h: 119.43925233644859,
              l: 0.4464444444444445,
              s: 0.8292682926829267
            },
            hsva: {
              a: 1,
              h: 119.43925233644859,
              s: 0.9066666666666666,
              v: 0.8166666666666667
            },
            hue: 119.43925233644859,
            rgba: {
              a: 1,
              b: 19,
              g: 208,
              r: 21
            }
          }
          break
        case 'ROSA':
          this.color = {
            alpha: 1,
            hex: '#FF007F',
            hexa: '#FF007FFF',
            hsla: {
              a: 1,
              h: 302.803738317757,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 302.803738317757,
              s: 1,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 127,
              g: 0,
              r: 255
            }
          }
          break
        case 'MARROM':
          this.color = {
            alpha: 1,
            hex: '#794727',
            hexa: '#794727FF',
            hsla: {
              a: 1,
              h: 23.551401869158877,
              l: 0.31439374999999997,
              s: 0.5151515151515151
            },
            hsva: {
              a: 1,
              h: 23.551401869158877,
              s: 0.68,
              v: 0.47635416666666663
            },
            hue: 23.551401869158877,
            rgba: {
              a: 1,
              b: 39,
              g: 71,
              r: 121
            }
          }
          break
        case 'BEGE':
          this.color = {
            alpha: 1,
            hex: '#DAC011',
            hexa: '#DAC011FF',
            hsla: {
              a: 1,
              h: 52.14953271028037,
              l: 0.46243124999999996,
              s: 0.851851851851852
            },
            hsva: {
              a: 1,
              h: 52.14953271028037,
              s: 0.92,
              v: 0.8563541666666666
            },
            hue: 52.14953271028037,
            rgba: {
              a: 1,
              b: 17,
              g: 192,
              r: 218
            }
          }
      }
    }, // mudarCor
    setCombustiveis (combustiveis) {
      this.dadosVeiculo.combustiveis = []
      combustiveis.forEach(comb => {
        this.dadosVeiculo.combustiveis.push(comb.combustivel)
      })
    }, // setCombustiveis
    async pegarModeloVeiculo () {
      if (!this.dadosVeiculo.identificacao) return
      this.salvando = true
      this.alerta = ''
      this.veiculoJaAvenda = false
      const data = { empresa: this.empresa.cnpj }
      if (this.dadosVeiculo.identificacao) data.identificacao__iexact = this.dadosVeiculo.identificacao.replace(/[-]/g, '').toUpperCase().trim()
      await this.$store.dispatch('pegarDetalhesVeiculo', data).then(veiculos => {
        veiculos = veiculos.filter(veiculo => veiculo.ativo)
        if (veiculos.length > 0) {
          this.veiculoJaAvenda = true
          this.showError('Um veículo com essa placa já foi cadastrado no sistema')
        }
      })
      this.salvando = false
    }, // pegarModeloVeiculo
    pegarRevendedoras (isBtn) {
      const data = {
        tipo_empresa: 2,
        empresa: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination_empresas.page
      if (this.cidade) data.endereco__cidade__icontains = this.cidade
      if (this.estado) data.endereco__estado__icontains = this.estado
      if (this.nome_fantasia) data.nome_fantasia__icontains = this.nome_fantasia
      this.$store.dispatch('pegarEmpresasAtivas', data).then(json => {
        this.empresas = json.results
        this.totalItens = json.count
      })
    } // pegarRevendedoras
  }
}
</script>

<style scoped>
.custom-ui-class {
    width: 348px;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
}

</style>
