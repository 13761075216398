import settings from '@/settings'

const insert = (data, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/cadastroveiculopelaempresa/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVeiculosByEmpresa = (headers, filtros) => {
  let url = `${settings.apetrusApi}/veiculos/porempresa/?`
  const keys = Object.keys(filtros)
  for (let i = 0; i < keys.length; i++) {
    url = `${url}${url[url.length - 1] === '?' ? '' : '&'}${keys[i]}=${filtros[keys[i]]}`
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVeiculosByEmpresaVoucher = (headers, data) => {
  return fetch(`${settings.apetrusApi}/veiculos/porempresavoucher/?cnpj=${data}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVeiculosPorFuncionario = (headers, cpf, cnpj) => {
  return fetch(`${settings.apetrusApi}/veiculos/porfuncionario/?cpf=${cpf}&cnpj=${cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVeiculosPorClasse = (headers, data) => {
  return fetch(`${settings.apetrusApi}/veiculos/porclasse/?classe=${data.classe}&empresa=${data.cnpj}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const deleteVeiculoByEmpresa = (id, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/deletarveiculoempresa/?id_veiculo=${id}`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}
const confirmarVisualizacaoVeiculo = (headers, data) => {
  const url = `${settings.apetrusApi}/veiculos/confirmarvisualizacao/`
  return fetch(url, {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify(data),
    headers: headers
  })
}

const getTiposVeiculo = (headers) => {
  return fetch(`${settings.apetrusApi}/tiposveiculo/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const updateForEmpresa = (data, id, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/atualizarveiculoempresa/?id_veiculo=${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const adicionarVeiculoEmpresa = (data, id, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/adicionarveiculoempresa/?id_veiculo=${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const setMotorista = (data, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/setmotorista/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const removeMotorista = (data, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/removemotorista/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pegarInformacoesVeiculo = (headers, data) => {
  return fetch(`${settings.apetrusApi}/veiculos/informacoesveiculocompreco/?placa=${data}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const existe = (headers, placa) => {
  return fetch(`${settings.apetrusApi}/veiculos/existe/${placa}/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const preCadastro = (data, headers) => {
  return fetch(`${settings.apetrusApi}/veiculos/precadastro/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const aVenda = (headers, filtros) => {
  // Mostra Veículos avenda ou veículos da empresa para vender ou trocar
  let url = `${settings.apetrusApi}/veiculos/avenda/`
  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const detalhar = (headers, id) => {
  return fetch(`${settings.apetrusApi}/veiculos/${id}/`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const solicitacao = (headers, payload) => {
  let url = `${settings.apetrusApi}/veiculos/solicitacao/`
  const data = {
    method: payload.method || 'GET',
    mode: 'cors',
    headers: headers
  }
  if (payload.method === 'GET') {
    data.cache = 'default'
    if (payload) {
      const keys = Object.keys(payload)
      for (let i = 0; i < keys.length; i++) {
        url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${payload[keys[i]]}`
      }
    }
  } else data.body = JSON.stringify(payload)
  return fetch(url, data)
}

const pegarPropostasVeiculos = (headers, payload) => {
  let url = `${settings.apetrusApi}/veiculos/negociacoes/?is_empresa=true`
  if (payload) {
    const keys = Object.keys(payload)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${payload[keys[i]]}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const editarPropostaVeiculo = (headers, data, id, cnpj) => {
  return fetch(`${settings.apetrusApi}/veiculos/editarnegociacao/${id}/?empresa=${cnpj}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const colocarAvenda = (headers, id, cnpj) => {
  return fetch(`${settings.apetrusApi}/veiculos/colocar-a-venda/${id}/?empresa=${cnpj}`, {
    method: 'PUT',
    body: JSON.stringify(),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const pegarDetalhes = (headers, filtros) => {
  let url = `${settings.apetrusApi}/veiculos/pegardetalhes/`

  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const opcoesFinanciamento = (headers, payload) => {
  let url = `${settings.apetrusApi}/veiculos/opcoes-de-financiamento/`
  const data = {
    method: payload.method || 'GET',
    mode: 'cors',
    headers: headers
  }
  if (payload.method === 'GET') {
    data.cache = 'default'
    if (payload) {
      const keys = Object.keys(payload)
      for (let i = 0; i < keys.length; i++) {
        url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${payload[keys[i]]}`
      }
    }
  } else data.body = JSON.stringify(payload)
  return fetch(url, data)
}

const filaDeEspera = (headers, filtros) => {
  let url = `${settings.apetrusApi}/veiculos/fila-de-espera/`

  if (filtros) {
    const keys = Object.keys(filtros)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${url[url.length - 1] === '/' ? '?' : '&'}${keys[i]}=${filtros[keys[i]]}`
    }
  }

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  insert,
  getVeiculosByEmpresa,
  deleteVeiculoByEmpresa,
  confirmarVisualizacaoVeiculo,
  getVeiculosByEmpresaVoucher,
  getTiposVeiculo,
  updateForEmpresa,
  setMotorista,
  removeMotorista,
  getVeiculosPorFuncionario,
  adicionarVeiculoEmpresa,
  pegarInformacoesVeiculo,
  getVeiculosPorClasse,
  existe,
  preCadastro,
  aVenda,
  detalhar,
  solicitacao,
  pegarPropostasVeiculos,
  editarPropostaVeiculo,
  colocarAvenda,
  pegarDetalhes,
  opcoesFinanciamento,
  filaDeEspera
}
