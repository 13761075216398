<template>
  <v-card>
    <v-toolbar
      dense
      dark
      color="primary"
    >
      <v-toolbar-title>
        Fazer Proposta Para {{posto ? 'Posto' : 'Rede'}}
      </v-toolbar-title>
    </v-toolbar>
    <br>
    <v-card-text style="font-size: 1.0em; text-align: justify;">
      Você pode fazer uma proposta diretamente com {{posto ? 'o posto' : 'a rede'}}<br> para negociar descontos exclusivos.
    </v-card-text>

    <v-card-text>
      <v-select
              class="mt-4"
              :items="[
                {name:'Pagamento antecipado (pré-pago)', value: 'prepago'},
                // {name:'Solicitação de crédito (pós-pago)', value: 'pospago'},
                {name:'Pagamento no posto (presencial)', value: 'presencial'},
                // {name: 'Deixar posto escolher', value: null}
              ]"
              label="Selecione forma de pagamento"
              item-text="name"
              item-value="value"
              v-model="modelo_faturamento"
            ></v-select>
            <v-select v-if="modelo_faturamento == 'pospago'"
              class="mt-4"
              :items="[
                {name:'Semanal', value: 0},
                {name:'Quinzenal', value: 1},
                {name:'Mensal', value: 2},
                //{name: 'Deixar posto escolher', value: null}
              ]"
              label="Selecione o perído do faturamento"
              item-text="name"
              v-model="periodo_pos"
              item-value="value">
            </v-select>
            <v-select
              label="Para"
              :items="[
                { combustivel: { nome: 'Todos os Combustíveis', id_combustivel: 0 } },
                ...combustiveis
              ]"
              item-text="combustivel.nome"
              item-value="combustivel.id_combustivel"
              v-model="id_combustivel"
              outline
              hide-details
              single-line
            />
    </v-card-text>
    <v-card-actions align-content-space-between>
      <v-btn color="grey" text @click="fechar">Cancelar</v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="!modelo_faturamento || propostaAtiva" color="green darken-1" text @click="lancarProposta">Enviar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import { VMoney } from 'v-money'

export default {
  props: ['posto', 'rede'],
  data () {
    return {
      periodo_pos: 0,
      modelo_faturamento: '',
      propostaAtiva: false,
      id_combustivel: 0,
      combustiveis: [],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      }
    }
  },
  computed: {
    empresa () {
      return this.$store.getters.empresa
    }
  },
  beforeMount () {
    this.pegarCombustiveis()
  },
  methods: {
    lancarProposta () {
      let cnpj
      let idRede
      if (this.posto) {
        cnpj = this.posto.cnpj
      } else {
        idRede = this.rede.id_rede
      }
      this.$store.dispatch('lancarProposta', {
        cnpj_posto: cnpj,
        id_rede: idRede,
        cnpj_empresa: this.empresa.cnpj,
        modelo_faturamento: this.modelo_faturamento,
        periodo: this.periodo_pos,
        id_combustivel: this.id_combustivel === 0 ? null : this.id_combustivel
      }).then(() => {
        this.fechar()
      })
    },
    fechar () {
      this.modelo_faturamento = ''
      this.$emit('fechar')
    },
    pegarCombustiveis () {
      this.$store.dispatch('precoCombustivelPorPosto', this.posto ? this.posto.cnpj : this.rede.matriz.cnpj
      ).then(value => {
        this.combustiveis = value
      })
    }
  },
  directives: { money: VMoney }
}
</script>
