<template>
  <v-card class="mx-auto rounded-lg" max-width="285" v-if="veiculo" hover @click="$emit('visualizar', veiculo)">
    <v-img height="8em" v-if="veiculo.imagem_frontal" :src="veiculo.imagem_frontal"/>
    <v-layout
      v-else
      justify-center
      align-center
      fill-height
      style="
          height: 8em;
      "
      >
        <v-icon color="info" size="8em">{{getIcon(veiculo.tipo_veiculo)}}</v-icon>
    </v-layout>
    <v-card-title primary-title>
      <v-layout wrap style="font-size: 0.75em;">
        <h3 style="text-align: center; width: 100%; height: 1.6em;" v-if="veiculo.modelo && veiculo.marca">{{veiculo.modelo.split(' ')[0]}}/{{veiculo.marca}}</h3>
        <h3 style="text-align: center; width: 100%; height: 1.6em;" v-else>{{ veiculo.modelo_marca }}</h3>
      </v-layout>
    </v-card-title>
    <v-card-actions style="background-color: #2196F3; height: 2em">
      <h3 style="text-align: center; width: 100%; color: white">{{veiculo.tipo_veiculo.denominacao}}</h3>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: ['veiculo'],
  data () {
    return {}
  },
  methods: {
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    }
  }
}
</script>
