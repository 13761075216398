var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"text":""}},[_c('v-toolbar',{staticClass:"elevation-0 secondary--text",staticStyle:{"background":"transparent"},attrs:{"color":"#eee"}},[_c('v-toolbar-title',[_vm._v("Meus Vouchers")]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.vouchers,"headers":_vm.vouchersHeaders,"no-data-text":"Você ainda não possui vouchers"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-xs-left pr-0"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([(props.item.compra.combustivel)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('svg-filler',_vm._g({attrs:{"path":props.item.compra.combustivel ? require(("@/assets/combs/" + (props.item.compra.combustivel.icone) + ".svg")) : require("@/assets/combs/gas.svg"),"width":"40px","height":"40px","fill":props.item.compra.combustivel ? props.item.compra.combustivel.color : ''}},on))]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(props.item.compra.combustivel ? props.item.compra.combustivel.nome : 'Não informado'))])])],1),_c('td',{staticClass:"text-xs-left pr-0"},[_vm._v(" "+_vm._s(props.item.numero)+" ")]),(_vm.$vuetify.breakpoint.xs)?[_c('td',{staticClass:"text-xs-left pl-0"},[(props.item.disponivel)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"blue lighten-2"}},on),[_c('v-icon',[_vm._v("check_circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Ativo")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","color":"grey lighten-2"}},on),[_c('v-icon',[_vm._v("check_circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Usado")])])],1)]:[_c('td',{staticClass:"text-xs-left",on:{"click":function($event){return _vm.selecionarVoucher(props.item)}}},[(props.item.disponivel)?_c('v-btn',{attrs:{"text":"","icon":"","color":"blue lighten-2"}},[_c('v-icon',[_vm._v("check_circle")]),_vm._v(" ATIVO ")],1):_c('v-btn',{attrs:{"text":"","icon":"","disabled":"","color":"grey lighten-2"}},[_c('v-icon',[_vm._v("check_circle")]),_vm._v(" USADO ")],1)],1)],_c('td',{staticClass:"text-xs-left pl-0 pr-0",on:{"click":function($event){return _vm.selecionarVoucher(props.item)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(props.item.valor,{ fractionCount: 2 }))+" ")]),_c('td',{staticClass:"text-xs-left pr-0 pl-0",on:{"click":function($event){props.item.dependencia == null && props.item.disponivel
              ? _vm.compartilharVoucher(props.item)
              : _vm.selecionarVoucher(props.item)}}},[(
              props.item.vouchers_funcionario[0] == null &&
              props.item.disponivel
            )?_c('v-btn',{attrs:{"icon":"","text":"","color":"info"}},[_c('v-icon',[_vm._v("share")])],1):(props.item.vouchers_funcionario && props.item.vouchers_funcionario.length > 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"black","outlined":""}},[_vm._v(_vm._s(props.item.vouchers_funcionario[0].funcionario.pessoa.nome))]):_vm._e()],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.selecionarVoucher(props.item)}}},on),[_c('v-icon',[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar detalhes")])])],1)],2)]}},{key:"headers",fn:function(){return [_c('th',{staticClass:"text-xs-left pr-0"},[_vm._v("Combustível")]),_c('th',{staticClass:"text-xs-left pr-0"},[_vm._v("Código")]),(_vm.$vuetify.breakpoint.xs)?_c('th',{staticClass:"text-xs-left pl-2"},[_vm._v("Ativo")]):_c('th',{staticClass:"text-xs-left"},[_vm._v("Consumido")]),_c('th',{staticClass:"text-xs-left pr-0 pl-0"},[_vm._v("Valor (R$)")]),_c('th',{staticClass:"text-xs-left pl-0 pr-0"})]},proxy:true}])}),_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pages,"circle":""},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }