<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa !== 2)"/>
  <v-container grid-list-md fluid class="pt-5" v-else>
    <v-toolbar color="transparent" class="elevation-0 secondary--text">
      <v-toolbar-title>Veiculos a Venda</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab color="info" @click="showFilter = !showFilter">
            <v-icon>{{ !showFilter ? 'filter_list' : 'filter_list_off' }}</v-icon>
          </v-btn>
        </template>
        <span>{{!showFilter ? 'Mostrar filtros' : 'Recolher filtros'}}</span>
      </v-tooltip>
      <!-- <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small fab color="info" @click="dialogNotificacoes = true" class="ml-2">
            <v-icon>notifications_active</v-icon>
          </v-btn>
        </template>
        <span>Nofiticações de Novos Veículos</span>
      </v-tooltip> -->
    </v-toolbar>
    <v-slide-x-transition>
      <v-card v-show="showFilter" class="ml-4 mr-4">
        <v-card-text>
          <v-layout wrap justify-space-between>
            <v-flex xs10 md11>
              <v-layout wrap>
                <v-flex xs6 sm6 md4>
                  <v-select
                    label="Tipo de Veículos"
                    :items="tipos_veiculo"
                    v-model="tipo_veiculo"
                    item-text="denominacao"
                    item-value="id_tipo_veiculo"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field label="Marca" v-model="marca"></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field label="Modelo" v-model="modelo"></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-select
                    label="Estado"
                    :items="estados"
                    v-model="estado"
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field label="Cidade" v-model="cidade"></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-select
                    label="Ofertante"
                    :items="[
                      { text: 'Usuários', value: 'usuario__pessoa' },
                      { text: 'Empresas', value: 'empresa' }
                    ]"
                    v-model="ofertante"
                  ></v-select>
                </v-flex>
                <v-flex xs6 sm6 md4>
                  <v-text-field
                    v-if="ofertante !== 'empresa'"
                    label="CPF do usuário"
                    v-model="cpf"
                  />
                  <v-text-field
                    v-else
                    label="CNPJ da empresa"
                    v-model="cnpj"
                  />
                </v-flex>
                <v-flex xs6 sm6 md4 v-if="ofertante !== 'empresa'">
                  <v-text-field label="Email do usuário" v-model="email"></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs2 md1 align-self-center>
              <v-btn fab color="info" @click="pegarVeiculos(true)">
                <v-icon>search</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>
    <v-layout row wrap justify-center class="mt-4">
      <v-layout column class="ma-4">
        <v-layout v-if="veiculos && veiculos.length > 0" align-start justify-start row wrap fill-height style="min-height: 18em;">
          <v-flex xs12 sm6 md4 lg4 xl3 v-for="(item, index) in veiculos" :key="index" class="mb-3">
            <card-veiculo :veiculo="item" @visualizar="abrirVisualizar"/>
          </v-flex>
        </v-layout>
        <v-pagination v-model="pagination.page" :length="pages" circle class="mb-4"></v-pagination>
      </v-layout>
    </v-layout>
    <v-dialog v-model="visualizar" v-if="visualizar" scrollable width="650">
      <visualizar-veiculo
        :veiculo="veiculoSelecionado"
        :mostrarBotao="true"
        @fechar="visualizar = false, veiculoSelecionado = undefined"
      />
    </v-dialog>
    <v-dialog v-model="dialogNotificacoes" v-if="dialogNotificacoes" scrollable width="700">
      <notificacoes-veiculos
        :veiculo="veiculoSelecionado"
        @fechar="dialogNotificacoes = false, veiculoSelecionado = undefined"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import CardVeiculo from '@/components/Veiculo'
import VisualizarVeiculo from '@/components/dialogs/VisualizarVeiculo'
import NotificacoesVeiculos from '@/components/dialogs/NotificacoesVeiculos'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { CardVeiculo, VisualizarVeiculo, NotificacoesVeiculos, AvisoBloqueio },
  data () {
    return {
      visualizar: false,
      dialogNotificacoes: false,
      veiculoSelecionado: undefined,
      lagura_tela: 0,
      showFilter: false,
      veiculos: [],
      totalItens: 0,
      pagination: {
        rowsPerPage: 8,
        page: 1
      },
      estado: undefined,
      cidade: undefined,
      email: undefined,
      cpf: undefined,
      cnpj: undefined,
      modelo: undefined,
      marca: undefined,
      tipo_veiculo: undefined,
      tipos_veiculo: [],
      ofertante: 'usuario__pessoa'
    }
  },
  mounted () {
    if (!!this.empresa && !!this.headers) {
      this.pegarVeiculos(false)
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        if (this.empresa) this.pegarVeiculos(false)
      },
      deep: true
    },
    showFilter (val) {
      if (val) this.pegarTipoVeiculo()
    },
    empresa: {
      handler (val, oldval) {
        if (val && val !== oldval) this.pegarVeiculos(false)
      },
      deep: true
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    pages () {
      const count = this.totalItens
      if (this.pagination.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination.rowsPerPage)
    },
    tela () {
      return Number(window.innerWidth)
    },
    estados () {
      return this.$store.getters.estados
    }
  },
  methods: {
    pegarVeiculos (isBtn) {
      const data = {
        aVenda: true,
        cnpj: this.empresa.cnpj,
        naoMostrar: true, // Não permite mostrar os veículos da empresa logada
        destinatario_oferta: 2
      }
      data.page = isBtn ? 1 : this.pagination.page
      data.page_size = this.pagination.rowsPerPage
      if (this.showFilter) {
        if (this.ofertante) data[`${this.ofertante}__isnull`] = false
        if (this.tipo_veiculo) data.tipo_veiculo = this.tipo_veiculo
        if (this.modelo) data.modelo__icontains = this.modelo
        if (this.marca) data.marca__icontains = this.marca
        if (this.cpf && this.ofertante !== 'empresa') data.usuario__pessoa__cpf__iexact = this.cpf
        else if (this.cnpj) data.empresa__cnpj = this.cnpj
        if (this.email) data.usuario__pessoa__email__iexact = this.email
        if (this.cidade) data[`${this.ofertante}__endereco__cidade__iexact`] = this.cidade
        if (this.estado) data[`${this.ofertante}__endereco__estado__iexact`] = this.estado
      }
      this.$store.dispatch('listarVeiculosAVenda', data).then(json => {
        this.veiculos = json.results
        this.totalItens = json.count
      })
    },
    pegarTipoVeiculo () {
      this.$store.dispatch('listarTipoVeiculo').then(json => {
        this.tipos_veiculo = json
      })
    },
    abrirVisualizar (veiculo) {
      this.veiculoSelecionado = veiculo
      this.visualizar = true
    }
  },
  beforeMount () {
    this.lagura_tela = this.tela
    if (this.lagura_tela <= 1904 && this.lagura_tela > 957) {
      this.pagination.rowsPerPage = 6
    } else if (this.lagura_tela <= 957 && this.lagura_tela > 595) {
      this.pagination.rowsPerPage = 4
    } else if (this.lagura_tela <= 595) {
      this.pagination.rowsPerPage = 2
    }
  }
}
</script>
