<template>
  <v-card>
    <v-card-title>
      <h4 style="text-align: center; width: 100%;">Selecione um Colaborador</h4>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 350px;">
      <v-col class="d-flex" cols="12" sm="12">
        <v-autocomplete
            :items="funcionarios.results"
            v-model="funcionarioSelected"
            color="info"
            item-text="nome_email"
            label="Colaboradores"
            filled
            return-object
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          v-model="dataVeiculo"
          :items="veiculos_por_funcionario"
          item-text="modelo_marca"
          label="Veiculo"
          return-object
          color="info"
          filled
          @input="associarMotorista(dataVeiculo)"
        >
        </v-select>
        <v-select class="mt-4" label="Selecione o tipo de combustível"
          clearable
          :items="preco_combustiveis"
          item-text="combustivel.nome"
          item-value="combustivel.id_combustivel"
          item-color="combustivel.color"
          v-model="id_combustivel"
        ></v-select>
      </v-col>
      <!-- <v-chip
        v-if="veiculo"
        class="ma-2"
        close
        color="indigo darken-3"
        outlined
        @click:close="removerMotorista(veiculo)"
      >
      {{veiculo.modelo_marca}}
      </v-chip> -->
    </v-card-text>
    <v-footer color="white">
          <v-btn
            class="mb-4"
            block
            small
            color="primary"
            :disabled="!dataVeiculo || !funcionarioSelected || !id_combustivel"
            @click="selecionarFuncionario"
          >
            Confirmar
          </v-btn>
      </v-footer>
  </v-card>
</template>

<script>
import { funcionariosDao } from '@/api'

export default {
  props: ['visible', 'voucher'],
  data () {
    return {
      funcionarioData: null,
      dependente: null,
      funcionarios: [],
      preco_combustiveis: [],
      funcionarioSelected: null,
      motorista: null,
      dataVeiculo: null,
      exibirVeiculo: true,
      id_combustivel: undefined,
      dataMotorista: {
        cpf: null,
        cnpj: null
      },
      pagination: {
        page: 1,
        rowsPerPage: 1000
      }
    }
  },
  beforeMount () {
    this.carregarFuncionarios()
    this.carregarVeiculos()
    this.restartFields()
    if (this.voucher) {
      this.pegarCombustiveis()
    }
    if (this.voucher && this.voucher.compra && this.voucher.compra.combustivel) {
      this.id_combustivel = this.voucher.compra.combustivel.id_combustivel
    }
  },
  watch: {
    voucher (val) {
      if (val) {
        this.pegarCombustiveis()
      }
    },
    funcionarioSelected (val) {
      this.carregarVeiculoPorFuncionario(val).then(() => {
        this.exibirVeiculo = true
        this.dataMotorista.cnpj = this.funcionario.empresa.cnpj
        this.dataMotorista.cpf = this.funcionario.pessoa.cpf
        this.$store.dispatch('veiculoPorFuncionario', this.dataMotorista)
        // var i = 0
        // while (i < this.veiculos.length) {
        //   // if (this.veiculos[i].motorista !== null && this.veiculos[i].motorista.id_funcionario === val) {
        //   //   this.exibirVeiculo = false
        //   // }
        //   i++
        //   this.dataMotorista.cnpj = this.funcionario.empresa.cnpj
        //   this.dataMotorista.cpf = this.funcionario.pessoa.cpf
        //   this.$store.dispatch('veiculoPorFuncionario', this.dataMotorista)
        // }
      })
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    veiculos () {
      return this.$store.getters.veiculos
    },
    veiculo () {
      return this.$store.getters.veiculo
    },
    veiculos_por_funcionario () {
      return this.$store.getters.veiculos_por_funcionario
    },
    funcionario () {
      return this.$store.getters.funcionario
    },
    empresa () {
      return this.$store.getters.empresa
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    }
  },
  methods: {
    restartFields () {
      this.funcionarioSelected = null
      this.dataVeiculo = null
    },
    pegarCombustiveis () {
      const cnpj = this.voucher.compra.posto ? this.voucher.compra.posto.cnpj : this.voucher.compra.rede.matriz.cnpj
      this.$store.dispatch('precoCombustivelPorPosto', cnpj
      ).then(value => {
        this.preco_combustiveis = value
      })
    },
    carregarFuncionarios () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      funcionariosDao.getFuncionariosEmpresaConfirmados(data, this.headers)
        .then(res => res.json())
        .then(json => {
          this.funcionarios = json
        })
    },
    carregarVeiculos () {
      this.$store.dispatch('carregarVoucherVeiculos', this.empresa.cnpj)
    },
    associarMotorista (dataVeiculo) {
      var data = {
        id_funcionario: this.funcionarioSelected.id_funcionario,
        veiculo: dataVeiculo
      }
      this.$store.dispatch('inserirMotorista', data)
      this.exibirVeiculo = false
    },
    removerMotorista () {
      var data = {
        id_funcionario: this.funcionario.id_funcionario,
        id_veiculo: this.veiculo.id_veiculo
      }
      this.$store.dispatch('removerMotorista', data)
      this.dataVeiculo = null
      this.exibirVeiculo = true
    },
    carregarVeiculoPorFuncionario (funcionario) {
      return new Promise(resolve => {
        this.$store.dispatch('selecionarFuncionario', funcionario)
        this.dataMotorista.cnpj = funcionario.empresa.cnpj
        this.dataMotorista.cpf = funcionario.pessoa.cpf
        // this.$store.dispatch('veiculoPorFuncionario', this.dataMotorista)
        this.dataVeiculo = this.veiculo
        resolve('')
      })
    },
    // Teste localmente antes para ver se ta indo os id diferente dos veidulos
    selecionarFuncionario () {
      this.funcionarioData = {
        funcionario: this.funcionario,
        veiculo: this.dataVeiculo,
        id_combustivel: this.id_combustivel ? this.id_combustivel : null
      }
      if (this.funcionario !== null) {
        this.$emit('funcionarioSelected', this.funcionarioData)
      }
    }
  }
}
</script>
