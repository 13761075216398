<template>
  <v-card>
    <v-layout justify-center  v-if="items.length > 0">
    <v-carousel
      height="20em"
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        style="max-height: 20em"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
  </v-layout>
    <v-layout
      v-else
      justify-center
      align-center
      fill-height
      style="
          height: 20em;
      "
      >
        <v-icon color="info" size="20em">{{getIcon(veiculo.tipo_veiculo)}}</v-icon>
    </v-layout>
    <v-card-text class="mt-2" style="font-size: 1em">
      <v-alert v-if="apta" type="error" class="mt-4 ml-1 mr-1 mb-1 pa-1">
        <v-layout justify-space-around wrap>
          <v-flex xs12>
          <h4>
            Existem informações que estão faltando em seu cadastro
          </h4>
        </v-flex>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              elevation="1"
              color="white"
              class="error--text mr-4 mt-2"
              v-on="on"
              small
              rounded
            >
              <v-icon class="mr-1">info</v-icon>
              Saiba mais
            </v-btn>
          </template>
          <v-card width="25em">
            <v-card-title class="justify-center error--text">
              INFORMAÇÕES NÃO PREENCHIDAS
            </v-card-title>
            <v-card-text>
              <h3 class="mb-2">
                Essas informações são necessárias, pois os clientes
                necessitam delas para contatar a sua empresa.
                Informe-as para poder negociar com eles.
              </h3>
              <ul>
                <li><h4>Um <b>Número de Telefone</b> precisa ser inserido</h4></li>
                <li><h4>O <b>Endereço</b> precisa está preenchido</h4></li>
                <li><h4>A <b>Geo localização</b> precisa está confirmada</h4></li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-btn
                elevation="1"
                color="error"
                @click="$router.push('/editarempresa')"
                block
                small
              >
                <v-icon class="mr-1">edit</v-icon>
                Editar Empresa
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        </v-layout>
      </v-alert>
      <v-alert v-if="tem_solicitacao" type="error" class="mt-4 ml-1 mr-1 mb-1 pa-1">
        <h4>
          Você já enviou uma solicitação para esse cliente
        </h4>
      </v-alert>
      <v-alert v-if="!tem_solicitacao && !apta" :value="true" type="info" class="mt-4 ml-1 mr-1 mb-1 pa-1">
        <h4>
          {{ numero_propostas === 0 ?
            'Seja o primeiro a enviar uma proposta por esse veículo' :
            `${numero_propostas} ${numero_propostas !== 1 ?'pessoas estão interessadas' : 'pessoa está interessada'} nesse veículo`
          }}
        </h4>
      </v-alert>
      <v-stepper
        v-model="step"
        style="box-shadow: none;"
        class="mt-4 pa-0"
      >
        <v-stepper-items>
          <v-stepper-content step="1" class="ma-0 pa-0">
            <v-layout wrap justify-space-between>
              <v-flex xs12 v-if="veiculo_data.valor && mostrarBotao" class="mb-4">
                <h2 class="text-center"><b>{{veiculo_data.valor | currency({ fractionCount: 2 })}}</b></h2>
              </v-flex>
              <template v-if="veiculo_data.modelo && veiculo_data.marca">
                <v-flex xs6 class="mb-2">Modelo: <b>{{veiculo_data.modelo}}</b></v-flex>
                <v-flex xs6 class="mb-2">Marca: <b>{{veiculo_data.marca}}</b></v-flex>
              </template>
              <v-flex xs6 class="mb-2" v-else-if="veiculo_data.modelo_marca">Modelo/Marca:<b>{{veiculo_data.modelo_marca}}</b></v-flex>
              <v-flex xs6 class="mb-2" v-if="veiculo_data.ano_modelo">Ano do modelo: <b>{{veiculo_data.ano_modelo}}</b></v-flex>
              <v-flex xs6 class="mb-2" v-if="veiculo_data.ano_fabricacao">Ano de Fabricação / modelo: <b>{{veiculo_data.ano_fabricacao}}</b></v-flex>
              <v-flex xs6 class="mb-2" v-if="veiculo_data.cor_texto">
                  Cor em Texto: <b>{{veiculo_data.cor_texto}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.cor" class="mb-2">
                  Cor: <v-btn x-small :color="formatarCor(veiculo_data.cor)"></v-btn>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.versao_motor" class="mb-2">
                  Potencia do motor: <b>{{veiculo_data.motor}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.cilindradas && is_moto" class="mb-2">
                  Cilindradas: <b>{{veiculo_data.cilindradas}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.tipo_veiculo" class="mb-2">
                  Tipo veículo: <b>{{veiculo_data.tipo_veiculo.denominacao}}</b>
              </v-flex>
              <template v-if="veiculo_data.motorista && !mostrarBotao">
                <v-flex xs6 v-if="veiculo_data.motorista.pessoa" class="mb-2">
                    Nome Completo: <b>{{veiculo_data.motorista.pessoa.nome}} {{veiculo_data.motorista.pessoa.sobrenome}}</b>
                </v-flex>
              </template>
              <template v-if="veiculo_data.empresa && !mostrarBotao">
                <v-flex xs6 v-if="veiculo_data.empresa.nome_fantasia" class="mb-2">
                    Empresa proprietária: <b>{{veiculo_data.empresa.nome_fantasia}}</b>
                </v-flex>
              </template>
              <v-flex xs6 v-if="veiculo_data.cambio && !is_moto" class="mb-2">
                Cambio: <b>{{cambio[veiculo_data.cambio - 1]}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.direcao && !is_moto" class="mb-2">
                Direção: <b>{{direcao[veiculo_data.direcao - 1]}}</b>
              </v-flex>
              <template v-if="veiculo_data.pessoa">
                <v-flex xs6 v-if="veiculo_data.pessoa.telefone" class="mb-2">
                  Contato: <b>{{veiculo_data.pessoa.telefone.startsWith('+55') ? veiculo_data.pessoa.telefone : `+55${veiculo_data.pessoa.telefone}`}}</b>
                </v-flex>
                <v-flex xs6 v-if="veiculo_data.pessoa.nome_completo" class="mb-2">
                  Cliente: <b>{{veiculo_data.pessoa.nome_completo}}</b>
                </v-flex>
              </template>
              <v-flex xs6 v-if="veiculo_data.valor && !mostrarBotao" class="mb-4">
                Valor: <b>{{veiculo_data.valor | currency({ fractionCount: 2 })}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.valor_estimado" class="mb-4">
                Valor estimado: <b>{{veiculo_data.valor_estimado | currency({ fractionCount: 2 })}}</b>
              </v-flex>
              <v-flex xs6 v-if="veiculo_data.endereco" class="mb-4">
                Endereço: <b>{{veiculo_data.endereco}}</b>
              </v-flex>
              <v-flex xs6 class="mb-4">
                Usado: <b>{{ veiculo_data.usado ? 'SIM' : 'NÃO' }}</b>
              </v-flex>
              <v-flex
                xs6
                v-if="
                  !veiculo_data.usado &&
                  veiculo_data.qtd_estoque
                "
                class="mb-4"
              >
                Quantidade em estoque: <b>{{ veiculo_data.qtd_estoque }}</b>
              </v-flex>
              <v-flex xs12 v-if="veiculo_data.descricao" class="mb-4">
                Descrição: <b>{{veiculo_data.descricao}}</b>
              </v-flex>
              <v-layout wrap justify-space-around style="width:100%;">
                <v-flex xs12>
                  <h3 class="text-center mb-4">RECURSOS</h3>
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.vidro_eletrico || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.vidro_eletrico ? 'success' : 'error'"
                  >
                    {{ veiculo_data.vidro_eletrico ? 'done' : 'close' }}
                  </v-icon>
                  Vidro elétrico
                </v-flex>
                <v-flex xs3 v-if="veiculo_data.air_bag || !mostrarBotao">
                  <v-icon
                    :color="veiculo_data.air_bag ? 'success' : 'error'"
                  >
                    {{ veiculo_data.air_bag ? 'done' : 'close' }}
                  </v-icon>
                  Air bag
                </v-flex>
                <v-flex xs3 v-if="veiculo_data.trava_eletrica || !mostrarBotao">
                  <v-icon
                    :color="veiculo_data.trava_eletrica ? 'success' : 'error'"
                  >
                    {{ veiculo_data.trava_eletrica ? 'done' : 'close' }}
                  </v-icon>
                  Trava elétrica
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.ar || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.ar ? 'success' : 'error'"
                  >
                    {{ veiculo_data.ar ? 'done' : 'close' }}
                  </v-icon>
                  Ar condicionado
                </v-flex>
                <v-flex xs3 v-if="veiculo_data.alarme || !mostrarBotao">
                  <v-icon
                    :color="veiculo_data.alarme ? 'success' : 'error'"
                  >
                    {{ veiculo_data.alarme ? 'done' : 'close' }}
                  </v-icon>
                  Alarme
                </v-flex>
                <v-flex xs3 v-if="veiculo_data.som || !mostrarBotao">
                  <v-icon
                    :color="veiculo_data.som ? 'success' : 'error'"
                  >
                    {{ veiculo_data.som ? 'done' : 'close' }}
                  </v-icon>
                  Som
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.air_bag_passageiro || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.air_bag_passageiro ? 'success' : 'error'"
                  >
                    {{ veiculo_data.air_bag_passageiro ? 'done' : 'close' }}
                  </v-icon>
                  Air bag passageiro
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.volante_regulagem_altura || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.volante_regulagem_altura ? 'success' : 'error'"
                  >
                    {{ veiculo_data.volante_regulagem_altura ? 'done' : 'close' }}
                  </v-icon>
                  Volante para regulagem de altura
                </v-flex>
                <v-flex xs3 v-if="veiculo_data.bluetooth || !mostrarBotao">
                  <v-icon
                    :color="veiculo_data.bluetooth ? 'success' : 'error'"
                  >
                    {{ veiculo_data.bluetooth ? 'done' : 'close' }}
                  </v-icon>
                  Bluetooth
                </v-flex>
                <v-flex
                  xs3
                  v-if="!is_moto && (veiculo_data.vidro_eletrico_traseiro || !mostrarBotao)"
                >
                  <v-icon
                    :color="veiculo_data.vidro_eletrico_traseiro ? 'success' : 'error'"
                  >
                    {{ veiculo_data.vidro_eletrico_traseiro ? 'done' : 'close' }}
                  </v-icon>
                  Vidro elétrico traseiro
                </v-flex>
                <v-flex
                  xs3
                  v-if="!is_moto && (veiculo_data.vidro_eletrico_dianteiro || !mostrarBotao)"
                >
                  <v-icon
                    :color="veiculo_data.vidro_eletrico_dianteiro ? 'success' : 'error'"
                  >
                    {{ veiculo_data.vidro_eletrico_dianteiro ? 'done' : 'close' }}
                  </v-icon>
                  Vidro elétrico dianteiro
                </v-flex>
                <v-flex
                  xs3
                  v-if="veiculo_data.rodas_liga_leve || !mostrarBotao"
                >
                  <v-icon
                    :color="veiculo_data.rodas_liga_leve ? 'success' : 'error'"
                  >
                    {{ veiculo_data.rodas_liga_leve ? 'done' : 'close' }}
                  </v-icon>
                  Rodas de liga leve
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.kit_multimidia || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.kit_multimidia ? 'success' : 'error'"
                  >
                    {{ veiculo_data.kit_multimidia ? 'done' : 'close' }}
                  </v-icon>
                  Kit multimídia
                </v-flex>
                <v-flex
                  xs3
                  v-if="veiculo_data.computador_bordo || !mostrarBotao"
                >
                  <v-icon
                    :color="veiculo_data.computador_bordo ? 'success' : 'error'"
                  >
                    {{ veiculo_data.computador_bordo ? 'done' : 'close' }}
                  </v-icon>
                  Computador de bordo
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.bancos_couro || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.bancos_couro ? 'success' : 'error'"
                  >
                    {{ veiculo_data.bancos_couro ? 'done' : 'close' }}
                  </v-icon>
                  Bancos de couro
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.air_bag_lateral || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.air_bag_lateral ? 'success' : 'error'"
                  >
                    {{ veiculo_data.air_bag_lateral ? 'done' : 'close' }}
                  </v-icon>
                  Air bag lateral
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.retrovisores_eletricos || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.retrovisores_eletricos ? 'success' : 'error'"
                  >
                    {{ veiculo_data.retrovisores_eletricos ? 'done' : 'close' }}
                  </v-icon>
                  Retrovisores elétricos
                </v-flex>
                <v-flex
                  xs3
                  v-if="veiculo_data.freios_abs || !mostrarBotao"
                >
                  <v-icon
                    :color="veiculo_data.freios_abs ? 'success' : 'error'"
                  >
                    {{ veiculo_data.freios_abs ? 'done' : 'close' }}
                  </v-icon>
                  Freios ABS
                </v-flex>
                <v-flex
                  xs3
                  v-if="!is_moto && (veiculo_data.radio || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.radio ? 'success' : 'error'"
                  >
                    {{ veiculo_data.radio ? 'done' : 'close' }}
                  </v-icon>
                  Rádio
                </v-flex>
                <v-flex
                  xs3
                  v-if="veiculo_data.entrada_usb || !mostrarBotao"
                >
                  <v-icon
                    :color="veiculo_data.entrada_usb ? 'success' : 'error'"
                  >
                    {{ veiculo_data.entrada_usb ? 'done' : 'close' }}
                  </v-icon>
                  Entrada USB
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.air_bag_duplo || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.air_bag_duplo ? 'success' : 'error'"
                  >
                    {{ veiculo_data.air_bag_duplo ? 'done' : 'close' }}
                  </v-icon>
                  Air bag duplo
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.porta_copos || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.porta_copos ? 'success' : 'error'"
                  >
                    {{ veiculo_data.porta_copos ? 'done' : 'close' }}
                  </v-icon>
                  Porta copos
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.cd_player || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.cd_player ? 'success' : 'error'"
                  >
                    {{ veiculo_data.cd_player ? 'done' : 'close' }}
                  </v-icon>
                  CD Player
                </v-flex>
                <v-flex
                  xs3
                  v-if="veiculo_data.distribuicao_eletronica_frenagem || !mostrarBotao"
                >
                  <v-icon
                    :color="veiculo_data.distribuicao_eletronica_frenagem ? 'success' : 'error'"
                  >
                    {{ veiculo_data.distribuicao_eletronica_frenagem ? 'done' : 'close' }}
                  </v-icon>
                  Distribuição eletrônica de frenagem
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.desembaçador_traseiro || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.desembaçador_traseiro ? 'success' : 'error'"
                  >
                    {{ veiculo_data.desembaçador_traseiro ? 'done' : 'close' }}
                  </v-icon>
                  Desembaçador traseiro
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.sensor_re || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.sensor_re ? 'success' : 'error'"
                  >
                    {{ veiculo_data.sensor_re ? 'done' : 'close' }}
                  </v-icon>
                  Sensor de ré
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.camera_re || !mostrarBotao)">
                  <v-icon
                    :color="veiculo_data.camera_re ? 'success' : 'error'"
                  >
                    {{ veiculo_data.camera_re ? 'done' : 'close' }}
                  </v-icon>
                  Câmera de ré
                </v-flex>
                <v-flex xs3 v-if="!is_moto && (veiculo_data.kit_gnv || !mostrarBotao)">
                <v-icon
                  :color="veiculo_data.kit_gnv ? 'success' : 'error'"
                >
                  {{ veiculo_data.kit_gnv ? 'done' : 'close' }}
                </v-icon>
                Kit GNV
              </v-flex>
              <v-flex xs3 v-if="!is_moto && (veiculo_data.blindado || !mostrarBotao)">
                <v-icon
                  :color="veiculo_data.blindado ? 'success' : 'error'"
                >
                  {{ veiculo_data.blindado ? 'done' : 'close' }}
                </v-icon>
                Blindado
              </v-flex>
              <v-flex xs3 v-if="is_moto && (veiculo_data.gps || !mostrarBotao)">
                <v-icon
                  :color="veiculo_data.gps ? 'success' : 'error'"
                >
                  {{ veiculo_data.gps ? 'done' : 'close' }}
                </v-icon>
                GPS
              </v-flex>
              <v-flex xs3 v-if="is_moto && (veiculo_data.escapamento_esportivo || !mostrarBotao)">
                <v-icon
                  :color="veiculo_data.escapamento_esportivo ? 'success' : 'error'"
                >
                  {{ veiculo_data.escapamento_esportivo ? 'done' : 'close' }}
                </v-icon>
                Escapamento Esportivo
              </v-flex>
              <v-flex xs3 v-if="is_moto && (veiculo_data.contra_peso_guidon || !mostrarBotao)">
                <v-icon
                  :color="veiculo_data.contra_peso_guidon ? 'success' : 'error'"
                >
                  {{ veiculo_data.contra_peso_guidon ? 'done' : 'close' }}
                </v-icon>
                Contra Peso do Guidon
              </v-flex>
              <v-flex xs3 v-if="is_moto && (veiculo_data.bau || !mostrarBotao)">
                <v-icon
                  :color="veiculo_data.bau ? 'success' : 'error'"
                >
                  {{ veiculo_data.bau ? 'done' : 'close' }}
                </v-icon>
                Baú
              </v-flex>
              <v-flex xs3 v-if="is_moto && (veiculo_data.farois_neblina || !mostrarBotao)">
                <v-icon
                  :color="veiculo_data.farois_neblina ? 'success' : 'error'"
                >
                  {{ veiculo_data.farois_neblina ? 'done' : 'close' }}
                </v-icon>
                Farois Neblina
              </v-flex>
              </v-layout>
              <v-flex xs12 class="mt-4" v-if="!mostrarBotao && opcoesFinanciamento.length > 0">
                <h4 style="width: 100%; text-align: center" class="gray--text">Opções de Financiamento</h4>
                <v-list>
                  <template v-for="(item, index) in opcoesFinanciamento">
                    <v-divider :key="`divider-${index}`" v-if="index > 0"></v-divider>
                    <v-list-item :key="index">
                      <v-list-item-icon>
                        <v-icon class="mt-2" color="info">paid</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          Número de Parcelas: <b>{{item.numeroParcelas}}</b>
                        </v-list-item-title>
                        <v-list-item-subtitle>Juros: <b>{{item.juros}}%</b></v-list-item-subtitle>
                        <v-list-item-subtitle>Entrada: <b>{{item.entrada  | currency({ fractionCount: 2 }) }}</b></v-list-item-subtitle>
                        <v-list-item-subtitle>Financiadora: <b>{{item.financiadora }}</b></v-list-item-subtitle>
                        <template v-for="(item2, index2) in item.campos_dinamicos">
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Texto'">{{ item2.label}}: <b>{{ item2.value }}</b></v-list-item-subtitle>
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Percentual'">{{ item2.label}}: <b>{{ item2.value }}%</b></v-list-item-subtitle>
                          <v-list-item-subtitle :key="index2" v-if="item2.type == 'Valor financeiro'">{{ item2.label}}: <b>{{ item2.value | currency({ fractionCount: 2 }) }}</b></v-list-item-subtitle>
                        </template>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="2" class="ma-0 pa-0">
            <v-layout wrap justify-space-between>
              <v-flex xs6>
                <v-switch
                    v-model="tem_valor_oferecido"
                    label="Deseja estimar um valor para esse veículo ?"
                    color="primary"
                ></v-switch>
              </v-flex>
              <v-flex xs5>
                <v-btn class="mt-4" small rounded color="primary" @click="troca = !troca">
                  <v-icon class="mr-1">
                    {{ troca ? 'close' :'airport_shuttle'}}
                  </v-icon>
                  {{ troca ? 'Não Oferecer troca' : 'Oferecer troca' }}
                </v-btn>
              </v-flex>
              <v-slide-x-transition>
                <v-flex xs12 v-if="tem_valor_oferecido">
                  <h6 v-if="veiculo_data.valor" class="text-center mb-1" style="font-size: 0.8em;">
                    Valor que o usuário espera receber pelo veículo: {{veiculo_data.valor | currency({ fractionCount: 2 })}}
                  </h6>
                  <v-text-field
                    filled
                    label="Valor estimado oferecido"
                    v-model.lazy="valor_oferecido"
                    v-money="money"
                    prefix="R$"
                    class="mb-1"
                    hide-details
                  ></v-text-field>
                  <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                    * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                  </h6>
                </v-flex>
              </v-slide-x-transition>
              <v-layout justify-center v-if="troca" style="width:100;">
                <v-card class="mb-4" elevation="4" style="width: 90%;">
                  <v-card-title class="justify-center">
                    Opções de troca
                  </v-card-title>
                  <v-card-text>
                    <h3>Selecione um veículo para trocar</h3>
                    <v-list style="width: 100%" class="mb-0">
                      <v-list-item v-for="(v, index) in veiculos" :key="index">
                        <v-list-item-icon>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" :color="formatarCor(v.cor)">{{getIcon(v.tipo_veiculo)}}</v-icon>
                            </template>
                            Cor {{v.cor_texto}}
                          </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{v.modelo_marca}}</v-list-item-title>
                          <v-list-item-subtitle>Ano: {{v.ano_fabricacao}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-tooltip bottom v-if="veiculoSelecionado && veiculoSelecionado.id_veiculo === v.id_veiculo">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="veiculoSelecionado = undefined, carregarVeiculos(false)"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                            <span>Remover Veículo</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                color="success"
                                @click="veiculoSelecionado = v, veiculos=[v]"
                              >
                                <v-icon>check</v-icon>
                              </v-btn>
                            </template>
                            <span>Adicionar veículo para troca</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <div class="text-xs-center mb-4 mt-0" style="width: 100%" v-if="!veiculoSelecionado && pages && pages > 1">
                      <v-pagination class="mt-0" v-model="pagination.page" :length="pages" circle></v-pagination>
                    </div>
                    <v-layout wrap justify-space-between class="pa-4" v-show="veiculoSelecionado">
                      <v-flex xs12>
                        <v-switch slot="activator" class="ml-4" v-model="oferecer_retorno" color="info">
                          <template v-slot:label>
                            Oferecer um valor estimado de retorno na troca
                          </template>
                        </v-switch>
                        <template v-if="oferecer_retorno">
                          <v-text-field
                            v-if="oferecer_retorno"
                            outlined
                            label="Valor estimado oferecido para o cliente"
                            v-model.lazy="valor_oferecido_troca"
                            v-money="money"
                            prefix="R$"
                            class="mb-1"
                            hide-details
                          ></v-text-field>
                          <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                            * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                          </h6>
                        </template>
                      </v-flex>
                      <v-flex xs12>
                        <v-switch slot="activator" class="ml-4" v-model="pedir_retorno" color="info">
                          <template v-slot:label>
                              Solicitar um valor estimado de retorno na troca
                          </template>
                        </v-switch>
                        <template v-if="pedir_retorno">
                          <v-text-field
                            outlined
                            label="Valor estimado solicitado por você ao cliente"
                            v-model.lazy="valor_retorno"
                            v-money="money"
                            prefix="R$"
                            class="mb-1"
                            hide-details
                          ></v-text-field>
                          <h6 class="text-center error--text mb-4" style="font-size: 0.8em;">
                            * Esse valor estará sujeito a alteração após o veículo ser avaliado presencialmente
                          </h6>
                        </template>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-layout>
              <v-flex xs12>
                <v-textarea
                  label="Descrição"
                  v-model="descricao"
                  outlined
                  counter
                  maxlength="100"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions>
      <v-layout column>
        <v-btn
          class="mb-2"
          rounded
          block
          @click="$emit('fechar')"
          :loading="loading"
        >
          Fechar
        </v-btn>
        <v-btn
          class="mb-2"
          :disabled="apta || tem_solicitacao"
          rounded
          block
          @click="confirmar"
          color="success"
          v-if="mostrarBotao"
          :loading="loading"
        >
          <v-icon class="mr-1">handshake</v-icon>
          {{ step === 1 ? 'Avançar' : 'Enviar Proposta' }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>

import { VMoney } from 'v-money'

export default {
  directives: { money: VMoney },
  props: ['veiculo', 'mostrarBotao'],
  data: () => ({
    veiculo_data: {},
    items: [],
    numero_propostas: 0,
    tem_solicitacao: undefined,
    step: 1,
    oferecer_retorno: false,
    pedir_retorno: false,
    valor_oferecido: undefined,
    tem_valor_oferecido: false,
    valor_oferecido_troca: undefined,
    valor_retorno: undefined,
    descricao: undefined,
    veiculos: [],
    veiculoSelecionado: undefined,
    opcoesFinanciamento: [],
    totalItens: 0,
    troca: false,
    is_moto: false,
    cambio: ['Manual', 'Automático', 'Automatizado', 'CVT'],
    direcao: ['Mecânica', 'Hidráulica', 'Elétrica', 'Eletro-hidráulica'],
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false
    },
    pagination: {
      page: 1,
      rowsPerPage: 5
    }
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    pages () {
      if (this.pagination.rowsPerPage == null || this.totalItens == null) {
        return 0
      }
      return Math.ceil(this.totalItens / this.pagination.rowsPerPage)
    },
    apta () {
      if (!this.mostrarBotao) return false
      else if (!this.empresa) return true
      else if (!this.empresa.endereco) return true
      else if (!this.empresa.endereco.cep || !this.empresa.endereco.numero) return true
      else if (!this.empresa.contato1 && !this.empresa.contato2 && !this.empresa.contato3) return true
      else if (!this.empresa.latitude || !this.empresa.longitude) return true
      return false
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  watch: {
    pagination: {
      handler (val, oldval) {
        this.carregarVeiculos(false)
      },
      deep: true
    },
    oferecer_retorno (val) {
      if (val) {
        this.pedir_retorno = false
        this.valor_retorno = undefined
      }
    },
    pedir_retorno (val) {
      if (val) {
        this.oferecer_retorno = false
        this.valor_oferecido_troca = undefined
      }
    },
    troca (val) {
      if (!val) {
        this.oferecer_retorno = false
        this.pedir_retorno = false
        this.valor_retorno = undefined
        this.valor_oferecido_troca = undefined
        this.veiculoSelecionado = undefined
        this.carregarVeiculos(false)
      }
    },
    tem_valor_oferecido (val) {
      if (!val) {
        this.valor_oferecido = null
      }
    }
  },
  methods: {
    confirmar () {
      if (this.step === 1) this.step++
      else this.cadastrarSolicitacao()
    },
    pegarFilaDeEspera () {
      this.$store.dispatch('pegarFilaDeEspera', {
        id_veiculo: this.veiculo.id_veiculo
      }).then(json => {
        this.numero_propostas = json.numero_negociacoes
      })
    },
    pegarDetalhes () {
      this.$store.dispatch('detalharVeiculo', this.veiculo.id_veiculo).then(json => {
        this.veiculo_data = json
        if (this.veiculo_data.imagem_frontal) this.items.push({ src: this.veiculo_data.imagem_frontal })
        if (this.veiculo_data.imagem_traseira) this.items.push({ src: this.veiculo_data.imagem_traseira })
        if (this.veiculo_data.imagem_lateral_e) this.items.push({ src: this.veiculo_data.imagem_lateral_e })
        if (this.veiculo_data.imagem_lateral_d) this.items.push({ src: this.veiculo_data.imagem_lateral_d })
        if (this.veiculo_data.imagem_interior) this.items.push({ src: this.veiculo_data.imagem_interior })
        if (json.financiamentos) {
          this.opcoesFinanciamento = json.financiamentos.map((v) => {
            return {
              juros: v.juros,
              numeroParcelas: v.numero_parcelas,
              entrada: v.entrada,
              financiadora: v.financiadora,
              campos_dinamicos: v.campos_dinamicos
            }
          })
        }
      })
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    getIcon (tipo) {
      if (tipo && typeof tipo === 'object') tipo = tipo.denominacao
      if (tipo === 'Passageiro' || tipo === 'Automóvel' || tipo === 'Outros') return 'airport_shuttle'
      else if (tipo === 'Caminhão') return 'local_shipping'
      else if (tipo === 'Caminhão trator') return 'agriculture'
      else if (tipo === 'Caminhonete') return 'agriculture'
      else if (tipo === 'Ciclomotor' || tipo === 'Motoneta' || tipo === 'Motocicleta' || tipo === 'Triciclo') return 'two_wheeler'
      else if (tipo === 'Micro-ônibus' || tipo === 'Ônibus') return 'directions_bus'
      return 'airport_shuttle'
    },
    carregarVeiculos (isBtn) {
      const data = {
        empresa: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination.page
      data.page_size = this.pagination.rowsPerPage
      if (this.tipo_veiculo) data.tipo_veiculo = this.tipo_veiculo
      if (this.modelo) data.modelo__icontains = this.modelo
      if (this.marca) data.marca__icontains = this.marca
      if (this.cor_texto) data.cor_texto__icontains = this.cor_texto
      this.$store.dispatch('listarVeiculosAVenda', data).then(json => {
        this.veiculos = json.results
        this.totalItens = json.count
      })
    },
    pegarSolicitacao () {
      this.$store.dispatch('solicitacaoVeiculo', {
        method: 'GET',
        id_veiculo: this.veiculo.id_veiculo,
        cnpj: this.empresa.cnpj
      }).then(() => {
        this.tem_solicitacao = false
      }).catch(() => {
        this.tem_solicitacao = true
      })
    },
    cadastrarSolicitacao () {
      this.$store.dispatch('solicitacaoVeiculo', {
        method: 'POST',
        id_veiculo: this.veiculo.id_veiculo,
        cnpj_empresa: this.empresa.cnpj,
        valor_oferecido: !this.tem_valor_oferecido || !this.valor_oferecido ? null : Number(this.valor_oferecido.replaceAll('.', '').replace(',', '.')),
        valor_retorno: !this.pedir_retorno || !this.valor_retorno ? null : Number(this.valor_retorno.replaceAll('.', '').replace(',', '.')),
        valor_oferecido_troca: !this.oferecer_retorno || !this.valor_oferecido_troca ? null : Number(this.valor_oferecido_troca.replaceAll('.', '').replace(',', '.')),
        is_empresa: true,
        descricao: this.descricao,
        id_veiculo_troca: this.veiculoSelecionado ? this.veiculoSelecionado.id_veiculo : null
      }).then(() => {
        this.$emit('fechar')
      })
    }
  },
  beforeMount () {
    if (this.veiculo) {
      if (this.veiculo.tipo_veiculo) {
        this.is_moto = typeof this.veiculo.tipo_veiculo === 'object' ? this.veiculo.tipo_veiculo.denominacao === 'Motocicleta' : this.veiculo.tipo_veiculo === 'Motocicleta'
      }
      this.pegarDetalhes()
      this.pegarFilaDeEspera()
    }
    if (this.mostrarBotao) {
      this.carregarVeiculos(false)
      this.pegarSolicitacao()
    }
  }
}
</script>
