import settings from '@/settings'

const insert = (data, headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/insertvoucherempresa/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: headers
  })
}

const vouchersPorCliente = (pagination, headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/porcliente/?page=${pagination.page}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const vouchersPorEmpresa = (headers, payload) => {
  let url = `${settings.apetrusApi}/vouchers/porempresa/?`

  if (payload) {
    const keys = Object.keys(payload)
    for (let i = 0; i < keys.length; i++) {
      url = `${url}${payload[keys[i]] ? `&${keys[i]}=${payload[keys[i]]}` : ''}`
    }
  }
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVoucher = (id, headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/?id=${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVouchersCompartilhados = (pagination, headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/compartilhados/?page=${pagination.page}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const compartilharVoucher = (voucher, funcionario, veiculo, cnpj, idCombustivel, headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/compartilharcomfuncionario/?id_voucher=${voucher.id_voucher}&id_funcionario=${funcionario.id_funcionario}&id_veiculo=${veiculo.id_veiculo}&cnpj=${cnpj}${idCombustivel ? `&id_combustivel=${idCombustivel}` : ''}`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVoucherByNumber = (num, headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/porcodigo/?numero=${num}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const retornarVoucher = (voucher, headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/retornarvoucherempresa/?id_voucher=${voucher.id_voucher}&id_compra=${voucher.id_compra}&proposta=${voucher.proposta}`, {
    method: 'PUT',
    body: JSON.stringify(voucher),
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const getVouchersCompartilhadosFuncionarios = (headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/compartilhadoscomfuncionarios/?page=${1}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

const porCodigoFrota = (headers) => {
  return fetch(`${settings.apetrusApi}/vouchers/porhashempresa/`, {
    method: 'POST',
    mode: 'cors',
    cache: 'default',
    headers: headers
  })
}

export default {
  getVouchersCompartilhadosFuncionarios,
  insert,
  vouchersPorCliente,
  getVoucher,
  getVouchersCompartilhados,
  compartilharVoucher,
  getVoucherByNumber,
  retornarVoucher,
  vouchersPorEmpresa,
  porCodigoFrota
}
