var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Fazer Proposta Para "+_vm._s(_vm.posto ? 'Posto' : 'Rede')+" ")])],1),_c('br'),_c('v-card-text',{staticStyle:{"font-size":"1.0em","text-align":"justify"}},[_vm._v(" Você pode fazer uma proposta diretamente com "+_vm._s(_vm.posto ? 'o posto' : 'a rede')),_c('br'),_vm._v(" para negociar descontos exclusivos. ")]),_c('v-card-text',[_c('v-select',{staticClass:"mt-4",attrs:{"items":[
              {name:'Pagamento antecipado (pré-pago)', value: 'prepago'},
              // {name:'Solicitação de crédito (pós-pago)', value: 'pospago'},
              {name:'Pagamento no posto (presencial)', value: 'presencial'} ],"label":"Selecione forma de pagamento","item-text":"name","item-value":"value"},model:{value:(_vm.modelo_faturamento),callback:function ($$v) {_vm.modelo_faturamento=$$v},expression:"modelo_faturamento"}}),(_vm.modelo_faturamento == 'pospago')?_c('v-select',{staticClass:"mt-4",attrs:{"items":[
              {name:'Semanal', value: 0},
              {name:'Quinzenal', value: 1},
              {name:'Mensal', value: 2} ],"label":"Selecione o perído do faturamento","item-text":"name","item-value":"value"},model:{value:(_vm.periodo_pos),callback:function ($$v) {_vm.periodo_pos=$$v},expression:"periodo_pos"}}):_vm._e(),_c('v-select',{attrs:{"label":"Para","items":[
              { combustivel: { nome: 'Todos os Combustíveis', id_combustivel: 0 } } ].concat( _vm.combustiveis
            ),"item-text":"combustivel.nome","item-value":"combustivel.id_combustivel","outline":"","hide-details":"","single-line":""},model:{value:(_vm.id_combustivel),callback:function ($$v) {_vm.id_combustivel=$$v},expression:"id_combustivel"}})],1),_c('v-card-actions',{attrs:{"align-content-space-between":""}},[_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":_vm.fechar}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.modelo_faturamento || _vm.propostaAtiva,"color":"green darken-1","text":""},on:{"click":_vm.lancarProposta}},[_vm._v("Enviar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }