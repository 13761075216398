<template>
  <v-dialog
    persistent
    v-model="visible"
    width="600"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template v-if="visible">
      <v-card
        v-if="!carregandoDetalhes"
        text
        style="height: 100%"
        fill-height
        color="white"
      >
        <v-img
          :src="require('@/assets/voucherdesign.svg')"
          contain
          position="top center"
          color="white"
          style="height: 100%"
        >
          <v-container fill-height grid-list-md>
            <v-layout
              column
              align-space-around
              justify-space-between
              fill-height
              class="print"
            >
              <v-flex shrink pb-5>
                <v-layout fluid justify-space-between row>
                  <span style="width: 85px"></span>
                  <v-btn
                    fab
                    color="primary"
                    class="hidden-print-only"
                    dark
                    @click="print()"
                  >
                    <v-icon>print</v-icon>
                  </v-btn>
                  <span
                    class="text-xs-center pr-1 headline font-weight-medium grey--text text--darken-3"
                  >
                    Pedido:
                    <h3>N{{ detalhesCompra.id_compra }}</h3>
                  </span>
                </v-layout>
              </v-flex>
              <template>
                <div class="font-weight-light title text-xs-center">
                  Ver Resumo do Pedido
                </div>
              </template>
              <v-flex class="text-xs-center">
                <div v-if="detalhesPosto && !rede">
                  <h3>Posto: {{ detalhesPosto.nome_fantasia }}</h3>
                  <h3 v-if="detalhesPosto.endereco">
                    {{ detalhesPosto.endereco.logradouro }} N°
                    {{ detalhesPosto.endereco.numero }},
                    {{ detalhesPosto.endereco.bairro }}, CEP:
                    {{ detalhesPosto.endereco.cep }}
                  </h3>
                </div>
                <div v-else>
                  <h3>
                    Rede {{rede.nome}}
                  </h3>
                  <v-layout justify-center v-if="!mostrar" style="width: 35%;">
                    <v-btn color="info" small icon @click="mostrar = !mostrar"><v-icon>visibility</v-icon></v-btn>
                  </v-layout>
                  <div v-else>
                    <div v-for="(posto, index) in rede.postos" :key="index">
                      <h3>Posto: {{ posto.nome_fantasia }}</h3>
                      <h3 v-if="posto.endereco">
                        {{ posto.endereco.logradouro }} N°
                        {{ posto.endereco.numero }},
                        {{ posto.endereco.bairro }}, CEP:
                        {{ posto.endereco.cep }}
                      </h3>
                    </div>
                    <v-layout justify-center style="width: 35%;">
                      <v-btn color="info" small icon @click="mostrar = !mostrar"><v-icon>visibility_off</v-icon></v-btn>
                    </v-layout>
                  </div>
                </div>
                <h3>
                  Detalhes:
                  {{ detalhesVoucher.valor | currency({ fractionCount: 2 }) }}
                </h3>
              </v-flex>
              <v-flex class="text-xs-center">
                <h3>
                  Situação:

                  <span v-if="detalhesVoucher.disponivel">ATIVO</span>
                  <template v-else-if="detalhesVoucher.usuario_utilizador">
                    Usado por
                    {{
                      `${detalhesVoucher.usuario_utilizador.nome} ${detalhesVoucher.usuario_utilizador.sobrenome}`
                    }}
                    <v-avatar size="40" color="grey lighten-2">
                      <img
                        v-if="
                          detalhesVoucher.usuario_utilizador.url_imagem_perfil
                        "
                        :src="
                          detalhesVoucher.usuario_utilizador.url_imagem_perfil
                        "
                      />
                      <v-icon v-else>perm_identity</v-icon>
                    </v-avatar>
                  </template>
                  <span v-else>USADO</span>
                </h3>
              </v-flex>
              <v-flex class="centered-input display-1 font-weight-black"
                >Voucher {{ detalhesVoucher.numero }}</v-flex
              >
              <v-responsive class="text-xs-center" ref="qrcode">
                <qrcode-vue
                  v-if="value"
                  :value="value"
                  :size="qrCodeSize"
                  level="H"
                ></qrcode-vue>
                <template v-if="detalhesVoucher.disponivel"> </template>
                <template v-if="!detalhesVoucher.disponivel">
                  <span
                    class="display-1 font-weight-bold red--text text--darken-4"
                  >
                    UTILIZADO EM
                    <br />
                    {{
                      detalhesVoucher.data_inativado | formatDate("DD/MM/YYYY")
                    }}
                  </span>
                </template>
              </v-responsive>
              <v-layout
                row
                wrap
                justify-center
                v-if="!detalhesVoucher.disponivel"
              >
                <v-flex
                  xs10
                  align-self-center
                  class="text-xs-center headline font-weight-medium grey--text text--darken-2"
                  >Chave de acesso do cupom fiscal</v-flex
                >
                <v-flex
                  xs12
                  v-if="chaveFormatada"
                  class="text-xs-center"
                  align-self-end
                >
                  <div class="font-weight-medium">
                    {{ chaveFormatada.slice(0, 27) }}
                    <br />
                    {{ chaveFormatada.slice(27, 54) }}
                  </div>
                  <v-tooltip bottom z-index="1000">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        text
                        icon
                        v-on="on"
                        v-clipboard:copy="detalhesVoucher.chave_cupom"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                      >
                        <v-icon>filter_none</v-icon>
                      </v-btn>
                    </template>
                    <span>Copiar texto para a area de transferência.</span>
                  </v-tooltip>
                </v-flex>
                <v-flex class="text-xs-center" xs12 v-else>
                  <span
                    class="text-xs-center headline font-weight-bold red--text text--darken-4"
                    >CUPOM FISCAL NÃO ENVIADO</span
                  >
                </v-flex>
              </v-layout>
              <v-spacer></v-spacer>
              <!-- picture odômetro and  -->
              <template v-if="!detalhesVoucher.disponivel">
                <v-row justify="center">
                  <v-col cols="7" sm="5">
                    <v-card>
                      <v-img
                        v-if="detalhesVoucher.compra.foto_odometro"
                        v-bind:src="detalhesVoucher.compra.foto_odometro"
                      ></v-img>
                      <v-card-title class="title"> Odômetro </v-card-title>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <v-card>
                      <v-img
                        v-bind:src="
                          detalhesVoucher.compra.foto_bomba_combustivel
                        "
                      >
                      </v-img>
                      <v-card-title class="title">
                        Visor da Bomba
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <div><br /></div>
              <v-flex shrink class="hidden-print-only">
                <v-layout row>
                  <v-btn color="#FBC02D" rounded @click="fechar()">Voltar</v-btn>
                  <v-spacer></v-spacer>
                  <v-tooltip
                    bottom
                    v-if="detalhesVoucher.disponivel"
                    z-index="1000"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="#FBC02D"
                        rounded
                        @click="retornarVoucher(detalhesVoucher)"
                        v-on="on"
                        >Cancelar Voucher</v-btn
                      >
                    </template>
                    Cancela o voucher e retorna o volume de litros para a
                    compra.
                  </v-tooltip>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
      </v-card>
      <v-card v-else-if="carregandoDetalhes" color="primary" dark>
        <v-card-text>
          Aguarde um instante ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { voucherDao } from '@/api'
import QrcodeVue from 'qrcode.vue'

export default {
  props: ['visible', 'voucher'],
  components: { QrcodeVue },
  data () {
    return {
      valid: false,
      mostrar: false,
      today: null,
      detalhesVoucher: {},
      detalhesCompra: {},
      detalhesPosto: {},
      rede: {
        postos: []
      },
      carregandoDetalhes: false,
      qr_minutes_left: null,
      value: null,
      chaveFormatada: []
    }
  },
  watch: {
    voucher (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.detalhar(val)
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    qrCodeSize () {
      return this.$refs.qrcode.innerWidth || 200
    }
  },
  mounted () {
    this.today = new Date().setHours(0, 0, 0, 0)
  },
  methods: {
    print () {
      window.print()
    },
    onCopy: function (e) {
      if (confirm('Gostaria de abrir a pagina de Nota fiscal de Consumidor Eletrônica?')) {
        window.open('https://nfce.set.rn.gov.br/portalDFE/NFCe/ConsultaNFCe.aspx')
      }
    },
    onError: function (e) {
      alert('Failed to copy texts')
    },
    fechar () {
      this.carregandoDetalhes = false
      this.$emit('fechar')
    },
    carregarVouchers () {
      var data = {
        page: 1,
        cnpj: this.empresa.cnpj
      }
      this.$store.dispatch('carregarVouchers', data)
    },
    retornarVoucher (voucher) {
      voucherDao.retornarVoucher(voucher, this.headers).then(response => {
        this.carregarVouchers()
        this.$emit('fechar')
      //  return response.json()
      })
    },
    detalhar (voucher) {
      this.carregandoDetalhes = true
      voucherDao
        .getVoucher(voucher.id_voucher, this.headers)
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.carregandoDetalhes = false
          this.detalhesVoucher = json
          this.detalhesCompra = json.compra
          this.detalhesPosto = json.compra.posto
          this.rede = json.rede
          this.detalhesVoucher.chave_cupom = json.chave_cupom
          this.chaveFormatada = json.chave_cupom ? (json.chave_cupom.slice(0, 4) + '-' + json.chave_cupom.slice(4, 8) + '-' + json.chave_cupom.slice(8, 12) + '-' +
          json.chave_cupom.slice(12, 16) + '-' + json.chave_cupom.slice(16, 20) + '-' + json.chave_cupom.slice(20, 24) + '-' + json.chave_cupom.slice(24, 28) + '-' +
          json.chave_cupom.slice(28, 32) + '-' + json.chave_cupom.slice(32, 36) + '-' + json.chave_cupom.slice(36, 40) + '-' + json.chave_cupom.slice(40, 44)) : null
          if (this.detalhesVoucher.disponivel && this.detalhesCompra.data_validade && new Date(this.detalhesCompra.data_validade.split('-')) >= this.today) {
            this.carregarQrCode()
          } else {
            this.value = null
          }
        })
    },
    carregarQrCode () {
      voucherDao
        .getVoucherByNumber(this.detalhesVoucher.numero, this.headers)
        .then(res => res.json())
        .then(json => {
          this.value = json.hash
          this.qr_minutes_left = json.minutes_left
        })
    }
  }
}
</script>

<style scoped>
  .centered-input {
      text-align: center;
      color: #074D6E !important;
  }
  .resumo-pedido .v-expansion-panel__container {
    background-color: transparent;
  }
</style>
