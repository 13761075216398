<template>
  <v-card class="pa-4">
      <v-card-title class="primary--text">
          <h1 style="width: 100%; text-align: center;">Enviar e-mail para clientes</h1>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step" class="pa-0 ma-0 mt-3" style="box-shadow: none;">
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0 ma-0">
              <v-text-field
                  v-model="email"
                  label="E-mail"
                  filled
                  required
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              />
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0 ma-0">
              <v-text-field
                  v-model="assunto"
                  label="Assunto"
                  filled
                  required
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              />
              <v-textarea
                  v-model="message"
                  label="Mensagem"
                  filled
                  required
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
              />
              <v-text-field
                  v-model="senha"
                  label="Senha"
                  filled
                  required
                  :append-icon="show ? 'visibility' : 'visibility_off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                  hint="Senha do e-mail ou senha de aplicativo"
              />
              <v-layout justify-center>
                <v-btn
                  rounded
                  color="secondary"
                  @click="step = 3"
                >
                  <v-icon>
                    visibility
                  </v-icon>
                  Visualizar Email
                </v-btn>
              </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0 ma-0">
              <div v-html="texto_formatado" class="ma-2"></div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions>
          <v-btn rounded @click="voltar">
            {{
              step === 3 ? 'Voltar' : 'Fechar'
            }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step != 3"
            rounded
            color='primary'
            :disabled="(step === 1 && !email) || (step === 2 && !message && !assunto)"
            @click="btnSalvar"
          >
            {{ step === 2 ? 'Enviar' : 'Salvar' }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>
<script>

export default {
  data: () => ({
    step: 1,
    message: '',
    assunto: '',
    email: '',
    senha: '',
    show: false
  }),
  computed: {
    empresa () {
      return this.$store.getters.empresa
    },
    texto_formatado () {
      return `<!DOCTYPE html>
              <html>
                  <head>
                      <style>
                          .card {
                            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); max-width: 600px; margin: auto; font-family: arial;
                          }
                          .price {
                            color: grey;font-size: 22px;
                          }
                          .card a{
                            text-decoration: none;color: #4169E1;
                          }
                          .card button {
                            text-align: center;cursor: pointer;
                            width: 100%;
                            font-size: 18px;
                            border: none;
                            outline: 0;
                            padding: 12px;
                            color: white;
                            background-color: #0e5781;
                          }
                          .card button:hover {
                            opacity: 0.7;
                          }
                          .titulo {
                            color: #0e5781;
                          }
                          .texto {
                            margin-left: 10px;
                            margin-right: 10px;
                            margin-top: 0.7em;
                            text-align: justify;
                            font-size: 15pt;
                          }
                          .contato {
                            margin-left: 10px;
                            margin-right: 10px;
                            text-align: center;
                            font-size: 10pt;
                          }
                      </style>
                  </head>
                  <body>
                    <div class="card">
                      <h1 style="text-align: center" class="titulo">Olá! Somos a empresa ${this.empresa.nome_fantasia}.</h1>
                      <p class="texto">${this.message}</p>
                      ${
                        this.empresa.endereco ? `<p class="contato">
                          Nos encontre  em ${this.empresa.endereco.cidade}, bairro ${this.empresa.endereco.bairro}, ${this.empresa.endereco.logradouro}, N°${this.empresa.endereco.numero}, CEP:${this.empresa.endereco.cep}
                        </p>` : ''
                      }
                      <p>
                          <a href='https://play.google.com/store/apps/details?id=apetrus.com.br.appcliente&hl=pt_BR&showAllReviews=true'><button>Acessar aplicativo</button></a>
                      </p>
                    </div>
                  </body>
              </html>`
    }
  },
  beforeMount () {
    this.email = this.empresa.email
    if (this.email) {
      this.step = 2
    }
  },
  methods: {
    enviar () {
      const data = {
        empresa: this.empresa.cnpj,
        message: this.texto_formatado,
        subject: this.assunto,
        senha: this.senha
      }
      this.$store.dispatch('enviarEmail', data).then(() => {
        this.voltar()
      })
    },
    alterarEmpresa () {
      const empresa = this.empresa
      this.$store.dispatch('editarEmpresa', {
        cnpj: this.empresa.cnpj,
        email: this.email
      })
        .then(() => {
          this.$store.dispatch('escolherEmpresa', empresa)
          this.step = 2
        })
    },
    btnSalvar () {
      if (this.step === 1) this.alterarEmpresa()
      else this.enviar()
    },
    voltar () {
      switch (this.step) {
        case 1:
          this.$emit('fechar')
          break
        case 2:
          this.$emit('fechar')
          break
        case 3:
          this.step = 2
      }
    }
  }
}
</script>
