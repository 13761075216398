<template>
  <v-card style="width: 100%; box-shadow: none;">
    <v-flex xs12 v-if="endpoint.id">
      <v-text-field
        label="ID"
        outlined
        v-model="id"
        type="number"
      ></v-text-field>
    </v-flex>
    <template v-if="endpoint.method === 'GET'">
      <v-flex xs12 v-for="(parametro, index) in endpoint.table.items" :key="index">
        <v-text-field
          v-if="parametro.tipo == 'Texto'"
          :label="parametro.atributo"
          outlined
          v-model="parametros[parametro.atributo]"
          :disabled="parametros[parametro.atributo] === '87909075000178' || parametros[parametro.atributo] === '98834148000126'"
        ></v-text-field>
        <v-text-field
          v-else-if="parametro.tipo === 'Inteiro'"
          :label="parametro.atributo"
          outlined
          type="number"
          v-model="parametros[parametro.atributo]"
        ></v-text-field>
        <v-radio-group
          v-else-if="parametro.tipo === 'Booleano'"
          :label="parametro.atributo"
          v-model="parametros[parametro.atributo]"
        >
          <v-radio
            label="Não"
            color="info"
            :value="false"
          ></v-radio>
          <v-radio
            class="mt-4"
            label="Sim"
            color="info"
            :value="true"
          ></v-radio>
        </v-radio-group>
        <template
          v-else-if="parametro.tipo === 'Data'"
        >
          <v-text-field
            :label="parametro.atributo"
            outlined
            v-model="parametros[parametro.atributo]"
            v-mask="['##/##/####']"
            placeholder="DD/MM/YYYY"
          ></v-text-field>
        </template>
      </v-flex>
    </template>
    <v-layout wrap justify-space-between>
      <v-flex
        xs12
        sm12
        md5
        v-if="endpoint.method !== 'GET'"
        style="
          position: relative;
        "
      >
        <h3 class="mb-4">Dados de entrada</h3>
          <monaco-editor
            v-model="body"
            language="json"
            height="300px"
            theme="vs-dark"
            :options="{
              automaticLayout: true,
              formatOnType: true,
              formatOnPaste: true
            }"
          />
          <v-tooltip top
            :color="copiando ? 'green' : ''"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                @click="copiarCorpo"
                icon
                color="white"
                v-on="on"
                style="
                position: absolute;
                bottom: 1em;
                right: 1em;
              "
              >
                <v-icon>content_copy</v-icon>
              </v-btn>
            </template>
            <span>{{ copiando ? 'Copiado' : 'Copiar' }}</span>
          </v-tooltip>
      </v-flex>
      <v-flex
        xs12
        sm12
        :md5="endpoint.method !== 'GET'"
        style="
          position: relative;
        "
      >
        <h3 class="mb-4">Dados de saída</h3>
        <monaco-editor
            v-model="retorno"
            language="json"
            height="300px"
            theme="vs-dark"
            :options="{
              automaticLayout: true,
              formatOnType: true,
              formatOnPaste: true
            }"
          />
          <v-tooltip top
            :color="copiando2 ? 'green' : ''"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                @click="copiarRetorno"
                icon
                color="white"
                v-on="on"
                style="
                position: absolute;
                bottom: 1em;
                right: 1em;
              "
              >
                <v-icon>content_copy</v-icon>
              </v-btn>
            </template>
            <span>{{ copiando2 ? 'Copiado' : 'Copiar' }}</span>
          </v-tooltip>
      </v-flex>
      <v-btn
        style="margin-top: 4em;"
        color="primary"
        block
        :loading="loading"
        @click="enviarRequisicao"
      >
        Enviar Requisição
      </v-btn>
    </v-layout>
  </v-card>
</template>

<script>
import MonacoEditor from '@guolao/vue-monaco-editor'
export default {
  props: ['endpoint'],
  components: { MonacoEditor },
  data: () => ({
    body: undefined,
    retorno: {},
    parametros: {},
    url: 'https://teste-external.apetrus.com.br',
    id: undefined,
    copiando: false,
    copiando2: false
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    empresa () {
      return this.$store.getters.empresa
    }
  },
  beforeMount () {
    if (this.endpoint.body && this.endpoint.body.length > 0) {
      this.body = this.prepararCorpo(this.endpoint.body)
    }
    this.retorno = this.prepararSaida(this.endpoint.mensagem_sucesso)
    if (this.endpoint.method === 'GET') {
      if (this.endpoint.api === '/veiculos/') {
        this.parametros.empresa = '87909075000178'
      } else if (this.endpoint.api === '/automacao/') {
        this.parametros.posto = '98834148000126'
      }
    }
  },
  methods: {
    prepararCorpo (body) {
      function isNumero (valor) {
        return !isNaN(parseFloat(valor)) && isFinite(valor)
      }
      function isLista (valor) {
        try {
          const resultado = JSON.parse(valor)
          return Array.isArray(resultado)
        } catch (e) {
          return false
        }
      }
      function isObjetoLiteral (valor) {
        try {
          const resultado = JSON.parse(valor)
          return resultado && typeof resultado === 'object' && Object.keys(resultado).length === 0
        } catch (e) {
          return false
        }
      }
      let jsonData = {}
      body.forEach(item => {
        const [chave, valor] = item.split(': ').map(part => part.trim())
        let novoValor = valor
        if (chave === 'posto' || chave === 'empresa') novoValor = `${valor}`
        else if (valor === 'true' || valor === 'false') novoValor = valor === 'true'
        else if (isNumero(valor)) novoValor = parseFloat(valor)
        else if (isLista(valor)) novoValor = JSON.parse(valor)
        else if (isObjetoLiteral(valor)) novoValor = JSON.parse(valor)
        jsonData[chave] = novoValor
      })
      if (this.endpoint.nome_lista) {
        const newData = { empresa: '87909075000178', veiculos: [] }
        newData[this.endpoint.nome_lista].push(jsonData)
        jsonData = newData
      }
      const jsonString = JSON.stringify(jsonData, null, 2)
      return jsonString
    },
    prepararSaida (retorno) {
      if (retorno) {
        const saida = JSON.stringify(
          retorno,
          null,
          2
        )
        return saida.toString()
      }
      return ''
    },
    prepararRetorno (json) {
      const retorno = JSON.stringify(
        json,
        null,
        2
      )
      return retorno.toString()
    },
    descriptografarToken (token) {
      if (token) {
        var decodedStringAtoB = atob(token)
        return decodedStringAtoB
      }
    },
    enviarRequisicao () {
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.descriptografarToken(this.empresa.token)}`
      })
      this.$store.dispatch('enviarRequisicaoGenerica', {
        method: this.endpoint.method,
        body: this.body ? JSON.parse(this.body) : undefined,
        url: `${this.url}${this.endpoint.api}${this.id ? `${this.id}/` : ''}${this.endpoint.rota || ''}`,
        parametros: this.parametros,
        headers: headers
      }).then(json => {
        this.retorno = this.prepararRetorno(json)
      })
    },
    copiarCorpo () {
      navigator.clipboard.writeText(
        this.body
      )
      this.copiando = true
      const interval = setInterval(() => {
        this.copiando = false
        clearInterval(interval)
      }, 3000)
    },
    copiarRetorno () {
      navigator.clipboard.writeText(
        this.retorno
      )
      this.copiando2 = true
      const interval = setInterval(() => {
        this.copiando2 = false
        clearInterval(interval)
      }, 3000)
    }
  }
}
</script>
