<template>
  <v-card>
    <v-card-title class="justify-center">
      <h2>EDITAR VEÍCULO</h2>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-container flex grid-list-md>
        <v-stepper
          v-model="stepTela"
          style="box-shadow: none;"
        >
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form lazy-validation v-model="valid" ref="formEditarVeiculo">
                <v-layout row wrap fill-height justify-space-between class="ml-3 mr-3">
                  <v-flex xs12 v-if="isVenda && stepTela === 1">
                    <v-stepper v-model="step" style="box-shadow: none;">
                      <v-stepper-items>
                        <v-stepper-content step="1" class="ma-0 pa-0">
                          <v-layout justify-center column>
                            <v-tooltip bottom >
                              <template v-slot:activator="{ on }">
                                  <img
                                    v-show="imagem_frontal && mostrar"
                                    v-on="on"
                                    @click="$refs.imagemFrontalInput.click()"
                                    :src="imagem_frontal"
                                    alt="Imagem Frontal"
                                    class="ml-3 mr-3 mt-2"
                                    style="cursor: pointer;"
                                  />
                              </template>
                              <h4>Clique para inserir uma outra imagem</h4>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-card
                                  v-show="!imagem_frontal && !cropping1"
                                  hover
                                  class="ml-3 mr-3 mt-2"
                                  v-on="on"
                                  @click="$refs.imagemFrontalInput.click()"
                                >
                                  <v-layout justify-center column>
                                    <v-icon size="15em">image</v-icon>
                                  </v-layout>
                                </v-card>
                              </template>
                              <h4>Clique para inserir uma imagem</h4>
                            </v-tooltip>
                            <v-flex xs12 v-show="cropping1">
                              <vue-croppie
                                ref="croppieimagemFrontalRef"
                                :enableExif="true"
                                :enforceBoundary="true"
                                :enableResize="false"
                                :enableOrientation="false"
                                :mouseWheelZoom="true"
                                :showZoomer="false"
                                :boundary="{ width: 700, height: 500 }"
                                :viewport="{ width: 600, height: 450}"
                              ></vue-croppie>
                              <v-btn
                                text
                                @click="recortarImagem"
                                color="grey darken-2"
                                block
                                class="mt-3"
                              >
                                Recortar imagem
                              </v-btn>
                            </v-flex>
                            <input
                              type="file"
                              style="display:none"
                              accept="image/*"
                              ref="imagemFrontalInput"
                              @input="carregarImagem"
                            />
                            <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem Frontal</h3>
                            <v-layout wrap justify-space-between>
                              <v-btn icon color="info" class="ma-3" disabled>
                                <v-icon>arrow_back</v-icon>
                              </v-btn>
                              <v-btn icon color="info" class="ma-3" @click="step++">
                                <v-icon>arrow_forward</v-icon>
                              </v-btn>
                            </v-layout>
                          </v-layout>
                        </v-stepper-content>
                        <v-stepper-content step="2" class="ma-0 pa-0">
                          <v-layout justify-center column>
                            <v-tooltip bottom >
                              <template v-slot:activator="{ on }">
                                <img
                                  v-show="imagem_traseira && mostrar2"
                                  v-on="on"
                                  @click="$refs.imagemTraseiraInput.click()"
                                  :src="imagem_traseira"
                                  alt="Imagem Frontal"
                                  class="ml-3 mr-3 mt-2"
                                  style="cursor: pointer;"
                                />
                              </template>
                              <h4>Clique para inserir uma outra imagem</h4>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-card
                                  v-show="!imagem_traseira && !cropping2"
                                  hover
                                  class="ml-3 mr-3 mt-2"
                                  v-on="on"
                                  @click="$refs.imagemTraseiraInput.click()"
                                >
                                  <v-layout justify-center column>
                                    <v-icon size="15em">image</v-icon>
                                  </v-layout>
                                </v-card>
                              </template>
                              <h4>Clique para inserir uma imagem</h4>
                            </v-tooltip>
                            <v-flex xs12 v-show="cropping2">
                              <vue-croppie
                                ref="croppieimagemTraseiraRef"
                                :enableExif="true"
                                :enforceBoundary="true"
                                :enableResize="false"
                                :enableOrientation="false"
                                :mouseWheelZoom="true"
                                :showZoomer="false"
                                :boundary="{ width: 700, height: 500 }"
                                :viewport="{ width: 600, height: 450}"
                              ></vue-croppie>
                              <v-btn
                                text
                                @click="recortarImagem"
                                color="grey darken-2"
                                block
                                class="mt-3"
                              >
                                Recortar imagem
                              </v-btn>
                            </v-flex>
                            <input
                              type="file"
                              style="display:none"
                              accept="image/*"
                              ref="imagemTraseiraInput"
                              @input="carregarImagem"
                            />
                            <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem Traseira</h3>
                            <v-layout wrap justify-space-between>
                              <v-btn icon color="info" class="ma-3" @click="step--">
                                <v-icon>arrow_back</v-icon>
                              </v-btn>
                              <v-btn icon color="info" class="ma-3" @click="step++">
                                <v-icon>arrow_forward</v-icon>
                              </v-btn>
                            </v-layout>
                          </v-layout>
                        </v-stepper-content>
                        <v-stepper-content step="3" class="ma-0 pa-0">
                          <v-layout justify-center column>
                            <v-tooltip bottom >
                              <template v-slot:activator="{ on }">
                                <img
                                  v-show="imagem_interior && mostrar3"
                                  v-on="on"
                                  @click="$refs.imagemInteriorInput.click()"
                                  :src="imagem_interior"
                                  alt="Imagem Frontal"
                                  class="ml-3 mr-3 mt-2"
                                  style="cursor: pointer;"
                                />
                              </template>
                              <h4>Clique para inserir uma outra imagem</h4>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-card
                                  v-show="!imagem_interior && !cropping3"
                                  hover
                                  class="ml-3 mr-3 mt-2"
                                  v-on="on"
                                  @click="$refs.imagemInteriorInput.click()"
                                >
                                  <v-layout justify-center column>
                                    <v-icon size="15em">image</v-icon>
                                  </v-layout>
                                </v-card>
                              </template>
                              <h4>Clique para inserir uma imagem</h4>
                            </v-tooltip>
                            <v-flex xs12 v-show="cropping3">
                              <vue-croppie
                                ref="croppieimagemInteriorRef"
                                :enableExif="true"
                                :enforceBoundary="true"
                                :enableResize="false"
                                :enableOrientation="false"
                                :mouseWheelZoom="true"
                                :showZoomer="false"
                                :boundary="{ width: 700, height: 500 }"
                                :viewport="{ width: 600, height: 450}"
                              ></vue-croppie>
                              <v-btn
                                text
                                @click="recortarImagem"
                                color="grey darken-2"
                                block
                                class="mt-3"
                              >
                                Recortar imagem
                              </v-btn>
                            </v-flex>
                            <input
                              type="file"
                              style="display:none"
                              accept="image/*"
                              ref="imagemInteriorInput"
                              @input="carregarImagem"
                            />
                            <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem Interna</h3>
                            <v-layout wrap justify-space-between>
                              <v-btn icon color="info" class="ma-3" @click="step--">
                                <v-icon>arrow_back</v-icon>
                              </v-btn>
                              <v-btn icon color="info" class="ma-3" @click="step++">
                                <v-icon>arrow_forward</v-icon>
                              </v-btn>
                            </v-layout>
                          </v-layout>
                        </v-stepper-content>
                        <v-stepper-content step="4" class="ma-0 pa-0">
                          <v-layout justify-center column>
                            <v-tooltip bottom >
                              <template v-slot:activator="{ on }">
                                <img
                                  v-show="imagem_lateral_e && mostrar4"
                                  v-on="on"
                                  @click="$refs.imagemLateralEInput.click()"
                                  :src="imagem_lateral_e"
                                  alt="Imagem Frontal"
                                  class="ml-3 mr-3 mt-2"
                                  style="cursor: pointer;"
                                />
                              </template>
                              <h4>Clique para inserir uma outra imagem</h4>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-card
                                  v-show="!imagem_lateral_e && !cropping4"
                                  hover
                                  class="ml-3 mr-3 mt-2"
                                  v-on="on"
                                  @click="$refs.imagemLateralEInput.click()"
                                >
                                  <v-layout justify-center column>
                                    <v-icon size="15em">image</v-icon>
                                  </v-layout>
                                </v-card>
                              </template>
                              <h4>Clique para inserir uma imagem</h4>
                            </v-tooltip>
                            <v-flex xs12 v-show="cropping4">
                              <vue-croppie
                                ref="croppieimagemLateralERef"
                                :enableExif="true"
                                :enforceBoundary="true"
                                :enableResize="false"
                                :enableOrientation="false"
                                :mouseWheelZoom="true"
                                :showZoomer="false"
                                :boundary="{ width: 700, height: 500 }"
                                :viewport="{ width: 600, height: 450}"
                              ></vue-croppie>
                              <v-btn
                                text
                                @click="recortarImagem"
                                color="grey darken-2"
                                block
                                class="mt-3"
                              >
                                Recortar imagem
                              </v-btn>
                            </v-flex>
                            <input
                              type="file"
                              style="display:none"
                              accept="image/*"
                              ref="imagemLateralEInput"
                              @input="carregarImagem"
                            />
                            <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem da Lateral Esquerda</h3>
                            <v-layout wrap justify-space-between>
                              <v-btn icon color="info" class="ma-3" @click="step--">
                                <v-icon>arrow_back</v-icon>
                              </v-btn>
                              <v-btn icon color="info" class="ma-3" @click="step++">
                                <v-icon>arrow_forward</v-icon>
                              </v-btn>
                            </v-layout>
                          </v-layout>
                        </v-stepper-content>
                        <v-stepper-content step="5" class="ma-0 pa-0">
                          <v-layout justify-center column>
                            <v-tooltip bottom >
                              <template v-slot:activator="{ on }">
                                <img
                                  v-show="imagem_lateral_d && mostrar5"
                                  v-on="on"
                                  @click="$refs.imagemLateralDInput.click()"
                                  :src="imagem_lateral_d"
                                  alt="Imagem Frontal"
                                  class="ml-3 mr-3 mt-2"
                                  style="cursor: pointer;"
                                />
                              </template>
                              <h4>Clique para inserir uma outra imagem</h4>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-card
                                  v-show="!imagem_lateral_d && !cropping5"
                                  hover
                                  class="ml-3 mr-3 mt-2"
                                  v-on="on"
                                  @click="$refs.imagemLateralDInput.click()"
                                >
                                  <v-layout justify-center column>
                                    <v-icon size="15em">image</v-icon>
                                  </v-layout>
                                </v-card>
                              </template>
                              <h4>Clique para inserir uma imagem</h4>
                            </v-tooltip>
                            <v-flex xs12 v-show="cropping5">
                              <vue-croppie
                                ref="croppieimagemLateralDRef"
                                :enableExif="true"
                                :enforceBoundary="true"
                                :enableResize="false"
                                :enableOrientation="false"
                                :mouseWheelZoom="true"
                                :showZoomer="false"
                                :boundary="{ width: 700, height: 500 }"
                                :viewport="{ width: 600, height: 450}"
                              ></vue-croppie>
                              <v-btn
                                text
                                @click="recortarImagem"
                                color="grey darken-2"
                                block
                                class="mt-3"
                              >
                                Recortar imagem
                              </v-btn>
                            </v-flex>
                            <input
                              type="file"
                              style="display:none"
                              accept="image/*"
                              ref="imagemLateralDInput"
                              @input="carregarImagem"
                            />
                            <h3 style="width: 100%; text-align: center;" class="mt-3">Imagem da Lateral Direita</h3>
                            <v-layout wrap justify-space-between>
                              <v-btn icon color="info" class="ma-3" @click="step--">
                                <v-icon>arrow_back</v-icon>
                              </v-btn>
                              <v-btn icon color="info" class="ma-3" disabled>
                                <v-icon>arrow_forward</v-icon>
                              </v-btn>
                            </v-layout>
                          </v-layout>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-flex>
                  <v-flex
                    xs12
                    v-if="isVenda"
                    style="justify-content: end; display: flex;"
                  >
                    <v-switch
                      slot="activator"
                      class="ml-4"
                      v-model="dadosVeiculo.usado"
                      label="Usado"
                      color="info"
                      inset
                    />
                  </v-flex>
                  <v-flex xs6 v-if="!isVenda || dadosVeiculo.usado">
                    <v-text-field
                      outlined
                      hint="Digite no formato ABC-1234 ou ABC-1D23"
                      label="Placa"
                      v-mask="['AAA-#X##']"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório',
                        (v) => !!v && v.length == 8 || 'Placa Inválida'
                      ]"
                      v-model="dadosVeiculo.identificacao"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      outlined
                      v-model="dadosVeiculo.modelo"
                      label="Modelo"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                      ]"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6 v-if="isVenda">
                    <v-autocomplete
                      outlined
                      v-model="dadosVeiculo.marca"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                      ]"
                      label="Marca"
                      :items="[
                        'Agrale',
                        'Aston Martin',
                        'Audi',
                        'Avelloz',
                        'Bentley',
                        'BMW',
                        'BMW Motorrad',
                        'BYD',
                        'Chery',
                        'Chevrolet',
                        'Chrysler',
                        'Citroen',
                        'Dafra',
                        'Dodge',
                        'Ducati',
                        'Effa',
                        'Exeed',
                        'Ferrari',
                        'Fiat',
                        'Ford',
                        'Foton',
                        'GM',
                        'Haojue',
                        'Harley Davidson',
                        'Honda',
                        'Husqvarna',
                        'Hyundai',
                        'Iveco',
                        'JAC',
                        'Jaguar',
                        'Jeep',
                        'Kasinski',
                        'KTM',
                        'Kawasaki',
                        'Kia',
                        'Kymco',
                        'Lamborghini',
                        'Land Rover',
                        'Lexus',
                        'Lifan',
                        'Maserati',
                        'McLaren',
                        'Mercedes-AMG',
                        'Mercedes-Benz',
                        'Mini',
                        'Mitsubishi',
                        'MXF',
                        'Nissan',
                        'Outra',
                        'Peugeot',
                        'Piaggio',
                        'Porsche',
                        'Pro Tork',
                        'RAM',
                        'Renault',
                        'Rolls-Royce',
                        'Royal Enfield',
                        'Shineray',
                        'Smart',
                        'Sousa Motos',
                        'Subaru',
                        'Sundown',
                        'Suzuki',
                        'Toyota',
                        'Triumph',
                        'Troller',
                        'Volkswagen',
                        'Volvo',
                        'Voltz Motors',
                        'Yamaha'
                      ]"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs6 v-else>
                    <v-text-field
                      outlined
                      v-model="dadosVeiculo.marca"
                      label="Marca"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                      ]"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      outlined
                      v-model="dadosVeiculo.tipo_veiculo_object"
                      :items="tipos_veiculo"
                      label="Tipo do veículo"
                      item-text="denominacao"
                      item-value="id_tipo_veiculo"
                      no-data-text="Desculpe, não foi possível carregar os tipos de veiculo"
                      return-object
                    ></v-select>
                  </v-flex>
                  <v-slide-x-transition>
                    <v-flex xs12 v-if="isVenda && dadosVeiculo.usado">
                      <v-text-field
                        label="Quilometragem"
                        outlined
                        v-model="dadosVeiculo.quilometragem"
                        type="number"
                        :rules="[
                          (v) => !!v || 'Esse campo é obrigatório',
                          (v) => v >= 0 || 'O valor não pode ser menor que zero'
                        ]"
                      />
                    </v-flex>
                  </v-slide-x-transition>
                  <v-flex xs12 v-if="isVenda && !dadosVeiculo.usado">
                    <v-text-field
                      label="Quantidade em estoque"
                      outlined
                      v-model="dadosVeiculo.qtd_estoque"
                      type="number"
                      :rules="[
                        (v) => !!v || 'Esse campo é obrigatório',
                        (v) => v >= 0 || 'O valor não pode ser menor que zero'
                      ]"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      outlined
                      v-model="dadosVeiculo.cor_texto"
                      label="Cor"
                      :items="[
                        'Branca',
                        'Preta',
                        'Prata',
                        'Cinza',
                        'Azul',
                        'Vermelha',
                        'Verde',
                        'Amarela',
                        'Marrom',
                        'Bege',
                        'Rosa'
                      ]"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    v-if="isVenda"
                  >
                    <v-autocomplete
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                      outlined
                      v-model="dadosVeiculo.motor"
                      label="Potência do motor"
                      :items="[
                        '1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9',
                        '2.0', '2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7', '2.8', '2.9',
                        '3.0', '3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9',
                        '4.0', '4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7', '4.8', '4.9',
                        '5.0', '5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7', '5.8', '5.9',
                        '6.0', '6.1', '6.2', '6.3', '6.4', '6.5', '6.6', '6.7', '6.8', '6.9',
                        '7.0', '7.1', '7.2', '7.3', '7.4', '7.5', '7.6', '7.7', '7.8', '7.9',
                        '8.0', '8.1', '8.2', '8.3', '8.4', '8.5', '8.6', '8.7', '8.8', '8.9',
                        '9.0', '9.1', '9.2', '9.3', '9.4', '9.5', '9.6', '9.7', '9.8', '9.9',
                        '10.0', '10.1', '10.2', '10.3', '10.4', '10.5', '10.6', '10.7', '10.8', '10.9',
                        '11.0', '11.1', '11.2', '11.3', '11.4', '11.5', '11.6', '11.7', '11.8', '11.9',
                        '12.0', '12.1', '12.2', '12.3', '12.4', '12.5', '12.6', '12.7', '12.8', '12.9',
                        '13.0', '13.1', '13.2', '13.3', '13.4', '13.5', '13.6', '13.7', '13.8', '13.9',
                        '14.0', '14.1', '14.2', '14.3', '14.4', '14.5', '14.6', '14.7', '14.8', '14.9',
                        '15.0', '15.1', '15.2', '15.3', '15.4', '15.5', '15.6', '15.7', '15.8', '15.9'
                      ]"
                    />
                    <v-autocomplete
                      v-else
                      outlined
                      v-model="dadosVeiculo.cilindradas"
                      label="Cilindradas"
                      :items="[
                        '50', '100', '110', '125', '150', '160', '190', '200', '250', '300', '350', '400',
                        '420', '450', '500', '550', '600', '650', '700', '750', '800', '850', '900', '1000'
                      ]"
                    />
                  </v-flex>
                  <v-flex xs12
                    v-if="
                        isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                    "
                  >
                    <v-autocomplete
                      outlined
                      v-model="dadosVeiculo.qtd_portas"
                      label="Quantidade de Portas"
                      :items="[
                        1, 2, 3, 4, 5, 6
                      ]"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex :xs="tipo_veiculo !== 'motocicleta' ? 9 : 12">
                    <v-combobox
                      outlined
                      label="Combustivel"
                      :items="combustiveis"
                      item-text="nome"
                      :rules="[(v) => !!v.length || 'Esse campo é obrigatório']"
                      required
                      item-value="id_combustivel"
                      hide-no-data
                      :menu-props="{maxHeight: 120}"
                      hide-details
                      multiple
                      class="pb-1"
                      return-object
                      v-model="dadosVeiculo.combustiveis"
                    >
                      <template v-slot:selection="data">
                        <svg-filler :path="require(`@/assets/combs/${data.item.icone}.svg`)" width="35px" height="35px" :fill="data.item.color"/>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs3
                      v-if="
                        isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                      ">
                      <v-switch
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.kit_gnv"
                        label="Kit GNV"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      class="mt-4"
                      xs6
                      v-if="
                        isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                      "
                    >
                      <v-select
                        label="Tipo de Câmbio"
                        outlined
                        v-model="dadosVeiculo.cambio"
                        :items="[
                          { text: 'Manual', value: 1 },
                          { text: 'Automático', value: 2 },
                          { text: 'Automatizado', value: 3 },
                          { text: 'CVT', value: 4 }
                        ]"
                      ></v-select>
                    </v-flex>
                    <v-flex
                      xs6
                      class="mt-4"
                      v-if="isVenda &&
                        (
                          tipo_veiculo !== 'motocicleta'
                        )
                      "
                    >
                      <v-select
                        label="Tipo de Direção"
                        v-model="dadosVeiculo.direcao"
                        outlined
                        :items="[
                          { text: 'Mecânica', value: 1 },
                          { text: 'Hidráulica', value: 2 },
                          { text: 'Elétrica', value: 3 },
                          { text: 'Eletro-hidráulica', value: 4 }
                        ]"
                      ></v-select>
                    </v-flex>
                    <v-flex xs6 v-if="isVenda">
                      <v-text-field
                        outlined
                        v-model="dadosVeiculo.ano_fabricacao"
                        label="Ano de Fabricação / modelo"
                        placeholder="0000/0000"
                        v-mask="['####/####']"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 v-if="isVenda">
                      <v-text-field
                        v-money="money"
                        outlined
                        v-model.lazy="dadosVeiculo.valor"
                        label="Valor do Veículo"
                        prefix="R$"
                        v-if="!salvando"
                      />
                      <v-text-field
                        v-model="dadosVeiculo.valor"
                        label="Valor do Veículo"
                        prefix="R$"
                        outlined
                        v-else
                      />
                    </v-flex>
                  <v-layout
                    wrap
                    justify-space-between
                    v-if="isVenda"
                  >
                    <v-flex xs12>
                      <h2>Opcionais</h2>
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.vidro_eletrico"
                        label="Vidro elétrico"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.trava_eletrica"
                        label="Trava elétrica"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                    xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.ar"
                        label="Ar condicionado"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.alarme"
                        label="Alarme"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.som"
                        label="Som"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.volante_regulagem_altura"
                        label="Volante com regulagem de altura"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.bluetooth"
                        label="Bluetooth"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.vidro_eletrico_traseiro"
                        label="Vidro elétrico traseiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.vidro_eletrico_dianteiro"
                        label="Vidro elétrico dianteiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.rodas_liga_leve"
                        label="Rodas de liga leve"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.kit_multimidia"
                        label="Kit multimídia"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.computador_bordo"
                        label="Computador de bordo"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.bancos_couro"
                        label="Bancos de couro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.retrovisores_eletricos"
                        label="Retrovisor elétrico"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag"
                        :label="
                          tipo_veiculo !== 'motocicleta' ?
                          'Air bag do motorista' :
                          'Air bag'
                        "
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag_passageiro"
                        label="Air bag passageiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag_lateral"
                        label="Air bag lateral"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.air_bag_duplo"
                        label="Air bag duplo"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.freios_abs"
                        label="Freios ABS"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.radio"
                        label="Rádio"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4>
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.entrada_usb"
                        label="Entrada USB"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.porta_copos"
                        label="Porta copos"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.cd_player"
                        label="CD Player"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.distribuicao_eletronica_frenagem"
                        label="Distribuição eletrônica de frenagem"
                        color="info"
                      />
                    </v-flex>
                    <v-flex xs4 v-if="
                        tipo_veiculo !== 'motocicleta'
                      ">
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.desembaçador_traseiro"
                        label="Desembaçador traseiro"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.sensor_re"
                        label="Sensor de ré"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.camera_re"
                        label="Câmera de ré"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo !== 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.blindado"
                        label="Blindado"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.gps"
                        label="GPS"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.escapamento_esportivo"
                        label="Escapamento Esportivo"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.contra_peso_guidon"
                        label="Contra Peso Guidon" color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.bau"
                        label="Baú"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs4
                      v-if="
                        tipo_veiculo === 'motocicleta'
                      "
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.farois_neblina"
                        label="Faróis de Neblina"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      v-if="dadosVeiculo.usado"
                    >
                      <h2>Estado financeiro</h2>
                    </v-flex>
                    <v-radio-group
                      v-model="dadosVeiculo.quitando"
                      v-if="dadosVeiculo.usado"
                    >
                      <v-radio
                        label="Quitado"
                        color="info"
                        :value="false"
                      ></v-radio>
                      <v-radio
                        class="mt-4"
                        label="Financiado"
                        color="info"
                        :value="true"
                      ></v-radio>
                    </v-radio-group>
                    <v-flex
                      xs12
                      class="mt-4"
                      v-if="dadosVeiculo.usado"
                    >
                      <h2>Documentação e regularização</h2>
                    </v-flex>
                    <v-flex
                      xs12
                      style="flex-direction: column;
                      display: flex;"
                      v-if="dadosVeiculo.usado"
                    >
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.ipva_pago"
                        label="IPVA Pago"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.com_multa"
                        label="Com Multa"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.de_leilao"
                        label="De Leilão"
                        color="info"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      class="mb-4"
                    >
                      <h2>Conservação e garantia</h2>
                    </v-flex>
                    <v-flex
                      xs12
                      style="flex-direction: column; display: flex;"
                    >
                      <v-checkbox
                        v-if="dadosVeiculo.usado"
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.unico_dono"
                        label="Único Dono"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.com_manual"
                        label="Com Manual"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.com_garantia"
                        label="Com Garantia"
                        color="info"
                      />
                      <v-checkbox
                        v-if="dadosVeiculo.usado"
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.revisoes_feitas"
                        label="Revisões feitas em concessionária"
                        color="info"
                      />
                      <v-checkbox
                        slot="activator"
                        class="d-inline-block ml-4"
                        v-model="dadosVeiculo.chave_reserva"
                        label="Chave Reserva"
                        color="info"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout column>
                  <v-flex xs12 class="text-xs-center bold subheading gray--text font-weight-regular" v-if="dadosVeiculo.usuario && !isVenda">
                    Veiculo pertence há: {{dadosVeiculo.usuario.nome}} {{dadosVeiculo.usuario.sobrenome}}
                  </v-flex>
                    <v-flex xs8 v-if="!isVenda">
                        <v-select v-if="!dadosVeiculo.usuario"
                          v-model="select"
                          outlined
                          :items="options_cliente"
                          label="A quem pertence esse veículo"
                          :rules="[(v) => !!v || 'Esse campo é obrigatório']"
                          required
                        >
                        </v-select>
                    </v-flex>
                    <v-flex xs8 class="text-xs-center subheading gray--text font-weight-regular" v-if="select == 'Sim' && !isVenda">
                        Selecione o funcinário dono do veículo
                    </v-flex>
                      <v-autocomplete  v-if="select == 'Do funcionário' && !isVenda"
                        :items="funcionarios"
                        v-model="funcionarioSelected"
                        color="primary"
                        item-text="nome_email"
                        label="Colaboradores"
                        return-object
                      ></v-autocomplete>
                    </v-layout>
                </v-layout>
              </v-form>
            </v-stepper-content>
          <v-stepper-content step="2">
            <v-container flex grid-list-md>
              <v-layout justify-center wrap>
                <v-bottom-navigation
                  active
                  v-model="stepOfertar"
                  color="info"
                  grow
                  class="ma-1 mb-4"
                  style="box-shadow: none;"
                >
                  <v-btn style="font-size: 1em;" class="mr-4" x-large>
                    <v-icon size="30">person</v-icon>
                    Usuários do Aplicativo
                  </v-btn>
                  <v-btn style="font-size: 1em;" class="ml-4" x-large>
                    <v-icon size="30">business</v-icon>
                    Revendedoras de Veículo
                  </v-btn>
                </v-bottom-navigation>
                <v-flex xs12>
                  <v-stepper v-model="stepOfertar">
                    <v-stepper-items>
                      <v-stepper-content step="0">
                        <h3 class="text-center">OFERTAR PARA PESSOAS FÍSICAS</h3>
                      </v-stepper-content>
                      <v-stepper-content step="1">
                        <h2 class="text-center" style="margin-bottom: 2em;">SELECIONE UMA OPÇÃO</h2>
                        <v-layout class="mt-4 mb-4" justify-space-around style="width: 80%;">
                          <v-flex xs3>
                            <v-btn
                              outlined
                              block
                              :text="btnAtivo !== 1"
                              color="info"
                              @click="btnAtivo = 1"
                              :class=" btnAtivo == 1 ?'white--text' : ''"
                            >
                              Todas as empresas
                            </v-btn>
                          </v-flex>
                          <v-flex xs3>
                            <v-btn
                              outlined
                              block
                              :text="btnAtivo !== 2"
                              color="info"
                              @click="btnAtivo = 2"
                              :class=" btnAtivo == 2 ?'white--text' : ''"
                            >
                              Empresas selecionadas
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-slide-x-transition>
                          <v-layout column v-if="btnAtivo == 2">
                            <v-layout justify-end class="mr-2 mb-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    fab
                                    small
                                    color="info"
                                    class="white--text"
                                    @click="filtroAtivo = !filtroAtivo"
                                  >
                                    <v-icon>{{ filtroAtivo ? 'filter_list_off' : 'filter_list' }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{ filtroAtivo ? 'Esconder filtro' : 'Mostrar filtro' }}
                                </span>
                              </v-tooltip>
                            </v-layout>
                            <v-slide-x-transition>
                              <v-layout justify-center v-show="filtroAtivo">
                                <v-flex xs4>
                                  <v-text-field
                                    outlined
                                    label="Nome"
                                    color="info"
                                    v-model="nome_fantasia"
                                  />
                                </v-flex>
                                <v-flex xs3>
                                  <v-text-field
                                    outlined
                                    label="Cidade"
                                    color="info"
                                    v-model="cidade"
                                  />
                                </v-flex>
                                <v-flex xs3>
                                  <v-select
                                    label="Estado"
                                    color="info"
                                    :items="estados"
                                    v-model="estado"
                                    clearable
                                    outlined
                                  />
                                </v-flex>
                                <v-flex xs1>
                                  <v-btn
                                    fab
                                    color="info"
                                    small
                                    class="white--text"
                                    @click="pegarRevendedoras(true)"
                                  >
                                    <v-icon>search</v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-slide-x-transition>
                            <v-list>
                              <v-list-item
                                v-for="(empresa, index) in empresas"
                                :key="index"
                                @click="function () {
                                  const temp = isSelected(empresa)
                                  if (temp) removerEmpresa(empresa)
                                  else adicionarEmpresa(empresa)
                                }"
                                class="ma-1"
                                :style="isSelected(empresa) ? {'border': '1px solid #2196f3'} : {}"
                              >
                                <v-list-item-avatar>
                                  <v-icon color="info">business</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ empresa.nome_fantasia }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ empresa.cnpj }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-list-item-subtitle>
                                    <v-icon color="info">map</v-icon>
                                    <span v-if="empresa.endereco" class="ml-1">{{ empresa.endereco.cidade }}/{{ empresa.endereco.estado }}</span>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <div class="text-xs-center">
                              <v-pagination
                                v-model="pagination_empresas.page"
                                :length="pages"
                                circle
                                color="info"
                              ></v-pagination>
                            </div>
                          </v-layout>
                        </v-slide-x-transition>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                </v-flex>
                <v-flex xs12 class="mt-4">
                  <v-textarea
                    outlined
                    label="Descrição"
                    color="info"
                    hint="Informe aqui o estado do veículo e outras informações relevantes"
                    placeholder="Informe aqui o estado do veículo e outras informações relevantes"
                    v-model="dadosVeiculo.descricao"
                    counter
                    maxlength="300"
                  >
                  </v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-card-text>
    <v-card-actions>
        <v-btn
          text
          color="info"
          class="ml-3"
          @click="function () {
            if (stepTela == 2 && isVenda) stepTela--
            else fechar()
          }"
          x-large
        >
          {{ stepTela == 1 ? 'Fechar' : 'Voltar' }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="info"
          class="mr-3"
          @click="function () {
            if (stepTela == 1 && isVenda && $refs.formEditarVeiculo.validate()) stepTela++
            else if ($refs.formEditarVeiculo.validate()) alterarVeiculo()
          }"
          :loading="salvando"
          x-large
        >
          Confirmar
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { veiculoDao, funcionariosDao } from '@/api'
import { VMoney } from 'v-money'

export default {
  props: ['veiculo', 'isVenda'],
  directives: { money: VMoney },
  data () {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      step: 1,
      stepTela: 1,
      stepOfertar: 0,
      btnAtivo: 1,
      salvando: false,
      color: null,
      identificacao: '',
      funcionarioSelected: '',
      valid: false,
      validFinanciamento: false,
      select: null,
      cropping1: false,
      cropping2: false,
      cropping3: false,
      cropping4: false,
      cropping5: false,
      mostrar: true,
      mostrar2: true,
      mostrar3: true,
      mostrar4: true,
      mostrar5: true,
      imagem_frontal: undefined,
      imagem_traseira: undefined,
      imagem_lateral_e: undefined,
      imagem_lateral_d: undefined,
      imagem_interior: undefined,
      temError: false,
      mensagemError: '',
      veiculo_data: { combustiveis: [] },
      dadosVeiculo: {
        usado: true,
        quilometragem: 0,
        ano_fabricacao: undefined,
        valor: '0,00',
        vidro_eletrico: false,
        alarme: false,
        air_bag: false,
        som: false,
        trava_eletrica: false,
        sensor_re: false,
        ar: false,
        camera_re: false,
        air_bag_passageiro: false,
        volante_regulagem_altura: false,
        bluetooth: false,
        vidro_eletrico_traseiro: false,
        vidro_eletrico_dianteiro: false,
        rodas_liga_leve: false,
        kit_multimidia: false,
        computador_bordo: false,
        bancos_couro: false,
        air_bag_lateral: false,
        retrovisores_eletricos: false,
        freios_abs: false,
        radio: false,
        entrada_usb: false,
        air_bag_duplo: false,
        porta_copos: false,
        cd_player: false,
        distribuicao_eletronica_frenagem: false,
        desembaçador_traseiro: false,
        kit_gnv: false,
        blindado: false,
        gps: false,
        escapamento_esportivo: false,
        contra_peso_guidon: false,
        bau: false,
        farois_neblina: false,
        quitando: false,
        ipva_pago: false,
        com_multa: false,
        de_leilao: false,
        unico_dono: false,
        com_manual: false,
        com_garantia: false,
        revisoes_feitas: false,
        chave_reserva: false,
        qtd_estoque: null,
        cambio: 1,
        direcao: 1,
        combustiveis: [],
        id_tipo_veiculo: null,
        tipo_veiculo_object: null,
        identificacao: null,
        modelo_marca: null,
        cor: null,
        usuario: null,
        cor_texto: ''
      },
      tipos_veiculo: [
      ],
      options_cliente: [
        'Do funcionário',
        'Da empresa'
      ],
      pagination: {
        page: 1,
        rowsPerPage: 1000
      },
      funcionarios: [],
      items: [],
      empresas: [],
      empresas_selecionadas: [],
      pagination_empresas: {
        page: 1,
        rowsPerPage: 5
      },
      totalItens: 0,
      filtroAtivo: false,
      nome_fantasia: undefined,
      estado: undefined,
      cidade: undefined
    }
  },
  watch: {
    identificacao () {
      this.identificacao = this.identificacao.toUpperCase()
    },
    veiculo (val) {
      if (val) {
        this.$store.dispatch('carregarCombustiveis')
        this.pegarDetalhes()
        this.color = this.formatarCor(val.cor)
        this.identificacao = val.identificacao.replace(/[-]/g, '').toUpperCase()
      }
    },
    'dadosVeiculo.tipo_veiculo_object' (val) {
      if (val) {
        this.dadosVeiculo.id_tipo_veiculo = val.id_tipo_veiculo
      }
    },
    'dadosVeiculo.combustiveis' (val) {
      if (val && val.length && val.length > 2) {
        const combs = this.dadosVeiculo.combustiveis
        this.dadosVeiculo.combustiveis = [combs[1], combs[2]]
      }
    },
    headers (val) {
      if (val) {
        this.carregarTiposVeiculo()
      }
    },
    pagination_empresas: {
      handler (val, oldval) {
        if (this.empresa) this.pegarRevendedoras(false)
      },
      deep: true
    },
    stepOfertar (val) {
      if (val === 1) this.pegarRevendedoras(false)
      else {
        this.empresas_selecionadas = []
      }
    },
    btnAtivo (val) {
      if (val === 1) this.empresas_selecionadas = []
    },
    modelo_marca (val) {
      this.dadosVeiculo.modelo_marca = val
    },
    'dadosVeiculo.cor_texto' (val) {
      this.mudarCor()
    },
    tipo_veiculo (val) {
      if (val === 'motocicleta') {
        this.dadosVeiculo.air_bag_duplo = false
        this.dadosVeiculo.blindado = false
        this.dadosVeiculo.cd_player = false
        this.dadosVeiculo.air_bag_lateral = false
        this.dadosVeiculo.air_bag_passageiro = false
        this.dadosVeiculo.air_bag_duplo = false
        this.dadosVeiculo.desembaçador_traseiro = false
        this.dadosVeiculo.direcao = null
        this.dadosVeiculo.cambio = null
        this.dadosVeiculo.kit_gnv = false
        this.dadosVeiculo.kit_multimidia = false
        this.dadosVeiculo.motor = null
        this.dadosVeiculo.porta_copos = false
        this.dadosVeiculo.radio = false
        this.dadosVeiculo.retrovisores_eletricos = false
        this.dadosVeiculo.sensor_re = false
        this.dadosVeiculo.ar = false
        this.dadosVeiculo.som = false
        this.dadosVeiculo.bancos_couro = false
        this.dadosVeiculo.vidro_eletrico = false
        this.dadosVeiculo.vidro_eletrico_dianteiro = false
        this.dadosVeiculo.volante_regulagem_altura = false
      } else {
        this.dadosVeiculo.bau = false
        this.dadosVeiculo.escapamento_esportivo = false
        this.dadosVeiculo.contra_peso_guidon = false
        this.dadosVeiculo.farois_neblina = false
        this.dadosVeiculo.gps = false
        this.dadosVeiculo.cilindradas = null
      }
    },
    'dadosVeiculo.kit_gnv' (val) {
      const GNV = this.combustiveis.filter((c) => c.ref === 'gnv')[0]
      const idGnv = GNV ? GNV.id_combustivel : undefined
      if (val && idGnv) {
        const existe = idGnv ? !!this.dadosVeiculo.combustiveis.filter((c) => c.id_combustivel === idGnv)[0] : false
        if (!existe) this.dadosVeiculo.combustiveis.push(GNV)
      } else if (idGnv) {
        this.dadosVeiculo.combustiveis = this.dadosVeiculo.combustiveis.filter((c) => c.id_combustivel !== idGnv)
      }
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    combustiveis () {
      return this.$store.getters.combustiveis
    },
    empresa () {
      return this.$store.getters.empresa
    },
    estados () {
      return this.$store.getters.estados
    },
    pages () {
      const count = this.totalItens
      if (this.pagination_empresas.rowsPerPage == null || count == null) return 0

      return Math.ceil(count / this.pagination_empresas.rowsPerPage)
    },
    modelo_marca () {
      if (!this.dadosVeiculo.marca && this.dadosVeiculo.modelo) {
        return `(${this.dadosVeiculo.marca}) ${this.dadosVeiculo.modelo}`
      } else if (!this.veiculo_data.marca && this.veiculo_data.modelo) {
        return `(${this.veiculo_data.marca}) ${this.veiculo_data.modelo}`
      } else if (this.veiculo.modelo_marca) return this.veiculo.modelo_marca
      return ''
    },
    tipo_veiculo () {
      if (this.dadosVeiculo && this.dadosVeiculo.tipo_veiculo_object) {
        return this.dadosVeiculo.tipo_veiculo_object.denominacao.toLowerCase()
      }
      return undefined
    }
  },
  async mounted () {
    if (this.headers) {
      await this.carregarTiposVeiculo()
      await this.carregarFuncionarios()
      await this.$store.dispatch('carregarCombustiveis')
    }
    if (this.veiculo) {
      await this.pegarDetalhes()
    }
    // this.carregarFuncionarios()
  },
  methods: {
    adicionarEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const existe = !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
      if (existe) {
        this.activeAlert('Essa empresa já foi inserida', 'error')
        return
      }
      this.empresas_selecionadas.push(empresa.cnpj)
    },
    removerEmpresa (empresa) {
      if (!empresa) {
        this.activeAlert('Empresa inválida', 'error')
        return
      }
      const index = this.empresas_selecionadas.indexOf(empresa.cnpj)
      if (index === -1) {
        this.activeAlert('Essa empresa não foi inserida a lista.', 'error')
        return
      }
      this.empresas_selecionadas.splice(index, 1)
    },
    isSelected (empresa) {
      return !!this.empresas_selecionadas.find((item) => item === empresa.cnpj)
    },
    showError (msg) {
      this.temError = true
      this.mensagemError = msg
      setTimeout(() => {
        this.temError = false
        this.mensagemError = ''
      }, 5000)
    },
    fechar () {
      this.$refs.formEditarVeiculo.reset()
      this.$emit('fechar')
    },
    carregarTiposVeiculo () {
      veiculoDao.getTiposVeiculo(this.headers)
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.tipos_veiculo = json
        })
    },
    setCombustiveis (combustiveis) {
      combustiveis.forEach(comb => {
        this.dadosVeiculo.combustiveis.push(comb.combustivel)
      })
    },
    carregarFuncionarios () {
      var data = {
        pagination: this.pagination,
        empresa: this.empresa.cnpj
      }
      funcionariosDao.getFuncionariosEmpresaConfirmados(data, this.headers)
        .then(res => res.json())
        .then(json => {
          this.funcionarios = json.results
        })
    },
    async alterarVeiculo () {
      this.salvando = true
      if (this.$refs.formEditarVeiculo.validate()) {
        if (this.imagem_frontal && (this.imagem_frontal !== this.veiculo_data.imagem_frontal)) {
          await this.inserirImagemFirebase(this.imagem_frontal, 'imagens', 'frontal', 1)
        }
        if (this.imagem_traseira && (this.imagem_traseira !== this.veiculo_data.imagem_traseira)) {
          await this.inserirImagemFirebase(this.imagem_traseira, 'imagens', 'traseira', 2)
        }
        if (this.imagem_interior && (this.imagem_interior !== this.veiculo_data.imagem_interior)) {
          await this.inserirImagemFirebase(this.imagem_interior, 'imagens', 'interior', 3)
        }
        if (this.imagem_lateral_e && (this.imagem_lateral_e !== this.veiculo_data.imagem_lateral_e)) {
          await this.inserirImagemFirebase(this.imagem_lateral_e, 'imagens', 'lateral_esquerda', 4)
        }
        if (this.imagem_lateral_d && (this.imagem_lateral_d !== this.veiculo_data.imagem_lateral_d)) {
          await this.inserirImagemFirebase(this.imagem_lateral_d, 'imagens', 'lateral_direita', 5)
        }
        if (this.dadosVeiculo.valor) this.dadosVeiculo.valor = Number(this.dadosVeiculo.valor.replaceAll('.', '').replace(',', '.'))
        this.dadosVeiculo.cor = this.color.hex
        this.dadosVeiculo.identificacao = this.identificacao.replace(/[-]/g, '').toUpperCase()
        if (this.funcionarioSelected) {
          this.dadosVeiculo.novo_usuario = this.funcionarioSelected.pessoa.id_pessoa
        }
        if (this.isVenda && this.dadosVeiculo.ano_fabricacao.slice(-1) === '/') this.dadosVeiculo.ano_fabricacao = this.dadosVeiculo.ano_fabricacao.substring(0, 4)
        if (this.isVenda) this.dadosVeiculo.empresas_ofertadas = this.empresas_selecionadas
        this.dadosVeiculo.destinatario_oferta = this.isVenda ? this.stepOfertar + 1 : null
        this.dadosVeiculo.descricao = this.dadosVeiculo.descricao ? this.dadosVeiculo.descricao : null

        await veiculoDao.updateForEmpresa(this.dadosVeiculo, this.veiculo.id_veiculo, this.headers)
          .then(response => {
            if (response.ok) {
              this.$emit('info', 'As informações do veículo foram alteradas com sucesso')
              this.$emit('carregar')
              this.fechar()
            } else {
              this.$emit('error', 'Desculpe, não foi possível modificar o veículo no momento')
              this.fechar()
            }
          })
      } else {
        this.$store.dispatch('setError', 'Dados Inválidos')
      }
      this.salvando = false
    },
    mudarCor () {
      let corVeiculo = this.dadosVeiculo.cor_texto
      if (!corVeiculo) return
      corVeiculo = corVeiculo.toUpperCase()
      switch (corVeiculo) {
        case 'BRANCA':
          this.color = {
            alpha: 1,
            hex: '#FFFFFF',
            hexa: '#FFFFFFFF',
            hsla: {
              a: 1,
              h: 0,
              l: 1,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 255,
              g: 255,
              r: 255
            }
          }
          break
        case 'CINZA':
          this.color = {
            alpha: 1,
            hex: '#636363',
            hexa: '#636363FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.39,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 0.39
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 99,
              g: 99,
              r: 99
            }
          }
          break
        case 'PRATA':
          this.color = {
            alpha: 1,
            hex: '#C6C6C6',
            hexa: '#C6C6C6FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.7763541666666667,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0,
              v: 0.7763541666666667
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 198,
              g: 198,
              r: 198
            }
          }
          break
        case 'PRETA':
          this.color = {
            alpha: 1,
            hex: '#000000',
            hexa: '#000000FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0,
              s: 0
            },
            hsva: {
              a: 1,
              h: 0,
              s: 0.9633333333333334,
              v: 0
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 0,
              g: 0,
              r: 0
            }
          }
          break
        case 'VERMELHA':
          this.color = {
            alpha: 1,
            hex: '#FF0000',
            hexa: '#FF0000FF',
            hsla: {
              a: 1,
              h: 0,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 0,
              s: 1,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 0,
              g: 0,
              r: 255
            }
          }
          break
        case 'AZUL':
          this.color = {
            alpha: 1,
            hex: '#0013FF',
            hexa: '#0013FFFF',
            hsla: {
              a: 1,
              h: 235.51401869158877,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 235.51401869158877,
              s: 1,
              v: 1
            },
            hue: 235.51401869158877,
            rgba: {
              a: 1,
              b: 255,
              g: 19,
              r: 0
            }
          }
          break
        case 'AMARELA':
          this.color = {
            alpha: 1,
            hex: '#F2F408',
            hexa: '#F2F408FF',
            hsla: {
              a: 1,
              h: 60.560747663551396,
              l: 0.4941163194444444,
              s: 0.935483870967742
            },
            hsva: {
              a: 1,
              h: 60.560747663551396,
              s: 0.4941163194444444,
              v: 0.935483870967742
            },
            hue: 60.560747663551396,
            rgba: {
              a: 1,
              b: 0,
              g: 255,
              r: 255
            }
          }
          break
        case 'VERDE':
          this.color = {
            alpha: 1,
            hex: '#15D013',
            hexa: '#15D013FF',
            hsla: {
              a: 1,
              h: 119.43925233644859,
              l: 0.4464444444444445,
              s: 0.8292682926829267
            },
            hsva: {
              a: 1,
              h: 119.43925233644859,
              s: 0.9066666666666666,
              v: 0.8166666666666667
            },
            hue: 119.43925233644859,
            rgba: {
              a: 1,
              b: 19,
              g: 208,
              r: 21
            }
          }
          break
        case 'ROSA':
          this.color = {
            alpha: 1,
            hex: '#FF007F',
            hexa: '#FF007FFF',
            hsla: {
              a: 1,
              h: 302.803738317757,
              l: 0.5,
              s: 1
            },
            hsva: {
              a: 1,
              h: 302.803738317757,
              s: 1,
              v: 1
            },
            hue: 0,
            rgba: {
              a: 1,
              b: 127,
              g: 0,
              r: 255
            }
          }
          break
        case 'MARROM':
          this.color = {
            alpha: 1,
            hex: '#794727',
            hexa: '#794727FF',
            hsla: {
              a: 1,
              h: 23.551401869158877,
              l: 0.31439374999999997,
              s: 0.5151515151515151
            },
            hsva: {
              a: 1,
              h: 23.551401869158877,
              s: 0.68,
              v: 0.47635416666666663
            },
            hue: 23.551401869158877,
            rgba: {
              a: 1,
              b: 39,
              g: 71,
              r: 121
            }
          }
          break
        case 'BEGE':
          this.color = {
            alpha: 1,
            hex: '#DAC011',
            hexa: '#DAC011FF',
            hsla: {
              a: 1,
              h: 52.14953271028037,
              l: 0.46243124999999996,
              s: 0.851851851851852
            },
            hsva: {
              a: 1,
              h: 52.14953271028037,
              s: 0.92,
              v: 0.8563541666666666
            },
            hue: 52.14953271028037,
            rgba: {
              a: 1,
              b: 17,
              g: 192,
              r: 218
            }
          }
      }
    },
    async pegarDetalhes () {
      this.salvando = true
      await this.$store.dispatch('detalharVeiculo', this.veiculo.id_veiculo).then(json => {
        this.veiculo_data = json
        if (this.veiculo_data.imagem_frontal) {
          this.imagem_frontal = this.veiculo_data.imagem_frontal
          this.items.push({ src: this.veiculo_data.imagem_frontal })
        }
        if (this.veiculo_data.imagem_traseira) {
          this.imagem_traseira = this.veiculo_data.imagem_traseira
          this.items.push({ src: this.veiculo_data.imagem_traseira })
        }
        if (this.veiculo_data.imagem_lateral_e) {
          this.imagem_lateral_e = this.veiculo_data.imagem_lateral_e
          this.items.push({ src: this.veiculo_data.imagem_lateral_e })
        }
        if (this.veiculo_data.imagem_lateral_d) {
          this.imagem_lateral_d = this.veiculo_data.imagem_lateral_d
          this.items.push({ src: this.veiculo_data.imagem_lateral_d })
        }
        if (this.veiculo_data.imagem_interior) {
          this.imagem_interior = this.veiculo_data.imagem_interior
          this.items.push({ src: this.veiculo_data.imagem_interior })
        }
        if (json.tipo_veiculo) {
          this.dadosVeiculo.id_tipo_veiculo = json.tipo_veiculo.id_tipo_veiculo
          this.dadosVeiculo.tipo_veiculo_object = json.tipo_veiculo
        }
        if (json.combustiveis) {
          this.setCombustiveis(json.combustiveis)
        }

        this.dadosVeiculo.cor_texto = this.veiculo_data.cor_texto
        this.color = this.formatarCor(this.veiculo_data.cor)
        this.identificacao = this.veiculo_data.identificacao.replace(/[-]/g, '').toUpperCase()
        this.dadosVeiculo.modelo = this.veiculo_data.modelo
        this.dadosVeiculo.marca = this.veiculo_data.marca
        this.dadosVeiculo.modelo_marca = this.veiculo_data.modelo_marca
        this.dadosVeiculo.ano_fabricacao = this.veiculo_data.ano_fabricacao
        this.dadosVeiculo.cor_texto = this.veiculo_data.cor_texto
        this.dadosVeiculo.usuario = this.veiculo_data.pessoa
        this.dadosVeiculo.usado = this.veiculo_data.usado
        this.dadosVeiculo.quilometragem = this.veiculo_data.quilometragem
        this.dadosVeiculo.vidro_eletrico = this.veiculo_data.vidro_eletrico
        this.dadosVeiculo.alarme = this.veiculo_data.alarme
        this.dadosVeiculo.air_bag = this.veiculo_data.air_bag
        this.dadosVeiculo.som = this.veiculo_data.som
        this.dadosVeiculo.trava_eletrica = this.veiculo_data.trava_eletrica
        this.dadosVeiculo.sensor_re = this.veiculo_data.sensor_re
        this.dadosVeiculo.ar = this.veiculo_data.ar
        this.dadosVeiculo.camera_re = this.veiculo_data.camera_re
        this.dadosVeiculo.air_bag_passageiro = this.veiculo.air_bag_passageiro
        this.dadosVeiculo.volante_regulagem_altura = this.veiculo.volante_regulagem_altura
        this.dadosVeiculo.bluetooth = this.veiculo.bluetooth
        this.dadosVeiculo.vidro_eletrico_traseiro = this.veiculo.vidro_eletrico_traseiro
        this.dadosVeiculo.vidro_eletrico_dianteiro = this.veiculo.vidro_eletrico_dianteiro
        this.dadosVeiculo.rodas_liga_leve = this.veiculo.rodas_liga_leve
        this.dadosVeiculo.kit_multimidia = this.veiculo.kit_multimidia
        this.dadosVeiculo.computador_bordo = this.veiculo.computador_bordo
        this.dadosVeiculo.bancos_couro = this.veiculo.bancos_couro
        this.dadosVeiculo.air_bag_lateral = this.veiculo.air_bag_lateral
        this.dadosVeiculo.retrovisores_eletricos = this.veiculo.retrovisores_eletricos
        this.dadosVeiculo.freios_abs = this.veiculo.freios_abs
        this.dadosVeiculo.radio = this.veiculo.radio
        this.dadosVeiculo.entrada_usb = this.veiculo.entrada_usb
        this.dadosVeiculo.air_bag_duplo = this.veiculo.air_bag_duplo
        this.dadosVeiculo.porta_copos = this.veiculo.porta_copos
        this.dadosVeiculo.cd_player = this.veiculo.cd_player
        this.dadosVeiculo.kit_gnv = this.veiculo.kit_gnv
        this.dadosVeiculo.blindado = this.veiculo.blindado
        this.dadosVeiculo.gps = this.veiculo.gps
        this.dadosVeiculo.escapamento_esportivo = this.veiculo.escapamento_esportivo
        this.dadosVeiculo.contra_peso_guidon = this.veiculo.contra_peso_guidon
        this.dadosVeiculo.bau = this.veiculo.bau
        this.dadosVeiculo.farois_neblina = this.veiculo.farois_neblina
        this.dadosVeiculo.quitando = this.veiculo.quitando
        this.dadosVeiculo.ipva_pago = this.veiculo.ipva_pago
        this.dadosVeiculo.com_multa = this.veiculo.com_multa
        this.dadosVeiculo.de_leilao = this.veiculo.de_leilao
        this.dadosVeiculo.unico_dono = this.veiculo.unico_dono
        this.dadosVeiculo.com_manual = this.veiculo.com_manual
        this.dadosVeiculo.com_garantia = this.veiculo.com_garantia
        this.dadosVeiculo.revisoes_feitas = this.veiculo.revisoes_feitas
        this.dadosVeiculo.chave_reserva = this.veiculo.chave_reserva
        this.dadosVeiculo.qtd_estoque = this.veiculo.qtd_estoque
        this.dadosVeiculo.motor = this.veiculo.motor
        this.dadosVeiculo.qtd_portas = this.veiculo.qtd_portas
        this.dadosVeiculo.distribuicao_eletronica_frenagem = this.veiculo.distribuicao_eletronica_frenagem
        this.dadosVeiculo.desembaçador_traseiro = this.veiculo.desembaçador_traseiro
        this.dadosVeiculo.direcao = this.veiculo_data.direcao
        this.dadosVeiculo.cambio = this.veiculo_data.cambio
        this.dadosVeiculo.valor = this.formatarValor(this.veiculo_data.valor)
        this.dadosVeiculo.descricao = this.veiculo_data.descricao
        this.dadosVeiculo.identificacao = this.veiculo_data.identificacao
        this.empresas_selecionadas = this.veiculo_data.empresas_ofertadas
        this.dadosVeiculo.destinatario_oferta = this.veiculo_data.destinatario_oferta
        this.stepOfertar = this.veiculo_data.destinatario_oferta - 1
        if (this.empresas_selecionadas.length > 0) this.btnAtivo = 2
        if (json.cor_texto) this.mudarCor()
        if (this.veiculo_data.tipo_veiculo) {
          this.dadosVeiculo.id_tipo_veiculo = this.veiculo_data.tipo_veiculo.id_tipo_veiculo
          this.dadosVeiculo.tipo_veiculo_object = this.veiculo_data.tipo_veiculo
        }
      })
      this.salvando = false
    },
    formatarValor (valor) {
      if (!valor) return '0,00'
      return valor.replaceAll('.', ',')
    },
    formatarCor (cor) {
      if (cor === null) return ''
      if (cor === undefined) return ''
      if (cor.length === 6) return `#${cor}`
      if (cor.length === 7) return cor
      if (cor.length === 9) return cor.slice(0, 7)
      else {
        let teste = parseInt(cor)
        teste = teste.toString(16)
        return `#${teste.slice(2, 8)}`
      }
    },
    carregarImagem (event) {
      switch (this.step) {
        case 1:
          this.imagem_frontal = null
          this.mostrar = false
          break
        case 2:
          this.imagem_traseira = null
          this.mostrar2 = false
          break
        case 3:
          this.imagem_interior = null
          this.mostrar3 = false
          break
        case 4:
          this.imagem_lateral_e = null
          this.mostrar4 = false
          break
        case 5:
          this.imagem_lateral_d = null
          this.mostrar5 = false
          break
      }

      if (event.target.files && event.target.files[0]) {
        const imagem = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = e => {
          let ref = null
          switch (this.step) {
            case 1:
              this.cropping1 = true
              this.$refs.croppieimagemFrontalRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemFrontalRef
              break
            case 2:
              this.cropping2 = true
              this.$refs.croppieimagemTraseiraRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemTraseiraRef
              break
            case 3:
              this.cropping3 = true
              this.$refs.croppieimagemInteriorRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemInteriorRef
              break
            case 4:
              this.cropping4 = true
              this.$refs.croppieimagemLateralERef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemLateralERef
              break
            case 5:
              this.cropping5 = true
              this.$refs.croppieimagemLateralDRef.bind({
                url: reader.result
              })
              ref = this.$refs.croppieimagemLateralDRef
              break
          }
          this.$nextTick(() => {
            ref.setZoom(0.1)
          })
        }
        reader.readAsDataURL(imagem)
      }
    }, // carregarImagem
    recortarImagem () {
      const options = {
        type: 'base64',
        format: 'png'
      }
      let refscroppie = null
      switch (this.step) {
        case 1:
          refscroppie = this.$refs.croppieimagemFrontalRef
          this.mostrar = true
          break
        case 2:
          refscroppie = this.$refs.croppieimagemTraseiraRef
          this.mostrar2 = true
          break
        case 3:
          refscroppie = this.$refs.croppieimagemInteriorRef
          this.mostrar3 = true
          break
        case 4:
          refscroppie = this.$refs.croppieimagemLateralERef
          this.mostrar4 = true
          break
        case 5:
          refscroppie = this.$refs.croppieimagemLateralDRef
          this.mostrar5 = true
          break
      }

      refscroppie.result(options, output => {
        switch (this.step) {
          case 1:
            this.imagem_frontal = output
            this.cropping1 = false
            break
          case 2:
            this.imagem_traseira = output
            this.cropping2 = false
            break
          case 3:
            this.imagem_interior = output
            this.cropping3 = false
            break
          case 4:
            this.imagem_lateral_e = output
            this.cropping4 = false
            break
          case 5:
            this.imagem_lateral_d = output
            this.cropping5 = false
            break
        }
      })
    }, // recortarImagem
    async inserirImagemFirebase (imagem, local, prefixo, step) {
      const data = {
        arquivo: imagem,
        id: this.empresa.cnpj,
        local: local,
        prefixo: prefixo,
        tipo: 'png',
        rota: 'veiculos'
      }
      const variavel = await this.$store.dispatch('inserirFirebase', data)
      switch (step) {
        case 1:
          this.dadosVeiculo.imagem_frontal = variavel
          this.cropping1 = false
          break
        case 2:
          this.dadosVeiculo.imagem_traseira = variavel
          this.cropping2 = false
          break
        case 3:
          this.dadosVeiculo.imagem_interior = variavel
          this.cropping3 = false
          break
        case 4:
          this.dadosVeiculo.imagem_lateral_e = variavel
          this.cropping4 = false
          break
        case 5:
          this.dadosVeiculo.imagem_lateral_d = variavel
          this.cropping5 = false
          break
      }
    }, // inserirImagemFirebase
    pegarRevendedoras (isBtn) {
      const data = {
        tipo_empresa: 2,
        empresa: this.empresa.cnpj
      }
      data.page = isBtn ? 1 : this.pagination_empresas.page
      if (this.cidade) data.endereco__cidade__icontains = this.cidade
      if (this.estado) data.endereco__estado__icontains = this.estado
      if (this.nome_fantasia) data.nome_fantasia__icontains = this.nome_fantasia
      this.$store.dispatch('pegarEmpresasAtivas', data).then(json => {
        this.empresas = json.results
        this.totalItens = json.count
      })
    } // pegarRevendedoras
  }
}
</script>
